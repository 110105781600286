import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  LineController,
  BarController,
} from "chart.js"
import { useState } from "react"
import { Line } from "react-chartjs-2"
import { round, ObjectKeys, calculateROC, sgToPlato } from "@accubrew/utils"
import { trpc, useBatches, useDeviceId, useTemp, useUnit, StateContainer, useStateContainer } from "@accubrew/state"
// import { setTemp, trpc, useBatchId, useDeviceId, StateContainer, useStateContainer } from "@accubrew/state"
import { ConfirmModal } from "../../../components"

import { format } from "date-fns"

import { svg } from "../../../assets"
import { FiDownload } from "react-icons/fi"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, LineController, BarController)

const PERIOS_TO_NUMBER_OF_STATS = {
  "4h": 18,
  "8h": 36,
  "12h": 54,
  "24h": 108,
  all: undefined,
}

export function Chart({ batchId }: { batchId: string }) {
  const temp = useTemp()
  const user = trpc.user.get.useQuery()

  const unit = useUnit()
  // const MAP_DATASET_TO_INDEX =
  //   temp === "c" && unit === "SG"
  //     ? (["specificGravity", "fermentation", "clarity", "temperatureC"] as const)
  //     : temp === "f" && unit === "SG"
  //     ? (["specificGravity", "fermentation", "clarity", "temperatureF"] as const)
  //     : temp === "f" && unit === "Plato"
  //     ? (["plato", "fermentation", "clarity", "temperatureF"] as const)
  //     : (["plato", "fermentation", "clarity", "temperatureC"] as const)
  const MAP_DATASET_TO_INDEX =
    temp === "c"
      ? (["specificGravity", "fermentation", "clarity", "temperatureC"] as const)
      : (["specificGravity", "fermentation", "clarity", "temperatureF"] as const)
  const [period, setPeriod] = useState<keyof typeof PERIOS_TO_NUMBER_OF_STATS>("all")
  const [activeState, setActiveState] = useState<0 | 1 | 2 | 3>(0)

  const deviceId = useDeviceId()
  const deviceBatches = useBatches({ deviceId })
  const deleteChartPoint = trpc.batch.deleteChartPoint.useMutation()

  const stats: any = trpc?.batch?.stats.useQuery(
    { batchId, ...(period === "all" ? { allData: true } : {}) }, //
    { enabled: !!batchId },
  )
  console.log("stats", stats.data)
  type DataDeleteType = { visible: boolean; fileName?: any; readingTime: any }
  const dataDeleteConfirmRef = useStateContainer<DataDeleteType>()

  if (deviceBatches.isSuccess && !batchId) return null
  function convertDataToPlato(dataObject: any) {
    if (dataObject?.label?.toLowerCase() === "specific gravity") {
      const platoData = dataObject?.data?.map((sg: any) => sgToPlato(sg))
      return { ...dataObject, data: platoData }
    } else {
      return dataObject
    }
  }

  //  needs a refactor
  const labels: any = period === "all" ? stats.data?.labels ?? [] : stats.data?.labels.slice(-PERIOS_TO_NUMBER_OF_STATS[period]) ?? []
  const arrdata = stats.data?.datasets?.[MAP_DATASET_TO_INDEX[activeState]]
  const activeData = unit === "Plato" ? convertDataToPlato(arrdata) : arrdata

  const data = period === "all" ? activeData?.data ?? [] : activeData?.data.slice(-PERIOS_TO_NUMBER_OF_STATS[period]) ?? []
  const exportCsv: any = trpc.batch.exportBatchStatsCsv.useMutation()
  const handleCsvDownload = () => {
    exportCsv.mutate(
      {
        batchId: batchId ?? "",
      },
      {
        onSuccess(data: any) {
          const link: any = document.createElement("a")
          link.download = data.url
          link.href = data.url
          link.click()
        },
        onError(error: any) {
          console.log("Error export csv", error)
        },
      },
    )
  }

  return (
    <div className="bg-white rounded-md py-4 px-5 shadow relative">
      <div className="mb-5 flex justify-between items-center">
        <h3 className="text-[#342F20] font-extrabold text-[24px]">Chart</h3>
        <div
          className={`border-2 px-[6px] py-[3px] space-x-[5px] rounded-[6px] hidden md:block${
            stats.data?.datasets[MAP_DATASET_TO_INDEX[activeState]].label ? "" : "opacity-0 pointer-events-none"
          }`}
        >
          {ObjectKeys(PERIOS_TO_NUMBER_OF_STATS).map((item) => (
            <button
              key={item}
              className={`text-[14px] font-extrabold text-[#B6B4AF] px-[10px] py-[2px] rounded-[6px] ${item === period ? "bg-darkOrangeDark text-white" : ""}`}
              onClick={() => setPeriod(item)}
            >
              {item}
            </button>
          ))}
        </div>

        <div className="flex items-center">
          {!["PRODUCTION", "COLLABORATOR"].includes(user.data!.role) && (
            <button
              className="text-orangeDark w-fit px-2 h-[35px] bg-orangeLight mr-[15px] rounded-[6px] flex justify-center items-center space-x-1"
              onClick={handleCsvDownload}
            >
              {exportCsv.isLoading ? (
                <svg.Loading className="mx-auto w-6 h-6" />
              ) : (
                <>
                  <p className="text-sm">Export CSV</p>
                  <FiDownload className="text-orangeDark" />
                </>
              )}
            </button>
          )}
          <button className="w-[35px] h-[35px] bg-[#EAEAEA] rounded-[6px] flex justify-center items-center">
            <svg.Maximize2 />
          </button>
        </div>
      </div>
      <div
        className={`flex flex-col  gap-3  md:hidden w-full my-3 ${
          stats.data?.datasets[MAP_DATASET_TO_INDEX[activeState]].label ? "" : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="inline-flex justify-between flex-wrap rounded-md shadow-sm border border-gray-200 p-1 space-x-2 w-full md:w-[50%]">
          {ObjectKeys(PERIOS_TO_NUMBER_OF_STATS).map((item) => (
            <button
              key={item}
              className={`text-[14px] font-extrabold text-[#B6B4AF] px-[10px] py-[2px] rounded-[6px] ${item === period ? "bg-[#F3BD48] text-white" : ""}`}
              onClick={() => setPeriod(item)}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
      <div
        className={`flex justify-between justify-start md:items-center mb-4 flex-col md:flex-row${
          stats.data?.datasets[MAP_DATASET_TO_INDEX[activeState]].label ? "" : "opacity-0 pointer-events-none"
        }`}
      >
        {/* left */}
        <div className="flex justify-start md:items-center">
          <span className="font-extrabold text-[18px] mr-5 text-[#342F20] w-36">
            {stats.data?.datasets[MAP_DATASET_TO_INDEX[activeState]].label ?? "Fermentation.."}
          </span>
          {/* @ts-ignore */}
          <button className="p-2" onClick={() => setActiveState((state) => (state === 0 ? 3 : state - 1))}>
            <svg.ChevronDown className="w-5 h-5 rotate-90" />
          </button>
          {/* @ts-ignore */}
          <button className="p-2" onClick={() => setActiveState((state) => (state + 1) % 4)}>
            <svg.ChevronDown className="w-5 h-5 -rotate-90" />
          </button>
        </div>

        {/* right */}
        <div className="flex gap-x-4 items-center ">
          <p className="text-[#B6B4AF] font-normal text-[16px]">
            Last reading: <span className="text-[#342F20] font-bold"> {round(data[data.length - 1] ?? 0, 2)}</span>
          </p>
          <p
            className="font-normal text-[16px]  px-[10px] py-[4px] rounded"
            style={{ color: activeData?.textColor, backgroundColor: activeData?.backgroundColor }}
          >
            Change: {round(calculateROC(data), 2)}%
          </p>
        </div>
      </div>

      <div style={{ aspectRatio: 3 }}>
        {stats.data?.labels ? (
          <Line
            data={{ labels, datasets: [{ ...activeData, data }] }}
            options={{
              aspectRatio: 3,
              responsive: true,
              scales: {
                y: { grid: { display: true } },
                x: {
                  grid: { display: false },
                  labels: labels.map((label: any) => format(new Date(label), "MM/dd HH:mm")),
                },
              },
              onClick: (event, elements, chart) => {
                if (elements?.length > 0) {
                  const clickedElement = elements[0]
                  const index = clickedElement?.index

                  dataDeleteConfirmRef.current?.setState({
                    visible: true,
                    fileName: stats?.data?.fileName[index],
                    readingTime: stats?.data?.readingTime[index],
                  })
                }
              },
              plugins: {
                legend: { display: false, position: "top" as const },
                title: { display: false },
              },
            }}
          />
        ) : null}
      </div>

      {(deviceBatches.isLoading || stats.isLoading) && (
        <div className="absolute inset-0 flex justify-center items-center">
          <svg.Loading />
        </div>
      )}
      <StateContainer initialState={{ visible: false, fileName: null, readingTime: null }} ref={dataDeleteConfirmRef}>
        {(state: any, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure you want to delete this data point?"
            description="This action cannot be undone."
            onConfirm={() => {
              deleteChartPoint.mutate(
                { batchId, fileName: state?.fileName, readingTime: state.readingTime },
                {
                  onSuccess(data: any) {
                    stats.refetch()
                    // alert("done")
                  },
                  onError(error: any) {
                    console.log("Error export csv", error)
                  },
                },
              )
              setState({ visible: false })
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            // loading={deleteMultipleBatch.isLoading || batches.isFetching}
          />
        )}
      </StateContainer>
    </div>
  )
}
