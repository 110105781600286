import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { trpc } from "@accubrew/state"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { Button, AuthLayout, AuthCard } from "../../components"
import { useNavigate } from "@tanstack/react-location"
import ProfileBg from "../../assets/profileBg.svg"
const schema = z.object({
  firstName: z.string().min(3),
  lastName: z.string().optional(),
  phoneNumber: z.string().optional(),
  countryCode: z.string().optional(),
  dialCode: z.string().optional(),
})

const resolver = zodResolver(schema)

export function CompleteProfile() {
  const navigate = useNavigate()
  const context = trpc.useContext()
  const updateUser = trpc.user.update.useMutation()
  const user = trpc.user.get.useQuery()

  const { handleSubmit, formState, register, setValue, getValues } = useForm<z.infer<typeof schema>>({
    resolver,
    defaultValues: {
      firstName: user.data?.firstName ?? "",
      lastName: user.data?.lastName ?? "",
      phoneNumber: user.data?.phoneNumber ?? "",
      countryCode: user.data?.countryCode ?? "",
      dialCode: user.data?.dialCode ?? "",
    },
  })

  const { isValid } = formState

  const onSubmit = handleSubmit((data) => {
    updateUser.mutate(data, {
      onSuccess(data) {
        context.user.get.setData(undefined, data)
        requestAnimationFrame(() => {
          navigate({ to: "/dashboard" })
        })
      },
    })
  })
  const data = getValues()

  return (
    <AuthLayout title="Personal information" backgroundImg={ProfileBg}>
      <AuthCard title="Personal information">
        <form className="mb-3 pb-14" onSubmit={onSubmit}>
          <div className="flex">
            <div className="flex-1 mr-3">
              <label htmlFor="firstName" className="block mb-1 font-[800] text-[14px] text-[#B6B4AF]">
                First Name
              </label>
              <div className="border mb-5 rounded-[6px]">
                <input type="text" id="firstName" className="w-full border-none bg-transparent" {...register("firstName")} />
              </div>
            </div>
            <div className="flex-1">
              <label htmlFor="lastName" className="block mb-1 font-[800] text-[14px] text-[#B6B4AF]">
                Last Name
              </label>
              <div className="border mb-5 rounded-[6px]">
                <input type="text" id="lastName" className="w-full border-none bg-transparent" {...register("lastName")} />
              </div>
            </div>
          </div>

          <label htmlFor="phone" className="block mb-1 font-[800] text-[14px] text-[#B6B4AF] ">
            Phone
          </label>
          <div className="mb-5 border rounded-[6px] w-100">
            <PhoneInput
              country="us"
              inputClass="w-full"
              value={user.data?.phoneNumber}
              onChange={(phone, country: any) => {
                const dialCode = country.dialCode
                const countryCode = country.countryCode
                const phoneNumber = phone.replace(`${dialCode}`, "")
                setValue("countryCode", countryCode.toUpperCase())
                setValue("phoneNumber", phoneNumber)
                setValue("dialCode", dialCode)
              }}
            />
          </div>
          <Button
            title="Go to dashboard"
            variant="primary"
            className="py-3 mb-15 block w-full text-[18px] font-[800]"
            disabled={!isValid}
            isLoading={updateUser.isLoading}
          />
        </form>
      </AuthCard>
    </AuthLayout>
  )
}
