import { StateContainer, Store, queryClient, trpc, useStateContainer } from "@accubrew/state"
import { Link, Outlet, useLocation } from "@tanstack/react-location"
import { Children } from "react"
import { ConfirmModal } from "../../components"
type Props = { children?: React.ReactNode }
type DeleteAccountState = { visible: boolean }

export function Layout({ children }: Props) {
  const user = trpc.user.get.useQuery()
  const deactivateUser = trpc.user.deactivate.useMutation()
  const deleteAccountConfirmRef = useStateContainer<DeleteAccountState>()

  const allowedRoles = ["OWNER", "MANAGER", "PRODUCTION"]
  const isAllowed = allowedRoles.includes(user.data!.role)

  return (
    <>
      <div className="grid grid-cols-1  lg:grid-cols-4 mt-4">
        <div className="hidden  lg:col-span-1 lg:block p-4">
          <div className="flex flex-col mb-4 mt-6">
            <MatchPathName path="settings/profile">
              {(active) => (
                <Link
                  className={`text-center p-3 py-3 text-sm rounded-lg ${active ? "bg-darkOrangeDark text-white" : "text-slate-600"}`}
                  to="/dashboard/settings/profile"
                >
                  Personal Info
                </Link>
              )}
            </MatchPathName>
            {isAllowed && (
              <>
                <MatchPathName path="settings/accounts">
                  {(active) => (
                    <Link
                      className={`text-center px-3 py-3 text-sm rounded-lg ${active ? "bg-darkOrangeDark text-white" : "text-slate-600"}`}
                      to="/dashboard/settings/accounts"
                    >
                      Account Management
                    </Link>
                  )}
                </MatchPathName>

                <MatchPathName path="settings/devices">
                  {(active) => (
                    <Link
                      className={`text-center px-3 py-3 text-sm rounded-lg ${active ? "bg-darkOrangeDark text-white" : "text-slate-600"}`}
                      to="/dashboard/settings/devices"
                    >
                      Probe Management
                    </Link>
                  )}
                </MatchPathName>
              </>
            )}

            <button
              className="mt-64 w-fit text-left px-3 py-1.5 text-md rounded text-error font-bold"
              onClick={() => {
                deleteAccountConfirmRef.current?.setState({ visible: true })
              }}
            >
              Delete Account
            </button>
          </div>
        </div>
        <div className="col-span-3">{children}</div>
      </div>
      <StateContainer<DeleteAccountState> initialState={{ visible: false }} ref={deleteAccountConfirmRef}>
        {(state, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure, you want to delete your account?"
            description="All data and connected accounts will
            be permanently deleted."
            onConfirm={async () => {
              await deactivateUser.mutateAsync(undefined)
              setState({ visible: false })
              Store.clear()
              requestAnimationFrame(() => queryClient.clear())
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            loading={deactivateUser.isLoading}
          />
        )}
      </StateContainer>
      <Outlet />
    </>
  )
}

function MatchPathName({ path, children }: { path: string; children: (active: boolean) => React.ReactNode }) {
  const location = useLocation()
  const active = location.current.pathname.includes(path)
  return children(active) as JSX.Element
}
