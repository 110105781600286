import { Link, Outlet, useLocation } from "@tanstack/react-location"

export function Layout() {
  return (
    <>
      <Outlet />
    </>
  )
}

function MatchPathName({ path, children }: { path: string; children: (active: boolean) => React.ReactNode }) {
  const location = useLocation()
  const active = location.current.pathname.includes(path)
  return children(active) as JSX.Element
}
