import { AiOutlineEdit, AiOutlineDelete, AiOutlineInfoCircle } from "react-icons/ai"
import { format } from "date-fns"
import { StateContainer, useBatches, useStateContainer, RouterOutput, trpc, useDeviceId } from "@accubrew/state"

import { svg } from "../../../assets"
import { ConfirmModal, Modal } from "../../../components"

import { NoteForm, InfoTable } from "../../brewbook/components"
import { Link } from "@tanstack/react-location"

type NotesModalType = { visible: boolean; note?: RouterOutput["note"]["list"][number] }
type NotesDeleteType = { visible: boolean; noteId?: RouterOutput["note"]["list"][number]["id"] }

export function NotesOverview({ batchId }: { batchId: string }) {
  const user = trpc.user.get.useQuery()
  const deleteNote = trpc.note.delete.useMutation()
  const notes = trpc.note.list.useQuery({ batchId })
  const notesModalRef = useStateContainer<NotesModalType>()
  const infoModalRef = useStateContainer<NotesModalType>()
  const deleteNoteConfirmRef = useStateContainer<NotesDeleteType>()

  const isLoading = notes.isLoading

  function handleAddClick() {
    notesModalRef.current?.setState({ visible: true })
  }
  const moreData = notes.data && notes.data.length > 5

  return (
    <>
      <div className={`pt-7 px-5 shadow h-full overflow-y-auto ${moreData ? "pb-9" : ""}`}>
        <div className="flex justify-between items-center mb-4">
          <h4 className="font-[800] text-[24px]">BrewBook</h4>
          {!isLoading ? (
            <button className="p-3 bg-[#F88D2A] rounded flex justify-center items-center" onClick={handleAddClick}>
              <svg.Plus />
            </button>
          ) : null}
        </div>

        {isLoading ? (
          <div className="absolute inset-0 top-12 flex justify-center items-center">
            <svg.Loading className="mx-auto my-8" />
          </div>
        ) : null}

        {notes.data?.length === 0 ? (
          <div className="absolute inset-0 top-16 flex justify-center items-center">
            <div className="text-[#B6B4AF] text-lg font-extrabold mb-2">No notes yet</div>
          </div>
        ) : null}

        {notes.data?.slice(0, 5).map((note: any) => (
          <div
            key={note.id}
            className={`bg-slate-50 p-2 mb-2 rounded-md overflow-y-auto border-l-[4px] ${note.type === "Batch" ? "border-[#F88D2A]" : "border-[#427CBF]"}`}
          >
            <div className="mb-2 flex justify-between items-center">
              <h2 className="font-bold line-clamp-2">{note.type === "Batch" ? "Batch Note" : "Reminder"}</h2>
              <div className="flex items-center">
                <button className="p-1" onClick={() => notesModalRef.current?.setState({ visible: true, note })}>
                  <AiOutlineEdit size={20} />
                </button>
                <button className="p-1" onClick={() => deleteNoteConfirmRef.current?.setState({ visible: true, noteId: note.id })}>
                  <AiOutlineDelete size={20} />
                </button>
                <button className="p-1" onClick={() => infoModalRef.current?.setState({ visible: true, note })}>
                  <AiOutlineInfoCircle size={20} />
                </button>
              </div>
            </div>
            <h5 className={`text-${note.createdBy?.email === user.data?.email ? "orangeDark" : "textPrimary"} font-bold`}>
              {note.createdBy?.firstName ?? ""} {note.createdBy?.lastName ?? ""}
            </h5>
            <p className="text-slate-600 mb-2">{note.description}</p>
            <div className="mb-2 flex justify-between  text-xs md:text-sm lg:text-sm">
              {note.createdAt && <p className="text-slate-600 mb-2 flex">{format(note.createdAt, "MMM d, yyyy ")}</p>}

              <div className="flex  ml-auto">
                {note.reminderDate && <p className="text-slate-400  mb-2">{format(note.reminderDate, "MMM d, yyyy h:mm a")}</p>}

                <svg.BrewNotification className="ml-2" />
              </div>
            </div>
          </div>
        ))}
      </div>
      {moreData ? (
        <Link className="p-2 text-[#F88D2A] font-bold text-sm px-1 absolute bottom-0 left-0 right-0 bg-white" to="/dashboard/brewbook">
          View All
        </Link>
      ) : (
        <div className="h-2"></div>
      )}

      {batchId && (
        <StateContainer<NotesModalType> initialState={{ visible: false }} ref={notesModalRef}>
          {(state, setState) => (
            <Modal visible={state.visible} onClose={() => setState((s) => ({ ...s, visible: false }))} className="max-w-lg sm:max-w-lg">
              <NoteForm close={() => setState((s) => ({ ...s, visible: false }))} batchId={batchId} note={state.note} />
            </Modal>
          )}
        </StateContainer>
      )}
      {batchId && (
        <StateContainer<NotesModalType> initialState={{ visible: false }} ref={infoModalRef}>
          {(state, setState) => (
            <Modal visible={state.visible} onClose={() => setState((s) => ({ ...s, visible: false }))} className="max-w-lg sm:max-w-lg">
              <InfoTable close={() => setState((s) => ({ ...s, visible: false }))} batchId={batchId} note={state.note} />
            </Modal>
          )}
        </StateContainer>
      )}
      <StateContainer initialState={{ visible: false }} ref={deleteNoteConfirmRef}>
        {(state, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure ?"
            description="This action cannot be undone."
            onConfirm={async () => {
              await deleteNote.mutateAsync({ id: state.noteId! })
              await notes.refetch()
              setState({ visible: false })
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            loading={deleteNote.isLoading || notes.isFetching}
          />
        )}
      </StateContainer>
    </>
  )
}
