import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import { add, eachDayOfInterval, endOfMonth, format, getDay, isEqual, isSameMonth, isToday, parse, startOfToday } from "date-fns"
import { useState } from "react"

import { useDashboardCalendar } from "@accubrew/state"
import { staticTrpc } from "../../../utils"
import { svg } from "../../../assets"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export function CalendarOverview({ batchId }: { batchId: string }) {
  let [selectedDay, setSelectedDay] = useState(startOfToday)
  let [currentMonth, setCurrentMonth] = useState(format(selectedDay, "MMM-yyyy"))
  const firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date())
  const lastDayCurrentMonth = endOfMonth(firstDayCurrentMonth)
  const calendarData = useDashboardCalendar(batchId, firstDayCurrentMonth, lastDayCurrentMonth, staticTrpc)
  let days = eachDayOfInterval({ start: firstDayCurrentMonth, end: endOfMonth(firstDayCurrentMonth) })

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"))
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"))
  }

  return (
    <div className="bg-white rounded-md pt-7 px-5 shadow h-full">
      <div className="flex justify-between items-center">
        <h4 className="font-[800] text-[24px]">Calendar</h4>
        {calendarData.isLoading && <svg.Loading className="w-5" />}
      </div>
      <div className="flex items-center">
        <h2 className="flex-auto font-bold text-gray-900">{format(firstDayCurrentMonth, "MMMM yyyy")}</h2>
        <button type="button" onClick={previousMonth} className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
        </button>
        <button
          onClick={nextMonth}
          type="button"
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
        </button>
      </div>
      <div className="grid grid-cols-7 mt-5 text-xs leading-6 text-center text-gray-500">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </div>
      <div className="grid grid-cols-7 grid-rows-6 text-sm">
        {days.map((day, dayIdx) => {
          const formattedDate = format(day, "dd-MM-yyyy")
          return (
            <div key={day.toString()} className={`${dayIdx === 0 ? colStartClasses[getDay(day)] : ""} py-1.5`}>
              <button
                type="button"
                onClick={() => setSelectedDay(day)}
                className={classNames(
                  isEqual(day, selectedDay) ? "text-white" : "",
                  !isEqual(day, selectedDay) && isToday(day) ? "text-orangeDark" : "",
                  !isEqual(day, selectedDay) && !isToday(day) && isSameMonth(day, firstDayCurrentMonth) ? "text-gray-900" : "",
                  !isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) ? "text-gray-400" : "",
                  isEqual(day, selectedDay) && isToday(day) ? "bg-orangeDark" : "",
                  isEqual(day, selectedDay) && !isToday(day) ? "bg-blue" : "",
                  !isEqual(day, selectedDay) ? "hover:bg-gray-200" : "",
                  isEqual(day, selectedDay) || isToday(day) ? "font-semibold" : "",
                  "mx-auto flex h-8 w-8 items-center justify-center rounded-md",
                )}
              >
                <time dateTime={formattedDate}>{format(day, "d")}</time>
              </button>

              <div className="flex justify-center mt-0.5 h-1.5 space-x-0.5">
                {calendarData.data?.[formattedDate]?.find((item: any) => item.type === "Reminder") && (
                  <div className="h-1.5 w-1.5 rounded-[2px] bg-[#427CBF]" />
                )}
                {calendarData.data?.[formattedDate]?.find((item: any) => item.type === "Batch") && <div className="h-1.5 w-1.5 rounded-[2px] bg-[#F88D2A]" />}
                {calendarData.data?.[formattedDate]?.find((item: any) => item.status === "RUNNING") && (
                  <div className="h-1.5 w-1.5 rounded-[2px] bg-[#43D82A]" />
                )}
                {calendarData.data?.[formattedDate]?.find((item: any) => item.status === "STOPPED") && (
                  <div className="h-1.5 w-1.5 rounded-[2px] bg-[#FF3030]" />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

let colStartClasses = ["", "col-start-2", "col-start-3", "col-start-4", "col-start-5", "col-start-6", "col-start-7"]
