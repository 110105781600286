import React, { useEffect, useState } from "react"
import { Link } from "@tanstack/react-location"
import { Button } from "../../components"
import { svg } from "../../assets"
import { Subscription, Finance, Hardware, FAQ } from "./components/constant"
import PricingCard from "./components/pricingCard"
import { setDeviceId, trpc, useDeviceId } from "@accubrew/state"
import Accordion from "./components/accordian"
import { useStripe, useElements, CardElement, Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Modal } from "../../components"
import { toast } from "react-toastify"
import CancelSubModal from "../../components/CancelSubscriptionModal"

function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}
export function Pricing() {
  const deviceId = useDeviceId()
  const [selectedDeviceID, setSelectedDeviceID] = useState<string>(deviceId ?? "")
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [deleteSubModal, setDeleteSubModal] = useState<boolean>(false)
  const [subscriptionId, setSubscriptionId] = React.useState<string>("")
  const [subscriptionEndDate, setSubscriptionEndDate] = React.useState<string>("")
  const [subscriptionStatus, setSubscriptionStatus] = React.useState<string>("")
  const [memberShipPrice, setMemberShipPrice] = React.useState<number>(24.95)

  const stripeUrl = trpc.payment.stripePaymentLink.useQuery()
  const devices = trpc.device.list.useQuery()
  const user = trpc.user.get.useQuery()
  const subscription = trpc.payment.createSubscription.useMutation({
    onSuccess(data) {},
  })
  const subscriptionPrice = trpc.payment.subscriptionPrice.useMutation({
    onSuccess(data) {},
  })
  const subscriptionDetail = trpc.payment.subscriptionDetail.useMutation({
    onSuccess(data) {},
  })
  const cancelSubscritpion = trpc.payment.cancelSubscritpion.useMutation({
    onSuccess(data) {},
  })
  const isLoading = subscription.isLoading || cancelSubscritpion.isLoading || stripeUrl.isLoading

  const stripe = useStripe()
  const elements = useElements()

  const handleStripe = () => {
    {
      stripeUrl?.data?.url !== undefined && (window.location.href = stripeUrl?.data?.url)
    }
  }
  const handleBuyDevice = () => {
    window.open("https://accubrew.io/shop/", "_blank")
  }
  const handleData = () => {
    if (elements && stripe && !user?.data?.paymentMethod) {
      const cardElement: any = elements.getElement(CardElement)
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: user?.data?.firstName + " " + user?.data?.lastName,
            email: user?.data?.email ?? undefined,
            phone: user?.data?.phoneNumber ?? undefined,
          },
        })
        .then((result) => {
          subscription.mutate(
            {
              email: user?.data?.email ? user?.data?.email : "",
              paymentMethodId: result?.paymentMethod?.id,
              particleId: selectedDeviceID,
              amount: memberShipPrice,
            },
            {
              onSuccess(data: any) {
                setPaymentModal(false)
                onChangeDevice(selectedDeviceID)
              },
              onError(error) {
                toast.error(error.message)
              },
            },
          )
        })
    }
    else{
      subscription.mutate(
        {
          email: user?.data?.email ? user?.data?.email : "",
          // paymentMethodId: user?.data?.paymentMethod,
          particleId: selectedDeviceID,
          amount: memberShipPrice,
        },
        {
          onSuccess(data: any) {
            setPaymentModal(false)
            onChangeDevice(selectedDeviceID)
          },
          onError(error) {
            toast.error(error.message)
          },
        },
      )
    }
  }
  useEffect(() => {
    selectedDeviceID != "" && onChangeDevice(selectedDeviceID)
  }, [])
  const onChangeDevice = (deviceId: string) => {
    setSelectedDeviceID(deviceId)
    setDeviceId(deviceId)
    subscriptionPrice.mutate(
      {
        particleId: deviceId,
        email: user?.data?.email ?? "",
      },
      {
        onSuccess(data) {
          setMemberShipPrice(data?.price)
        },
        onError(error) {
          console.log(error.message)
        },
      },
    )
    subscriptionDetail.mutate(
      {
        particleId: deviceId,
        email: user?.data?.email ?? "",
      },
      {
        onSuccess(data) {
          if (data?.subscriptionDetail != null) {
            const endDate = new Date(Number(data?.subscriptionDetail?.subscriptionEndDate) * 1000) // Multiply by 1000 to convert seconds to milliseconds
            setSubscriptionId(data?.subscriptionDetail?.id)
            const endDateFormatted = endDate.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }) // MM/dd/YYYY format
            setSubscriptionEndDate(endDateFormatted)
            setSubscriptionStatus(data?.subscriptionDetail?.subscriptionStatus)
          } else {
            setSubscriptionId("")
            setSubscriptionEndDate("")
            setSubscriptionStatus("")
          }
        },
        onError(error) {
          console.log("ERROR", JSON.stringify(error, null, 4))
        },
      },
    )
  }
  const currentDate = new Date()
  const currentDateFormatted = currentDate.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })

  return (
    <Page title="Pricing">
      <div className="flex items-center space-x-3 py-0">
        <Link to="/dashboard/accubrew/about" className="pl-3 text-[#000] text-16 font-semibold flex items-center">
          <svg.LeftArrow className="mr-1" />
          <span>Back</span>
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-4 mt-2">
        <select
          className="bg-white border border-gray-300 text-gray-800 shadow-lg text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4 pr-9 line-clamp-1"
          value={selectedDeviceID}
          onChange={(e) => onChangeDevice(e.target.value)}
        >
          <option disabled value="">
            Select Device
          </option>
          {devices.data?.devices.map((device: any) => (
            <option value={device.particleId} key={device.particleId}>
              {device.name} / {device.particleId}
            </option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-2 mx-2">
        <PricingCard title="Monitoring & Membership" subscriptionType="Month" price={memberShipPrice} featureList={Subscription}>
          <>
            {subscriptionEndDate != "" && new Date(subscriptionEndDate) > new Date(currentDateFormatted) ? (
              <div className="mb-1 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 items-end mt-14">
                <span className="md:col-span-1  text-sm font-medium text-gray-900 dark:text-black-300 relative inline-flex items-center">Current</span>
                <span className="md:col-span-2 text-sm font-medium text-textSecondary  md:ml-auto lg:ml-auto">
                  {subscriptionStatus === "active" ? "Next payment" : "Subscription ends on"} {subscriptionEndDate}
                </span>
                {subscriptionStatus === "active" ? (
                  <span
                    className="mr-2  mt-2 col-start-1 text-sm font-medium text-orangeDark dark:text-black-300 relative inline-flex items-center cursor-pointer"
                    onClick={() => setDeleteSubModal(true)}
                  >
                    Cancel
                  </span>
                ) : (
                  <span
                    className={`mr-2  mt-2 col-span-2 text-sm font-medium ${
                      subscriptionStatus === "paused" ? "text-darkGreen2" : "text-error2"
                    } dark:text-black-300 relative inline-flex items-center`}
                  >
                    Subcription {subscriptionStatus === "paused" ? "paused" : "canceled"}
                  </span>
                )}{" "}
              </div>
            ) : (
              <div className="mb-1 grid grid-cols-1 items-end mt-14">
                <Button
                  title=" Subscription"
                  variant="primary"
                  className="py-2 font-[800] mx-auto inline-block text-[18px] w-full"
                  onClick={() => {
                    if (selectedDeviceID != "") {
                      setPaymentModal(true)
                    } else toast.info("Select a device first")
                  }}
                />
              </div>
            )}
          </>
        </PricingCard>

        <PricingCard title="AccuBrew Sensors" subscriptionType="Sensor" price={295} featureList={Hardware} className="relative">
          <div className="mb-1 grid grid-cols-2 mt-12">
            <div className="pr-3 ">
              <Button
                //  isLoading={isLoading}
                title="Buy"
                variant="primary"
                className="py-2 font-bold mx-auto inline-block text-[18px] w-full"
                // onClick={() => handleStripe()}
                onClick={() => handleBuyDevice()}
              />
            </div>
            <div className="pr-3">
              <Button title="Contact Us" variant="secondary" className="py-2  font-bold mx-auto inline-block text-[18px] w-full" />
            </div>
          </div>
        </PricingCard>
        {/* <div className="flex justify-center p-8 md:p-0 lg:p-0">
          <div className="w-96">
            <h1 className="text-1xl font-bold my-3">FAQ</h1>
            <Accordion items={FAQ} />
          </div>
        </div> */}
      </div>{" "}
      {paymentModal && (
        <Modal visible={paymentModal !== undefined} onClose={() => setPaymentModal(false)}>
          <h2 className="px-4 pt-4 pb-2 text-lg font-bold">{user?.data?.paymentMethod ? "Subscription" : "Credit/Debit Card"}</h2>
          <p className="px-4 mb-4 font-medium">
            Probe to be subscribe by <span className="text-[#F88D2A]">{user?.data?.email}</span>
          </p>
          {!user?.data?.paymentMethod && (
            <div className="border rounded-md mx-4 p-2 my-5 ">
              {" "}
              <CardElement
                options={{
                  style: {
                    base: {
                      color: "#303238",
                      fontSize: "16px",
                      fontFamily: "sans-serif",
                      fontSmoothing: "antialiased",

                      "::placeholder": {
                        color: "#CFD7DF",
                      },
                    },
                    invalid: {
                      color: "#e5424d",
                      ":focus": {
                        color: "#303238",
                      },
                    },
                  },
                }}
              />
            </div>
          )}

          <div className="flex justify-center gap-x-3 mb-4">
            <Button onClick={() => setPaymentModal(false)} variant="plain" className="px-4 py-2">
              Cancel
            </Button>
            <Button onClick={handleData} variant="primary" className="relative px-4 py-2" disabled={isLoading} isLoading={isLoading}>
              Confirm
            </Button>
          </div>
        </Modal>
      )}
      {deleteSubModal && (
        <Modal visible={deleteSubModal !== undefined} onClose={() => setDeleteSubModal(false)}>
          <CancelSubModal
            setDeleteSubModal={setDeleteSubModal}
            onChangeDevice={onChangeDevice}
            selectedDeviceID={selectedDeviceID}
            subscriptionId={subscriptionId}
          />
        </Modal>
      )}
    </Page>
  )
}
