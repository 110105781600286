import { SVGProps } from "react"

export function Scale(props: SVGProps<SVGSVGElement> & { roc: number }) {
  // props.roc
  return (
    <div className="w-[55px] h-[97px] flex">
      <div className="flex-1 flex flex-col text-[9.5px] justify-between items-end mr-2 text-[#B6B4AF]">
        <span>25%</span>
        <span>12.5%</span>
        <span>0%</span>
        <span>-12.5%</span>
        <span>-25%</span>
      </div>
      <div className="relative w-8 border-[2.5px] border-[#427CBF] rounded-md flex flex-col justify-between overflow-hidden">
        <div className="w-3 h-[2.5px] bg-[#427cbf] opacity-0"></div>
        <div className="w-2 h-[2.5px] bg-[#427cbf]"></div>
        <div className="w-3 h-[2.5px] bg-[#427cbf]"></div>
        <div className="w-2 h-[2.5px] bg-[#427cbf]"></div>
        <div className="w-3 h-[2.5px] bg-[#427cbf] opacity-0"></div>
        <div className="absolute inset-0 flex flex-col">
          <div className="flex-1 flex items-end">
            {/*  */}
            {props.roc > 0 && <div className="w-3 bg-[#3EAE2B]" style={{ height: props.roc * (1 / 0.515) }} />}
          </div>
          <div className="flex-1">
            {/*  */}
            {props.roc < 0 && <div className="bg-[#D32F2F]" style={{ height: Math.abs(props.roc) * (1 / 0.515) }} />}
          </div>
        </div>
      </div>
    </div>
  )
}
