import { z } from "zod"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"

import { RouterOutput, trpc } from "@accubrew/state"
import { ROLES_PERMISSIONS, subAccount as subAccountSchema } from "@accubrew/validation"

import { svg } from "../../../assets"
import { useEffect, useState } from "react"
import { CollaboratorBatchPicker } from "./CollaboratorBatchPicker"
import { toast } from "react-toastify"
import { PhoneInput } from "react-international-phone"
import "react-international-phone/style.css"

type SubAccount = RouterOutput["subUser"]["list"]["accounts"][number]
type RoleType = "ADMIN" | "OWNER" | "MANAGER" | "PRODUCTION" | "COLLABORATOR"
export type SubAccountFormState = { visible: boolean; account?: any }

export function SubAccountForm({ account, hide }: Omit<SubAccountFormState, "visible"> & { hide: () => void }) {
  const createSubAccount = trpc.subUser.create.useMutation()
  const editSubAccount = trpc.subUser.edit.useMutation()
  const [phone, setPhone] = useState("")
  const context = trpc.useContext()
  const user = trpc.user.get.useQuery()
  const USER_ROLE: RoleType = user.data!.role
  const roles: ("ADMIN" | "OWNER" | "MANAGER" | "PRODUCTION" | "COLLABORATOR")[] = ROLES_PERMISSIONS[USER_ROLE]
  const handlePhoneChange = (phoneNumber: string) => {
    setPhone(phoneNumber)
    setValue("phoneNumber", phoneNumber)
  }

  const { formState, control, setValue, handleSubmit, register } = useForm<z.infer<typeof subAccountSchema>>({
    resolver: zodResolver(account ? subAccountSchema.omit({ password: true }) : subAccountSchema),
    defaultValues: {
      firstName: account?.firstName ?? "",
      lastName: account?.lastName ?? "",
      email: account?.email ?? "",
      phoneNumber: account?.phoneNumber ?? "",
      role: account?.role ?? roles[0],
      batches: account?.batches,
    },
    mode: "onChange",
  })

  const { isValid } = formState

  const handleSubmitPress = handleSubmit((values) => {
    if (account) {
      const { password, ...data } = values
      editSubAccount.mutate(data, {
        onSuccess() {
          context.subUser.list.refetch()
          hide()
        },
        onError(error) {
          toast.error(error.message)
        },
      })
    } else {
      createSubAccount.mutate(values, {
        onSuccess() {
          context.subUser.list.refetch()
          hide()
        },
        onError(error) {
          toast.error(error.message)
        },
      })
    }
  })

  return (
    <div className="p-4 rounded">
      <h2 className="font-extrabold text-[24px] my-4"> {account?.email ? "Edit account" : "Create account"}</h2>
      <label htmlFor="firstName" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
        First Name
      </label>
      <input type="text" id="firstName" className="w-full border-[#B6B4AF] border mb-3 rounded" {...register("firstName")} />
      <label htmlFor="lastName" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2 ">
        Last Name
      </label>
      <input type="text" id="lastName" className="w-full border-[#B6B4AF] border mb-3 rounded" {...register("lastName")} />
      <label htmlFor="email" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
        Email
      </label>
      <input type="email" id="3mail" disabled={account} className="w-full border-[#B6B4AF] border mb-3 rounded" {...register("email")} />

      <OptionalPasswordChange editMode={!!account?.email}>
        <label htmlFor="password" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
          Password
        </label>
        <input type="password" id="password" className="w-full border-[#B6B4AF] border rounded" {...register("password")} />
      </OptionalPasswordChange>

      <label htmlFor="phoneNumber" className="font-extrabold text-[14px] text-[#B6B4AF] block my-2">
        Phone Number
      </label>
      {/* <input type="tel" id="phoneNumber" className="w-full border-[#B6B4AF] border mb-3 rounded" {...register("phoneNumber")} autoComplete="off" /> */}
      <PhoneInput defaultCountry="us" inputClassName="w-full border rounded-lg p-2 focus:outline-none" value={phone} onChange={handlePhoneChange} />
      <p className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">Role</p>
      <Controller
        control={control}
        name="role"
        render={({ field }) => {
          return (
            <>
              {roles.map((role: any) => {
                return (
                  <button
                    key={role}
                    className={`px-2 py-1 text-slate-500 capitalize rounded ${role === field.value ? "bg-[#F88D2A] text-white" : "hover:text-[#F88D2A]"}`}
                    onClick={() => field.onChange(role)}
                  >
                    {role.toLowerCase()}
                  </button>
                )
              })}
            </>
          )
        }}
      />
      <CollaboratorBatchPicker control={control} />
      <div className="flex mt-4">
        <button className="flex-1 bg-slate-300 py-2 mr-3 rounded-md font-semibold" onClick={hide}>
          Cancel
        </button>
        <button
          className={`flex-1 py-2 bg-[#F88D2A] rounded-md font-semibold text-white relative ${isValid ? "" : "opacity-50"}`}
          disabled={!isValid}
          onClick={handleSubmitPress}
        >
          {createSubAccount.isLoading || editSubAccount.isLoading ? (
            <div className="absolute inset-0 flex justify-center items-center">
              <svg.Loading color="white" className="w-5 h-5" />
            </div>
          ) : null}
          <span className={createSubAccount.isLoading || editSubAccount.isLoading ? "opacity-0" : ""}>{account ? "Edit User" : "Add User"}</span>
        </button>
      </div>
    </div>
  )
}

function OptionalPasswordChange({ editMode, children }: { editMode: boolean; children: React.ReactNode }) {
  const [show, setShow] = useState(false)

  if (!editMode || show) return children as JSX.Element

  return (
    <button className="mb-3 text-xs py-1 px-2 bg-[#F88D2A] text-white rounded" onClick={() => setShow(true)}>
      Change Password
    </button>
  )
}
