export const Subscription = [
  { id: 1, title: "No Contract Subscriptions", enable: true },
  { id: 2, title: "Web and Mobile Apps", enable: true },
  { id: 3, title: "Monitor Gravity, Clarity, & Temp 24/7", enable: true },
  { id: 4, title: "Preserve Your Process via the BrewBook", enable: true },
  { id: 5, title: "Set Custom Alerts & Reminders", enable: true },
  { id: 6, title: "Integrated Team Messaging", enable: true },
  { id: 7, title: "Pause Subscriptions On Demand", enable: true },
]
export const Finance = [
  {
    title: "Support 24/7",
    enable: true,
  },
  {
    title: "Mobile control",
    enable: true,
  },
  {
    title: "Access to the platform",
    enable: true,
  },
  {
    title: "Title",
    enable: true,
  },
  {
    title: "Title",
    enable: false,
  },
]

export const Hardware = [
  { id: 1, title: "Stainless Steel CIP Ready Sensor", enable: true },
  { id: 2, title: "Digital Temperature Sensor", enable: true },
  { id: 3, title: "Fits Standard 1.5″ TC Port", enable: true },
  { id: 4, title: "120 VAC 60 Hz, IP69K Connector", enable: true },
  { id: 5, title: "Connects to Your 2.4 GHz WiFi Network", enable: true },
  { id: 6, title: "Backup Battery & Memory", enable: true },
  { id: 7, title: "Lifetime Guarantee", enable: true },
]
export const FAQ = [
  {
    title: "What Is AccuBrew?",
    content: "",
  },
  {
    title: "How does it work?",
    content:
      "AccuBrew uses wireless sensors designed by Gulf Photonics, experts in the field of fiberoptic cables and sensors, to record data such as sugar conversion, clarity, color and temperature during the fermentation process. This data is conveniently displayed on easy-to-read charts on a cloud-based user interface which can be accessed remotely anytime.",
  },
  {
    title: "How much does it cost?",
    content: "The cost for an AccuBrew probe is $495.",
  },
  {
    title: "Where is it made?",
    content: "",
  },
]
