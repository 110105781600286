import { Controller, useForm } from "react-hook-form"
import z from "zod"
import { isPast, format } from "date-fns"
import { AiOutlineClose } from "react-icons/ai"
import { zodResolver } from "@hookform/resolvers/zod"
import { note as noteSchema } from "@accubrew/validation"
import { RouterOutput, trpc } from "@accubrew/state"

import { svg } from "../../../assets"

const formSchema = noteSchema.omit({ id: true, batchId: true })

type Note = RouterOutput["note"]["list"][number]

type Props = {
  batchId: string
  close: () => void
  note?: any
  type?: Note["type"]
}

export function InfoTable({ close, note, batchId }: Props) {
  return (
    <div className="py-6 px-5 shadow">
      <div className="flex justify-between items-center font-bold mb-5">
        <h1 className="text-lg text-textPrimary"> {note?.type === "Batch" ? "Batch Note" : "Reminder"}</h1>
        <button onClick={close}>
          <AiOutlineClose />
        </button>
      </div>

      <table className="border-collapse border border-slate-400 w-[100%] my-4">
        <thead></thead>
        <tbody>
          <tr>
            <td className="border text-textPrimary pl-2 font-bold">Batch:</td>
            <td className="border text-textSecondary  pl-2 ">{note?.batch?.name}</td>
          </tr>
          <tr>
            <td className="border text-textPrimary pl-2 font-bold">Created By:</td>
            <td className="border text-textSecondary pl-2 ">
              {" "}
              {note?.createdBy?.firstName} {note?.createdBy?.lastName}
            </td>
          </tr>
          <tr>
            <td className="border text-textPrimary pl-2 font-bold">Created At:</td>
            <td className="border text-textSecondary pl-2 ">{format(note?.createdAt, "MM-dd h:mm a")}</td>
          </tr>
          {note?.type === "Reminder" && (
            <tr>
              <td className="border text-textPrimary pl-2 font-bold">Reminder Date:</td>
              <td className="border text-textSecondary pl-2 ">{format(note?.reminderDate, "MM-dd h:mm a")}</td>
            </tr>
          )}
          <tr>
            <td className="border text-textPrimary pl-2 font-bold">Note:</td>
            <td className="border text-textSecondary pl-2 ">{note?.description}</td>
          </tr>
        </tbody>
      </table>
      <div className="pl-2">
        <h3 className="font-bold text-textPrimary">Followers:</h3>
        {note?.followers?.length === 0 && <p className="text-textSecondary">No Followers</p>}
        {note?.followers?.map((follower: any) => {
          return (
            <p className="text-textSecondary">
              {follower.firstName} {follower.lastName}
            </p>
          )
        })}
      </div>
      {/* <div className="mx-3">
        <div className="flex">
          <button className="flex-1 bg-orange py-2 mr-3 rounded-md font-semibold" onClick={close}>
            Close
          </button>
        </div>
      </div> */}
    </div>
  )
}
