import React, { useEffect, useState } from "react"
import { svg } from "../../../assets"
import { setDeviceId, trpc, useDeviceId } from "@accubrew/state"
import { Link } from "@tanstack/react-location"
import CancelSubModal from "../../../components/CancelSubscriptionModal"
import { Modal } from "../../../components"

type Subscription = {
  user: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
  }
  id: string
  subscriptionStatus: string
  amount: number
  postalcode: number
  country: string
  city: string
}

type SubscriptionProps = {
  subscriptionDetails: Subscription
  selectedDeviceId: string
  subscriptionEndDate: any
  devices: any
  onChangeDevice?: any
}
const SubscriptionRecord: React.FC<SubscriptionProps> = ({ subscriptionDetails, selectedDeviceId, subscriptionEndDate, devices, onChangeDevice }) => {
  const [deleteSubModal, setDeleteSubModal] = useState<boolean>(false)

  return (
    <>
      <div className="max-w-7xl mx-auto rounded-md shadow-md mx-8 bg-white">
        <div className="m-4 mt-8 block md:flex lg:flex justify-between items-center">
          <h2 className="font-bold text-lg ">Subscription Management</h2>
          <Link to="/dashboard/accubrew/about" className=" cursor-pointer">
            <button className="py-4 md:p-2 lg:p-2 font-bold text-[#F88D2A] flex justify-center items-center rounded">Visit Store</button>
          </Link>
        </div>
        {selectedDeviceId !== "" ? (
          subscriptionDetails != undefined ? (
            <div className="m-4 rounded pb-3">
              <div className="grid md:block lg:block bg-gray-100 rounded-2xl border border-blue p-4 mb-4">
                <div className="ml-4  flex justify-between items-center">
                  <h2 className="">
                    <span className="font-bold text-sm md:text-lg lg:text-lg">Single</span>{" "}
                    <button className="ml-3 text-[#427CBF] rounded border-solid border border-blue p-1 text-sm md:text-lg lg:text-lg">Current</button>
                  </h2>
                  {/* <button className="p-2 font-bold  flex justify-center items-center rounded">
                    <svg.Edit />
                  </button> */}
                  {subscriptionDetails?.subscriptionStatus === "active" ? (
                    <span
                      className="mr-2  mt-2 col-start-1 text-sm font-medium text-orangeDark dark:text-black-300 relative inline-flex items-center cursor-pointer font-bold"
                      onClick={() => setDeleteSubModal(true)}
                    >
                      Cancel
                    </span>
                  ) : (
                    <span
                      className={`mr-2  mt-2 col-span-2 text-sm font-medium ${
                        subscriptionDetails?.subscriptionStatus == "paused" ? "text-darkGreen2" : "text-error2"
                      }  relative inline-flex items-center  font-bold`}
                    >
                      Subcription {subscriptionDetails?.subscriptionStatus === "paused" ? "paused" : "canceled"}
                    </span>
                  )}{" "}
                </div>
                <div className="ml-4 md:flex  lg:flex justify-between items-center">
                  <h2 className="font-bold text-2xl md:text-3xl lg:text-3xl">
                    <span>$</span>
                    {subscriptionDetails?.amount ? subscriptionDetails?.amount : 0}
                  </h2>
                  <p className=" md:p-2 lg:p-2 text-[#858279]  justify-center items-center rounded ">
                    {subscriptionDetails?.subscriptionStatus === "active" ? "Next payment" : "Subscription ends on"} {subscriptionEndDate}
                  </p>
                </div>
                <div className="hidden lg:flex p-2 pt-3">
                  <div className="flex-[2] text-[#858279] shrink-0 grow-1 overflow-hidden">Customer</div>
                  <div className="flex-[2]  text-[#858279] shrink-0 grow-1 overflow-hidden">Probes Claimed</div>
                  <div className="flex-[2] text-[#858279] shrink-0 grow-1 overflow-hidden">Country</div>
                  <div className="flex-[2]  text-[#858279] shrink-0 grow-1 overflow-hidden">City</div>
                  {/* <div className="flex-[2] text-[#858279] shrink-0 grow-1 overflow-hidden">Billing Address</div> */}
                  <div className="flex-[2] text-[#858279] shrink-0 grow-1 overflow-hidden">Postal code</div>
                </div>
                {/* <hr></hr> */}

                <div className=" mb-2 ml-2 p-2  rounded lg:flex lg:bg-transparent lg:mb-0 lg:ml-0 lg:items-center" key="test">
                  <div className=" block md:hidden lg:hidden flex-[2] text-[#858279] shrink-0 grow-1 overflow-hidden mt-2">Customer</div>
                  <div className="flex-[2] shrink-0 grow-1 overflow-hidden font-[700] mb-1">
                    {" "}
                    {subscriptionDetails?.user?.firstName} {subscriptionDetails?.user?.lastName}
                  </div>
                  <div className=" block md:hidden lg:hidden flex-[2] text-[#858279] shrink-0 grow-1 overflow-hidden mt-2">Probes Claimed</div>
                  <div className="flex-[2] shrink-0 grow-1 overflow-hidden mb-1">{devices.data?.devices?.length ?? 0}</div>

                  <div className=" block md:hidden lg:hidden flex-[2] text-[#858279] shrink-0 grow-1 overflow-hidden mt-2">Country</div>
                  <div className="flex-[2] shrink-0 grow-1 overflow-hidden mb-1">{subscriptionDetails?.country ?? "---"}</div>

                  <div className=" block md:hidden lg:hidden flex-[2] text-[#858279] shrink-0 grow-1 overflow-hidden mt-2">City</div>
                  <div className="flex-[2] shrink-0 grow-1 overflow-hidden mb-1">{subscriptionDetails?.city ?? "---"}</div>

                  <div className=" block md:hidden lg:hidden flex-[2] text-[#858279] shrink-0 grow-1 overflow-hidden mt-2">Postal code</div>
                  <div className="flex-[2] shrink-0 grow-1 overflow-hidden mb-1">{subscriptionDetails?.postalcode ?? "---"}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="px-3">
              <hr></hr>
              <div className="flex justify-center items-center py-4 pl-3">
                <p className="font-medium  text-orangeDark">No record found</p>
              </div>
            </div>
          )
        ) : (
          <div className="px-3">
            <hr></hr>
            <div className="flex justify-center items-center py-4 pl-3">
              <p className="font-medium  text-orangeDark">No Device Selected</p>
            </div>
          </div>
        )}
      </div>
      {deleteSubModal && (
        <Modal visible={deleteSubModal !== undefined} onClose={() => setDeleteSubModal(false)}>
          <CancelSubModal
            setDeleteSubModal={setDeleteSubModal}
            onChangeDevice={onChangeDevice}
            selectedDeviceID={selectedDeviceId}
            subscriptionId={subscriptionDetails?.id ?? ""}
          />
        </Modal>
      )}
    </>
  )
}
export default SubscriptionRecord
