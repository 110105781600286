import { Control, useController } from "react-hook-form"
import { subAccount as subAccountSchema } from "@accubrew/validation"
import { MdArrowBack } from "react-icons/md"
import { z } from "zod"
import { trpc, useBatches } from "@accubrew/state"
import { useState } from "react"
import { Modal } from "../../../components"
import { DeviceDropdown } from "../../dashboard/components"
import { svg } from "../../../assets"

type Props = {
  control: Control<z.infer<typeof subAccountSchema>>
  hide: () => void
}

export function CollaboratorBatchPicker({ control }: Pick<Props, "control">) {
  const [batchPickerVisible, setBatchPickerVisible] = useState(false)
  const { field } = useController({ control, name: "role" })

  function showBatchPicker() {
    setBatchPickerVisible(true)
  }

  function dismissBatchPicker() {
    setBatchPickerVisible(false)
  }

  if (field.value !== "COLLABORATOR") return null

  return (
    <>
      <button className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2" onClick={showBatchPicker}>
        Assigned Batches (<CollaboratorBatchesCount control={control} />)
      </button>
      <Modal visible={batchPickerVisible} onClose={dismissBatchPicker}>
        <BatchPicker control={control} hide={dismissBatchPicker} />
      </Modal>
    </>
  )
}

function CollaboratorBatchesCount({ control }: Pick<Props, "control">) {
  const { field } = useController({ control, name: "batches" })
  return <>{field.value?.length ?? 0}</>
}

function BatchPicker({ control, hide }: Props) {
  const user = trpc.user.get.useQuery()
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>()
  const batches = useBatches({ deviceId: selectedDeviceId })
  const { field } = useController({ control, name: "batches" })

  return (
    <div className="bg-white h-[450px] flex flex-col">
      <div className="flex items-center">
        <button className="p-4" onClick={hide}>
          <MdArrowBack size={20} />
        </button>
        <h2 className="font-bold text-lg">Assigned Batches</h2>
      </div>
      <div className="mx-4">
        <DeviceDropdown selectedDeviceId={selectedDeviceId} setSelectedDeviceId={setSelectedDeviceId} />
      </div>
      {selectedDeviceId && batches.isLoading && <svg.Loading className="mx-auto my-4" />}
      <div className="overflow-auto flex-1 ">
        {batches.data?.batches && batches.data.batches.length > 0 && (
          <button
            className="px-4 py-2 mb-3 text-left"
            onClick={() => {
              const unSelectedBatches = batches.data?.batches.filter((batch: any) => !field.value?.includes(batch.id))
              if (unSelectedBatches.length > 0) field.onChange([...(field.value ?? []), ...unSelectedBatches.map((x: any) => x.id)])
            }}
          >
            Select All
          </button>
        )}
        <div className="mx-3 mb-4 grid grid-cols-2 sm:grid-cols-3 gap-2">
          {batches.data?.batches.map((batch: any) => {
            return (
              <button
                key={batch.id}
                className={`px-2 py-1 text-left rounded  whitespace-nowrap ${field.value?.includes(batch.id) ? "bg-[#F88D2A] text-white" : ""}`}
                title={`${batch.name} / ${batch.id}`}
                onClick={() => {
                  if (field.value?.includes(batch.id)) field.onChange(field.value.filter((id) => id !== batch.id))
                  else field.onChange([...(field.value ?? []), batch.id])
                }}
              >
                {batch.name}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}
