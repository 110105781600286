import { Store, createStaticTrpc } from "@accubrew/state"

export function exclude<Entity, Key extends keyof Entity>(entity: Entity, ...keys: Key[]): Omit<Entity, Key> {
  for (let key of keys) delete entity[key]
  return entity
}

// export function calculateROC(v1: number, v2: number) {
//   return (((v1 - v2) / v2 + 0.00000000000000000000000000000001) * 100).toFixed(4)
// }

export function copyToClipboard(text: string) {
  try {
    navigator.clipboard.writeText(text)
  } catch (error) {
    const textArea = document.createElement("textarea")
    textArea.style.position = "fixed"
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.pointerEvents = "none"
    textArea.style.opacity = "0"
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand("copy")
    } catch (err) {
      console.error("Unable to copy to clipboard", err)
    }
    document.body.removeChild(textArea)
  }
}

export function noop() {}

export const URL = `${import.meta.env.VITE_API_URL}/trpc`
export const WS_URL = `${import.meta.env.VITE_WEBSOCKET_URL}`
export function headers() {
  const token = Store.useStore.getState().token
  return token ? { authorization: token } : {}
}
export const staticTrpc = createStaticTrpc({ url: URL, webSocketUrl: WS_URL, headers })
