import React, { useEffect } from "react"
import { svg } from "../../assets/index"
function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}

const config = {
  secondary: "bg-[#FEEEDF] text-[#F88D2A]",
  primary: "bg-[#F88D2A] text-[#FFFFFF]",
  plain: "bg-[#EAEAEA] text-[#342F20]",
}

function Button({ title, variant, onClick, className }: { className?: string; title: string; variant: keyof typeof config; onClick?: () => void }) {
  return (
    <button onClick={onClick} className={`${config[variant]} rounded-[6px] ${className}`}>
      {title}
    </button>
  )
}

export function Calendar() {
  return (
    <Page title="Calendar">
      <div className="flex justify-center h-screen" style={{ alignItems: "center" }}>
        <svg.CommingSoon />
      </div>
    </Page>
  )
}
