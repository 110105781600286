import { RouterOutput } from "@accubrew/state"
import { useEffect, useRef, useState } from "react"
import { MdKeyboardArrowDown } from "react-icons/md"
import { twMerge } from "tailwind-merge"

type Props = {
  loading?: boolean
  disabled?: boolean
  batches: RouterOutput["batch"]["list"]["batches"]
  selectedBatchId?: string | null | undefined
  onBatchIdChange: (batchId: string) => void
  className?: string
  hideWhenDisabled?: boolean
  highlightRunningBatch: boolean
}

function getMessage({ loading, length, disabled }: { loading?: boolean; length?: number; disabled?: boolean }) {
  if (disabled) return "Select Device"
  if (loading) return "Loading..."
  if (length === 0) return "No Batches Found"

  return "Select Batch"
}

export function BatchesDropdown({ disabled, loading, onBatchIdChange, selectedBatchId, className, batches, hideWhenDisabled, highlightRunningBatch }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [searchBatch, setSearchBatch] = useState("")
  const [title, setTitle] = useState("Select Batch")
  const dropdownRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [batches])

  const filtered = batches?.filter((batch: any) => batch?.name?.toLowerCase().includes(searchBatch.toLowerCase()))
  if (hideWhenDisabled && disabled) return null

  const toggleDropdown = (e: any) => {
    setIsOpen(!isOpen)
  }
  const handleInputChange = (e: any) => {
    setSearchBatch(e.target.value.toLowerCase())
  }

  const handleClick = (item: any) => {
    setTitle(item.name)
    onBatchIdChange(item.id)
    setSearchBatch("")
    setIsOpen(false)
  }
  const handleClose = (e: any) => {
    if (e.target.id === "dropdown-menu") {
      setIsOpen(false)
    }
  }
  const length = filtered?.length ?? 0

  return (
    <div className={twMerge("relative border border-gray-300 bg-white shadow-lg rounded-md", className)} ref={dropdownRef}>
      {batches && (
        <div className="relative group h-full">
          <button className="inline-flex justify-between items-center w-full px-3 text-gray-700 bg-white rounded-md shadow-sm h-full" onClick={toggleDropdown}>
            {selectedBatchId !== undefined ? <span>{title}</span> : "Select Batch"}
            <MdKeyboardArrowDown className="h-6 w-6 text-gray-500" />
          </button>
          <div
            id="dropdown-menu"
            onClick={handleClose}
            className={`absolute right-0 min-h-fit max-h-80 overflow-y-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 w-full ${
              isOpen ? "" : "hidden"
            }`}
          >
            <input
              id="search-input"
              className="block w-full px-3 border rounded-md border-gray-300 focus:outline-none mb-1"
              type="text"
              placeholder="Search Batch"
              autoComplete="off"
              value={searchBatch}
              onChange={(e) => handleInputChange(e)}
            />
            {length === 0 ? (
              <p className="px-3">No batch found</p>
            ) : (
              <>
                {filtered.map((item: any, index:any) => (
                  <button
                    key={index}
                    value={item?.id}
                    onClick={() => handleClick(item)}
                    className={`${
                      item.status === "RUNNING" && highlightRunningBatch ? "bg-success text-white" : "bg-inherit hover:bg-gray-200"
                    } block w-full text-left px-3 cursor-pointer rounded-md"
                          `}
                  >
                    {item.name}
                  </button>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
