import React, { useEffect } from "react"
import { svg } from "../../assets/index"
function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}

const config = {
  secondary: "bg-[#FEEEDF] text-[#F88D2A]",
  primary: "bg-[#F88D2A] text-[#FFFFFF]",
  plain: "bg-[#EAEAEA] text-[#342F20]",
}

export function MessageBoard() {
  return (
    <Page title="Message Board">
      <div className="flex justify-center h-screen" style={{ alignItems: "center" }}>
        <svg.CommingSoon />
      </div>
    </Page>
  )
}
