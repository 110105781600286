import React, { useEffect, useState } from "react"
import { svg } from "../../../assets"
import { setDeviceId, trpc, useDeviceId } from "@accubrew/state"

type Transaction = {
  user: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
  }
  amount: number
}

type TransactionHistoryProps = {
  transactions: Transaction[]
  selectedId: string
  transactionsHistoryUrl: string
}
const TransactionRecord: React.FC<TransactionHistoryProps> = ({ transactions, selectedId, transactionsHistoryUrl }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [startingIndex, setStartingIndex] = useState(0)
  const [endingIndex, setEndingIndex] = useState(10)
  const totalPages = Math.ceil(transactions?.length / 10)
  const [currentPage, setCurrentPage] = useState(0)
  const handleNext = () => {
    if (endingIndex < transactions.length) {
      setStartingIndex(startingIndex + 10)
      setEndingIndex(endingIndex + 10)
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePrevious = () => {
    if (startingIndex > 0) {
      setStartingIndex(startingIndex - 10)
      setEndingIndex(endingIndex - 10)
      setCurrentPage(currentPage - 1)
    }
  }

  const handlePageClick = (index: number) => {
    let n = index * 10
    setStartingIndex(n)
    setEndingIndex(n + 10)
    setCurrentPage(index)
  }

  const handleDownload = (invoiceLink: string) => {
    setIsLoading(true)
    const link: any = document.createElement("a")
    link.download = invoiceLink
    link.href = invoiceLink
    link.click()
    setTimeout(() => {
      setIsLoading(false)
    }, 1000) // Simulating a delay for the download
  }
  return (
    <>
      <div className="max-w-7xl mx-auto rounded-md shadow-md bg-white">
        <div className="m-4 mt-8 flex justify-between items-center">
          <h2 className="font-bold text-lg">Transactions</h2>
          {transactions.length > 0 && transactionsHistoryUrl !== "" && (
            <button className="p-2 text-[#43D82A] flex justify-center items-center rounded">
              {/* {isLoading ? (
                <svg.Loading className="w-6 h-6" />
              ) : ( */}
              <span className="flex justify-center items-center" onClick={() => handleDownload(transactionsHistoryUrl)}>
                <svg.Download /> Export
              </span>
              {/* )} */}
            </button>
          )}
        </div>
        {selectedId !== "" ? (
          <>
            <div className="table-container md:overflow-x-scroll lg:overflow-x-hidden hidden md:hidden lg:block">
              {transactions.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  {/* <!-- Table headers --> */}
                  <thead>
                    <tr>
                      <th className="px-2 py-3  text-left tracking-wider">Name</th>
                      <th className="px-2 py-3  text-left tracking-wider">Email</th>
                      <th className="px-2 py-3  text-left tracking-wider">Phone</th>
                      <th className="px-2 py-3  text-left tracking-wider">Subscription</th>
                      <th className="px-2 py-3  text-left tracking-wider">Date</th>
                      <th className="px-2 py-3  text-center tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  {/* <!-- Table body --> */}
                  <tbody className="bg-white divide-y divide-gray-200">
                    {transactions?.slice(startingIndex, endingIndex).map((acc: any) => {
                      return (
                        <tr>
                          <td className="px-2 py-4 whitespace-nowrap">
                            {" "}
                            {acc.user.firstName} {acc.user.lastName}
                          </td>
                          <td className="px-2 py-4 whitespace-nowrap">{acc.user.email ?? "---"}</td>
                          <td className="px-2 py-4 whitespace-nowrap">{acc.user.phoneNumber != "" ? acc.user.phoneNumber : "---"}</td>
                          <td className="px-2 py-4 whitespace-nowrap">Standard ${acc.amount}</td>
                          <td className="px-2 py-4 whitespace-nowrap">
                            {new Date(Number(acc?.subscriptionStartDate) * 1000).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })}
                          </td>
                          <td className="px-2 py-4 whitespace-nowrap grid place-items-center cursor-pointer">
                            {/* {isLoading ? <svg.Loading className="w-6 h-6" /> :  */}
                            <svg.Download onClick={() => handleDownload(acc.subscriptionInvoiceLink)} />
                            {/* // } */}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="px-3">
                  <hr></hr>
                  <div className="flex justify-center items-center py-4 pl-3">
                    <p className="font-medium  text-orangeDark">No record found</p>
                  </div>
                </div>
              )}
            </div>
            <div className="m-4 rounded lg:bg-white block md:block lg:hidden">
              <hr></hr>
              <div className="grid md:grid-cols-2  mt-4">
                {transactions.length > 0 ? (
                  transactions?.map((acc: any) => {
                    return (
                      <div className="bg-white md:shadow mb-2 ml-2 p-2  rounded lg:flex lg:bg-transparent lg:mb-0 lg:ml-0 lg:items-center" key={acc.email}>
                        <div className="flex-[2] shrink-0 grow-1 overflow-hidden font-[700] mb-1 whitespace-normal">
                          <div className=" flex justify-between items-center">
                            <p className="">
                              {acc.user.firstName} {acc.user.lastName}
                            </p>
                            <button
                              className="p-2   text-[#43D82A] flex justify-center items-center rounded "
                              onClick={() => handleDownload(acc.subscriptionInvoice)}
                            >
                              <svg.Download />
                            </button>
                          </div>
                        </div>
                        <div className="flex-[2] shrink-0 grow-1 overflow-hidden text-wrap mb-1 text-clip">{acc.user.email ?? "---"}</div>
                        <div className="flex-[2] shrink-0 grow-1 overflow-hidden mb-1">{acc.user.phoneNumber != "" ? acc.user.phoneNumber : "---"}</div>
                        <div className="flex-[2] shrink-0 grow-1 overflow-hidden mb-1 font-bold">Standard ${acc.amount}</div>
                        <div className="flex-[2] shrink-0 grow-1 overflow-hidden mb-1">
                          {new Date(Number(acc?.subscriptionStartDate) * 1000).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })}
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className=" flex justify-center items-center py-4">
                    <p className="font-medium  text-orangeDark">No record found</p>
                  </div>
                )}
              </div>
            </div>
            {transactions?.length > 0 && (
              <div className="flex items-center justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between mb-2">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing
                      <span className="font-medium mx-1">
                        {endingIndex < transactions.length ? (
                          <>
                            {startingIndex + 1} to {endingIndex}
                          </>
                        ) : (
                          <>{transactions.length}</>
                        )}
                      </span>
                      of
                      <span className="font-medium mx-1 text-orange">{transactions?.length}</span>
                    </p>
                  </div>
                  <div>
                    <nav className="isolate inline-flex gap-2 -space-x-px rounded-md shadow-sm mt-4 md:mt-0 lg:mt-0" aria-label="Pagination">
                      <a
                        onClick={handlePrevious}
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-black bg-[#EAEAEA] focus:z-20 focus:outline-offset-0 cursor-pointer"
                      >
                        <span className="sr-only">Previous</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path
                            fillRule="evenodd"
                            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                      {[...Array(totalPages)].map((_, index) => (
                        <a
                          aria-current="page"
                          className={`relative z-10 inline-flex items-center rounded  px-4 py-2 text-sm font-semibold cursor-pointer ${
                            currentPage === index ? "bg-orange text-white" : ""
                          }`}
                          onClick={() => handlePageClick(index)}
                        >
                          {index + 1}
                        </a>
                      ))}
                      <a
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-black bg-[#EAEAEA] focus:z-20 cursor-pointer"
                        onClick={handleNext}
                      >
                        <span className="sr-only">Next</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path
                            fillRule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="px-3">
            <hr></hr>
            <div className="flex justify-center items-center py-4 pl-3">
              <p className="font-medium  text-orangeDark">No Device selected</p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default TransactionRecord
