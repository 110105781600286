import React, { useState, useEffect } from "react"
import { Store, queryClient } from "@accubrew/state"
import { Layout } from "./Layout"
import { setTemp, trpc, useTemp, setUnit, useUnit } from "@accubrew/state"
import ReactFlagsSelect from "react-flags-select"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Controller, useForm } from "react-hook-form"
import { Button } from "../../components/Button"
import { Modal } from "../../components"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { svg, images } from "../../assets"
import { AiOutlineDelete, AiOutlineUpload } from "react-icons/ai"
import { useStripe, useElements, CardElement, Elements } from "@stripe/react-stripe-js"
import { toast } from "react-toastify"


function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}

const resolver = zodResolver(
  z.object({
    firstName: z.string().min(3),
    lastName: z.string().optional(),
    phoneNumber: z.string().optional(),
    countryCode: z.string().optional(),
    dialCode: z.string().optional(),
    // photo: z.string().optional(),
  }),
)

export function Profile() {
  const temp = useTemp()
  const unit = useUnit()
  const stripe = useStripe()
  const elements = useElements()
  const [paymentModal, setPaymentModal] = useState<boolean>(false)

  const user: any = trpc.user.get.useQuery()
  const temperatureUnit = trpc.user.temperatureUnit.useQuery({ email: user.data?.email!, unit: temp ?? "f" })
  const sgUnit = trpc.user.sgUnit.useQuery({ email: user.data?.email!, unit: unit ?? "SG" })
  const updatePaymentMethod = trpc.payment.updatePaymentMethod.useMutation({
    onSuccess(data) {},
  })
  const updateUser: any = trpc.user.update.useMutation()
  const context = trpc.useContext()
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    setUnit(user?.data?.sgUnit)
  }, [])
  const { control, setValue, getValues, formState, register } = useForm({
    resolver,
    defaultValues: {
      firstName: user.data?.firstName ?? "",
      lastName: user.data?.lastName ?? "",
      email: user.data?.email ?? "",
      phoneNumber: user.data?.phoneNumber ?? "",
      countryCode: user.data?.countryCode ? user.data?.countryCode.toLowerCase() : "",
      dialCode: user.data?.dialCode ? user.data?.dialCode.toLowerCase() : "",
      photo: "",
      muteNotification: user?.data?.muteNotification ?? false,
    },
    mode: "onChange",
  })
  const { isValid, errors }: any = formState
  const ProfileData = getValues()
  const isLoading = updateUser.isLoading || updatePaymentMethod.isLoading
  const handleSavePress = () => {
    const data = getValues()
    updateUser.mutate(data, {
      async onSuccess() {
        await context.user.get.refetch()
        setIsEdit(false)
      },
    })
  }
  const handleNotificationToggle = () => {
    updateUser.mutate(
      {
        firstName: user?.data?.firstName ?? "",
        muteNotification: !user?.data?.muteNotification,
      },
      {
        async onSuccess(data: any) {
          await context.user.get.refetch()
        },
        onError(error: any) {
          console.log("ERROR user.update muteNotification", error)
        },
      },
    )
  }
  const handleTempSwitchPress = async (temp: "c" | "f") => {
    setTemp(temp)
    updateUser.mutate(
      {
        firstName: user?.data?.firstName ?? "",
      },
      {
        async onSuccess(data: any) {
          await context.user.get.refetch()
        },
        onError(error: any) {
          console.log("ERROR user.update", error)
        },
      },
    )
  }

  const handleUnitSwitchPress = async (unit: "SG" | "Plato") => {
    setUnit(unit)
    updateUser.mutate(
      {
        firstName: user?.data?.firstName ?? "",
      },
      {
        async onSuccess(data: any) {
          await context.user.get.refetch()
        },
        onError(error: any) {
          console.log("ERROR user.update", error)
        },
      },
    )
  }
  const handleData = () => {
    if (elements && stripe ) {
      const cardElement: any = elements.getElement(CardElement)
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: user?.data?.firstName + " " + user?.data?.lastName,
            email: user?.data?.email ?? undefined,
            phone: user?.data?.phoneNumber ?? undefined,
          },
        })
        .then((result:any) => {
          updatePaymentMethod?.mutate(
            {
              email: user?.data?.email ? user?.data?.email : "",
              paymentMethodId: result?.paymentMethod?.id,
              
            },
            {
              onSuccess(data: any) {
                setPaymentModal(false)
                toast.success("Payment method successfully updated")
             
              },
              onError(error:any) {
                toast.error(error.message)
              },
            },
          )
        })
    }
  
  }
  return (
    <Page title="Profile">
      <Layout>
        <div className="card shadow p-10 mx-4 bg-white">
          <div className="grid md:grid-cols-4 lg:grid-cols-4">
            <div className=" rounded  mr-6">
              <div className="hidden md:block lg:block relative w-200 h-300 bg-cover bg-center">
                <img src={images.Avatar} />
                {isEdit && (
                  <>
                    {/* Centered icon */}
                    <div className="absolute inset-0 flex items-center justify-center">
                      <AiOutlineUpload color="white" className="h-6 w-6" />
                    </div>

                    {/* Bottom-centered icon */}
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
                      <AiOutlineDelete color="white" className="h-6 w-6" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-span-3 ">
              <div className=" flex justify-between items-center">
                <h1 className="font-bold text-[22px]">Personal Info</h1>
                <div className="flex items-center justify-center">
                  <span
                    className="mr-3 cursor-pointer"
                    onClick={() => {
                      setIsEdit(!isEdit)
                    }}
                  >
                    <svg.Edit />
                  </span>
                  <button className="text-gray rounded border-solid border border-gray p-2 px-4">
                    {" "}
                    {user.data?.role ? user.data?.role.charAt(0).toUpperCase() + user.data?.role.slice(1).toLowerCase() : "Owner"}
                  </button>
                </div>
              </div>
              <div className="block md:hidden lg:hidden">
                <div className=" relative w-40 h-40 bg-cover bg-center p-3">
                  <img src={images.Avatar} />
                  {isEdit && (
                    <>
                      {/* Centered icon */}
                      <div className="absolute inset-0 flex items-center justify-center">
                        <AiOutlineUpload color="white" className="h-6 w-6" />
                      </div>

                      {/* Bottom-centered icon */}
                      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
                        <AiOutlineDelete color="white" className="h-6 w-6" />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-4 lg:grid-cols-4 ">
                <div className="col-span-3">
                  {" "}
                  <div className="grid md:grid-cols-2 lg:grid-cols-2 ">
                    <div className=" py-3">
                      <p className="font-medium font-bold text-textSecondary">First Name</p>
                      {isEdit ? (
                        <>
                          <div
                            className={`border rounded-[6px] ${formState?.errors?.firstName && "border-red-500"} ${
                              formState?.errors?.firstName ? "mb-2" : "mb-5"
                            }`}
                          >
                            <input type="text" id="firstName" className="w-full border-none bg-transparent rounded-[6px]" {...register("firstName")} />
                          </div>
                          {formState?.errors?.firstName?.message && <p className="text-red-500 text-sm mb-5">{errors?.firstName?.message}</p>}
                        </>
                      ) : (
                        <p className="font-medium">{user?.data?.firstName ?? "---"}</p>
                      )}
                    </div>
                    <div className=" py-3 md:ml-3 lg:ml-3">
                      {" "}
                      <p className="font-medium font-bold text-textSecondary">Last Name</p>
                      {isEdit ? (
                        <>
                          <div
                            className={`border rounded-[6px] ${formState?.errors?.lastName && "border-red-500"} ${
                              formState?.errors?.lastName ? "mb-2" : "mb-5"
                            }`}
                          >
                            <input type="text" id="lastName" className="w-full border-none bg-transparent rounded-[6px]" {...register("lastName")} />
                          </div>
                          {formState?.errors?.lastName?.message && <p className="text-red-500 text-sm mb-5">{errors?.lastName?.message}</p>}
                        </>
                      ) : (
                        <p className="font-medium">{user?.data?.lastName ?? "---"}</p>
                      )}
                    </div>
                    <div className=" py-3">
                      {" "}
                      <p className="font-medium font-bold text-textSecondary">Phone</p>
                      {isEdit ? (
                        <>
                          <div
                            className={`border rounded-[6px] ${formState?.errors?.phoneNumber && "border-red-500"} ${
                              formState?.errors?.phoneNumber ? "mb-2" : "mb-5"
                            }`}
                          >
                            <PhoneInput
                              country={ProfileData.countryCode ?? "us"}
                              inputClass="w-full"
                              value={`+${ProfileData.dialCode}${ProfileData.phoneNumber}`}
                              onChange={(phone, country: any) => {
                                const dialCode = country.dialCode
                                const countryCode = country.countryCode
                                const phoneNumber = phone.replace(`${dialCode}`, "")
                                setValue("countryCode", countryCode.toUpperCase())
                                setValue("phoneNumber", phoneNumber)
                                setValue("dialCode", dialCode)
                              }}
                            />
                          </div>
                          {formState?.errors?.phoneNumber?.message && <p className="text-red-500 text-sm mb-5">{errors?.phoneNumber?.message}</p>}
                        </>
                      ) : (
                        <p className="font-medium">{user?.data?.phoneNumber ? "+" + user?.data?.dialCode + user?.data?.phoneNumber : "---"}</p>
                      )}
                    </div>
                    <div className=" py-3 md:ml-3 lg:ml-3">
                      {" "}
                      <p className="font-medium font-bold text-textSecondary">Email</p>
                      {isEdit ? (
                        <>
                          <div className={`border rounded-[6px] ${formState?.errors?.email && "border-red-500"} ${formState?.errors?.email ? "mb-2" : "mb-5"}`}>
                            <input type="email" id="email" className="w-full border-none bg-transparent rounded-[6px]" {...register("email")} disabled />
                          </div>
                          {formState?.errors?.email?.message && <p className="text-red-500 text-sm mb-5">{errors?.email?.message}</p>}
                        </>
                      ) : (
                        <p className="font-medium">{user?.data?.email ?? "---"}</p>
                      )}
                    </div>
                    {isEdit && (
                      <div className="flex  gap-x-3 mb-4 mt-2">
                        <Button variant="primary" className="relative px-4 py-2 " disabled={isLoading} isLoading={isLoading} onClick={handleSavePress}>
                          Save
                        </Button>
                        <Button
                          variant="plain"
                          className="px-4 py-2"
                          onClick={() => {
                            setIsEdit(!isEdit)
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex pt-5 md:justify-center lg:justify-center">
                  {" "}
                  <ReactFlagsSelect
                    className="w-[100px] bg-whiterounded-lg border-none md:ml-auto lg:ml-auto "
                    selectButtonClassName="p-0"
                    countries={["US"]}
                    customLabels={{ US: "EN" }}
                    placeholder="lng"
                    selected="US"
                    onSelect={function (countryCode: string): void {
                      throw new Error("Function not implemented.")
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr className="my-8" />
          <div className="">
            <h1 className="font-bold text-[22px]">Global Settings</h1>
            <div className="grid md:grid-cols-3 lg:grid-cols-3">
              <div>
                {" "}
                <div className="relative inline-flex items-center mt-2">
                  <span className=" ml-2 mr-2 text-md font-medium  relative inline-flex items-center">Do not disturb</span>

                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer"></input>
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:#F88D2A peer-focus:ring-4 peer-focus:#F88D2A  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:#F88D2A peer-checked:bg-orange-600"></div>
                  </label>
                </div>
                <div className="relative inline-flex items-center mt-2">
                <Button
                          variant="primary"
                          className="px-4 py-2"
                          onClick={() => {
                           setPaymentModal(true)
                          }}
                        >
                         Update payment method
                        </Button>
                 
                </div>
              </div>
              <div>
                {/* toggle */}
                <div className="relative inline-flex items-center mt-2">
                  <span className=" ml-2 mr-2 text-md font-medium  relative inline-flex items-center">Mute in app notifications</span>

                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" checked={user?.data?.muteNotification} onChange={handleNotificationToggle} />
                    <div className={`w-11 h-6 rounded-full peer ${user?.data?.muteNotification ? "bg-[#F88D2A]" : "bg-gray-200"}`}>
                      <div
                        className={`after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${
                          user?.data?.muteNotification ? "after:border-white after:translate-x-full" : ""
                        }`}
                      ></div>
                    </div>
                  </label>
                </div>
                {/* Units */}
                <label className="border inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800 mt-4">
                  <input id="Toggle3" type="checkbox" className="hidden peer" />
                  <span className="px-6 py-2 rounded-md bg-darkOrangeDark text-white">Dark</span>
                  <span className="px-6 py-2 rounded-md bg-white">Light</span>
                  <span className="px-6 py-2 rounded-md bg-white">Auto</span>
                </label>
                {/* theme */}
                <div>
                  <label className=" border inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800 mt-4">
                    <input id="Toggle3" type="checkbox" className="hidden peer" />
                    <span
                      className={`px-6 py-2 rounded-md  ${temp === "f" ? "bg-darkOrangeDark text-white" : "bg-white"}`}
                      onClick={() => {
                        handleTempSwitchPress("f")
                      }}
                    >
                      °F
                    </span>
                    <span
                      className={`px-6 py-2 rounded-md ${temp === "c" ? "bg-darkOrangeDark text-white" : "bg-white"}`}
                      onClick={() => {
                        handleTempSwitchPress("c")
                      }}
                    >
                      °C
                    </span>
                  </label>
                </div>

                {/* sgUnits */}
                <div>
                  <label className=" border inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800 mt-4">
                    <input id="Toggle3" type="checkbox" className="hidden peer" />
                    <span
                      className={`px-6 py-2 rounded-md  ${unit === "SG" ? "bg-darkOrangeDark text-white" : "bg-white"}`}
                      onClick={() => {
                        handleUnitSwitchPress("SG")
                      }}
                    >
                     SG
                    </span>
                    <span
                      className={`px-6 py-2 rounded-md ${unit === "Plato" ? "bg-darkOrangeDark text-white" : "bg-white"}`}
                      onClick={() => {
                        handleUnitSwitchPress("Plato")
                      }}
                    >
                      Plato
                    </span>
                  </label>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        {paymentModal && (
        <Modal visible={paymentModal !== undefined} onClose={() => setPaymentModal(false)}>
          <h2 className="px-4 pt-4 pb-2 text-lg font-bold">Credit/Debit Card</h2>
          <p className="px-4 mb-4 font-medium">
            Probe to be subscribe by <span className="text-[#F88D2A]">{user?.data?.email}</span>
          </p>
         
            <div className="border rounded-md mx-4 p-2 my-5 ">
              {" "}
              <CardElement
                options={{
                  style: {
                    base: {
                      color: "#303238",
                      fontSize: "16px",
                      fontFamily: "sans-serif",
                      fontSmoothing: "antialiased",

                      "::placeholder": {
                        color: "#CFD7DF",
                      },
                    },
                    invalid: {
                      color: "#e5424d",
                      ":focus": {
                        color: "#303238",
                      },
                    },
                  },
                }}
              />
            </div>
        

          <div className="flex justify-center gap-x-3 mb-4">
            <Button onClick={() => setPaymentModal(false)} variant="plain" className="px-4 py-2">
              Cancel
            </Button>
            <Button onClick={handleData} variant="primary" className="relative px-4 py-2" disabled={isLoading} isLoading={isLoading}>
              Confirm
            </Button>
          </div>
        </Modal>
      )}
      </Layout>
    </Page>
  )
}
