import React from "react"
import classnames from "classnames"

import { usePagination, DOTS } from "../hooks"

interface PaginationProps {
  onPageChange: (page: number) => void
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  className?: string
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { onPageChange, totalCount, siblingCount = window?.innerWidth <= 640 ? 0 : 1, currentPage, pageSize, className } = props
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  let firstPage = paginationRange[0]

  return (
    <>
      <div className="flex items-center justify-between w-full my-5 lg:my-0">
        <div className="flex sm:flex-1 sm:items-center sm:justify-between flex-col md:flex-row w-full px-3 gap-y-3">
          <div>
            {/* <p className="text-sm text-textTertiary">
      Showing{" "}
      {endingIndex < batches?.length ? (
        <>
          {startingIndex + 1} to {endingIndex}
        </>
      ) : (
        <>{batches?.length}</>
      )}{" "}
      of <span className="text-orange">{batches.length}</span> notes
    </p> */}
          </div>
          <div className={`w-auto ${pageSize <= 5 ? "md:w-auto" : "md:w-[420px]"}`}>
            <nav className="isolate inline-flex flex-wrap justify-between w-full gap-1 mr-0 md:mr-6 lg:mr-0" aria-label="Pagination">
              <button
                className={`relative inline-flex rounded items-center px-2 py-2 ${
                  currentPage === firstPage ? "bg-[#F0F0F0] text-[#D6D6D6]" : "bg-[#EAEAEA] text-[#342F20]"
                } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                onClick={onPrevious}
                disabled={currentPage === firstPage}
              >
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                  return <button>...</button>
                }

                return (
                  <button
                    className={`relative z-10 rounded inline-flex items-center ${
                      pageNumber === currentPage ? "bg-[#F3BD48] text-white" : "text-gray-900"
                    } px-4 py-2 text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
                    onClick={() => onPageChange(pageNumber as number)}
                  >
                    {pageNumber}
                  </button>
                )
              })}

              <button
                className={`relative rounded inline-flex items-center px-2 py-2 ${
                  currentPage === lastPage ? "bg-[#F0F0F0] text-[#D6D6D6]" : "bg-[#EAEAEA] text-[#342F20]"
                } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                onClick={onNext}
                disabled={currentPage === lastPage}
              >
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pagination
