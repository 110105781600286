import { setDeviceId, trpc, useDeviceId } from "@accubrew/state"
import { Dispatch, SetStateAction } from "react"
import { twMerge } from "tailwind-merge"

type Props = {
  className?: string
  selectedDeviceId?: string
  setSelectedDeviceId?: Dispatch<SetStateAction<string | undefined>>
  setSelectedBatchId?: Dispatch<SetStateAction<string | null | undefined>>
}

export function DeviceDropdown({ className, selectedDeviceId, setSelectedDeviceId, setSelectedBatchId }: Props) {
  const deviceId = useDeviceId()
  const devices = trpc.device.list.useQuery()

  function getDeviceId() {
    return setSelectedDeviceId ? selectedDeviceId : deviceId
  }

  function handleDeviceIdChanged(deviceId: string) {
    setSelectedDeviceId?.(deviceId) ?? setDeviceId(deviceId)
    setSelectedBatchId?.(undefined)
  }

  const length = devices.data?.devices.length ?? 0

  return (
    <div className={twMerge("relative border border-gray-300 text-gray-800 bg-white shadow-lg w-full rounded-md", className)}>
      {length === 0 && !devices.isLoading && <div className="w-full py-1 px-2 bg-white border-none text-md font-[600]">No Devices Found</div>}
      {devices.isLoading && <div className="w-full py-1 px-2 bg-white border-none w pr-9">Loading...</div>}
      {length > 0 && (
        <select
          className="appearance-none w-full py-1 px-2 bg-white border-none pr-9 line-clamp-1 rounded-md"
          value={getDeviceId() ?? "none"}
          id="device-dropdown"
          onChange={(e) => handleDeviceIdChanged(e.target.value)}
        >
          <option disabled value="none">
            Select Device
          </option>
          {devices.data?.devices.map((device: any) => (
            <option value={device.particleId} key={device.particleId}>
              {device.name} / {device.particleId}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}
