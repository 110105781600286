import React, { useState } from "react"
import { images, svg } from "../../../assets"
import { AiOutlineDelete } from "react-icons/ai"
import { format } from "date-fns"
import { RouterOutput, StateContainer, trpc, useStateContainer, useBatches, setBatchId, useDeviceId } from "@accubrew/state"
import { BatchDropdown, ConfirmModal, Modal } from "../../../components"
import Pagination from "../../../components/Pagination"
import { useNavigate } from "@tanstack/react-location"

type BatchesTypes = {
  createdAt: string
  probeParticleId: any
  name: string
  status: string
  updatedAt: string
}

type BatchListingProps = {
  page: number
  setPage: (page: number) => void
  batches: any
  isLoading: boolean
  selectedDeviceId: string
  disabled: boolean
  handleCheckboxClick: (batch: any) => void
  selectedBatches: string[]
  setSelectedBatches: any
}
type BatchDeleteType = { visible: boolean; batchId?: any }

const BatchListing: React.FC<BatchListingProps> = ({
  page,
  setPage,
  batches,
  selectedDeviceId,
  disabled,
  handleCheckboxClick,
  selectedBatches,
  setSelectedBatches,
}) => {
  const navigate = useNavigate()
  const user = trpc.user.get.useQuery()

  const isNotOperator = ["MANAGER", "PRODUCTION", "COLLABORATOR"].includes(user.data!.role)
  const deleteBatch = trpc.batch.delete.useMutation()
  const deleteBatchConfirmRef = useStateContainer<BatchDeleteType>()
  // const batchList = trpc.batch.list.useQuery({ probeId: selectedDeviceId! }, { enabled: !disabled })
  const isLoading = batches.isLoading

  return (
    <>
      {/* mobile and tablet */}
      {isLoading && <svg.Loading className="mx-auto mt-16" />}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:hidden gap-4">
        {batches?.data?.batches?.length > 0
          ? batches?.data?.batches?.map((batch: any) => (
              <div className="bg-white shadow-md p-2 rounded-lg w-full lg:hidden font-[Mulish]">
                <div className="flex justify-between gap-3">
                  <div className="flex gap-3 items-center">
                    <input
                      type="checkbox"
                      name=""
                      id="checkbox"
                      className="rounded-[4px] w-5 h-5 border-[#B6B4AF] text-darkOrangeDark"
                      checked={selectedBatches.includes(batch)}
                      onChange={(e) => handleCheckboxClick(batch)}
                    />
                    <p className="my-3 text-textSecondary text-left text-base"> {format(batch.createdAt, "MM/dd/yyyy")}</p>
                  </div>
                  {!isNotOperator && (
                    <button
                      disabled={batch?.status === "RUNNING"}
                      onClick={() => deleteBatchConfirmRef.current?.setState({ visible: true, batchId: batch?.id })}
                    >
                      <AiOutlineDelete className={`${batch?.status !== "RUNNING" ? "text-textTertiary" : "text-btnTertiary"} h-6 w-6`} />
                    </button>
                  )}
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-[16px] text-left">{batch?.name}</p>

                  <p
                    className={`py-1 px-4 border rounded-xl text-[14px] ${
                      batch?.status === "RUNNING" ? "bg-green3 text-successful" : "bg-lightPurple2 text-purple"
                    }`}
                  >
                    {batch?.status?.charAt(0).toUpperCase() + batch?.status?.slice(1).toLowerCase()}
                  </p>
                </div>
                <p
                  className="text-base text-left cursor-pointer font-semibold"
                  onClick={() => {
                    navigate({ to: "/dashboard/batches/batch-history" }), setBatchId(batch?.id)
                  }}
                >
                  {batch?.deviceName ? batch?.deviceName : batch?.probeParticleId}
                </p>

                <p className="text-base font-bold text-left">{batch?.createdBy ?? "----"}</p>

                {/* <div className="flex justify-start gap-2 overflow-scroll no-scrollbar my-4 lg:hidden">
            <button className="bg-[#E7EEF7] text-[#427CBF] rounded-3xl px-2 py-1 flex justify-center items-center">
              <span>#batch</span>
            </button>
            <button className="bg-[#E7EEF7] text-[#427CBF] rounded-3xl px-2 py-1 flex justify-center items-center">
              <span>#notification</span>
            </button>
            <button className="bg-[#E7EEF7] text-[#427CBF] rounded-3xl px-2 py-1 flex justify-center items-center">
              <span>+3</span>
            </button>
          </div> */}
              </div>
            ))
          : !isLoading && <span className="font-bold">No Notes Found</span>}
      </div>

      {/* End mobile and tablet */}

      {/* lg screen */}

      <div className="table-container md:overflow-x-scroll lg:overflow-x-hidden hidden md:hidden lg:block w-full ">
        {batches?.data?.batches?.length > 0 ? (
          <table className="w-full divide-y divide-gray-200 border-separate border-spacing-y-3 ">
            {/* <!-- Table headers --> */}
            <thead className="border-b border-b-red-100">
              <tr className="w-full text-textSecondary ">
                <th className="text-left">
                  <div className="flex items-center gap-1">
                    <span className="pl-2">Start Date</span>
                    {/* <svg.Sort/> */}
                  </div>
                </th>

                <th className=" text-left">
                  <div className="flex items-center gap-1">
                    <span>Batches</span>
                  </div>
                </th>
                <th className=" text-left">
                  <div className="flex items-center gap-1">
                    <span>Probe</span>
                  </div>
                </th>
                <th className=" text-left">
                  <div className="flex items-center gap-1">
                    <span>Created By</span>
                  </div>
                </th>
                <th className=" text-left">Status</th>
                {/* <th className="w-[20%] text-left">
                  <div className="flex items-center gap-1">
                    <span>Tags</span>
                  
                  </div>
                </th> */}
                {!isNotOperator && <th className="ps-0 lg:ps-5 text-center">Actions</th>}
              </tr>
            </thead>

            {/* <!-- Table body --> */}
            <tbody className="bg-white divide-y divide-gray-200 ">
              {batches?.data?.batches?.map((batch: any) => {
                return (
                  <tr className="w-full  space-y-3 shadow">
                    <td className="py-2 pl-2 text-textSecondary text-left"> {format(batch?.createdAt, "MM/dd/yyyy")}</td>

                    <td className="py-2 text-left w-60 2xl:w-96">{batch?.name}</td>
                    <td
                      className="py-2  text-left cursor-pointer font-semibold"
                      onClick={() => {
                        navigate({ to: "/dashboard/batches/batch-history" }), setBatchId(batch?.id)
                      }}
                    >
                      {batch?.deviceName ? batch?.deviceName : batch?.probeParticleId}
                    </td>
                    <td className="py-2 font-bold text-left">{batch?.createdBy ?? "----"}</td>

                    <td className="py-2 text-center">
                      <p
                        className={`border p-1 rounded-lg font ${batch?.status === "RUNNING" ? "bg-green3 text-successful" : "bg-lightPurple2 text-[#BF0095]"}`}
                      >
                        {batch?.status?.charAt(0).toUpperCase() + batch?.status?.slice(1).toLowerCase()}
                      </p>
                    </td>

                    {/* <td className="py-2 w-[20%] text-left flex gap-1">
                          <button className="bg-[#E7EEF7] text-[#427CBF] rounded-3xl px-2 py-1 flex justify-center items-center">
                            <span>#batch</span>
                          </button>
                          <button className="bg-[#E7EEF7] text-[#427CBF] rounded-3xl px-2 py-1 flex justify-center items-center">
                            <span>#notification</span>
                          </button>
                          <button className="bg-[#E7EEF7] text-[#427CBF] rounded-3xl px-2 py-1 flex justify-center items-center">
                            <span>+3</span>
                          </button>
                        </td> */}
                    {!isNotOperator && (
                      <td>
                        <div className="flex justify-center gap-3 items-center">
                          <input
                            type="checkbox"
                            name=""
                            id="checkbox"
                            disabled={batch?.status === "RUNNING"}
                            className={`rounded-[4px] w-5 h-5 ${
                              batch?.status !== "RUNNING" ? "border-textTertiary" : "border-btnTertiary"
                            } text-darkOrangeDark`}
                            // checked={selectedBatches.includes(batch?.id)}
                            // onChange={(e) => handleCheckboxClick(batch?.id)}
                            checked={selectedBatches.includes(batch)}
                            onChange={(e) => handleCheckboxClick(batch)}
                          />

                          <button
                            disabled={batch?.status === "RUNNING"}
                            onClick={() => deleteBatchConfirmRef.current?.setState({ visible: true, batchId: batch?.id })}
                          >
                            <AiOutlineDelete className={`${batch?.status !== "RUNNING" ? "text-textTertiary" : "text-btnTertiary"} h-6 w-6`} />
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          !isLoading && <span className="font-bold">No Notes Found</span>
        )}
      </div>

      {/* pagination */}
      <Pagination
        className="pagination-bar"
        currentPage={page}
        totalCount={batches?.data?.totalPages * batches?.data?.totalPages}
        pageSize={batches?.data?.totalPages}
        onPageChange={(page) => {
          setPage(page), setSelectedBatches([])
        }}
      />

      {/* End lg */}

      <StateContainer initialState={{ visible: false }} ref={deleteBatchConfirmRef}>
        {(state: any, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure ?"
            description="This action cannot be undone."
            onConfirm={async () => {
              await deleteBatch.mutateAsync({ batchId: state.batchId! })
              await batches.refetch()
              setState({ visible: false })
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            loading={deleteBatch.isLoading || batches.isFetching}
          />
        )}
      </StateContainer>
    </>
  )
}

export default BatchListing
