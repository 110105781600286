import React, { useEffect, useState } from "react"
import { svg } from "../../assets/index"
import BatchListing from "./components/batchListing"
import { AiOutlineDelete } from "react-icons/ai"
// import { setDeviceId, trpc, useDeviceId } from "@accubrew/state"
import { RouterOutput, StateContainer, trpc, useStateContainer, useBatches, useDeviceId } from "@accubrew/state"
import { ConfirmModal } from "../../components"
import { toast } from "react-toastify"

function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}
export function Batches() {
  const deviceId = useDeviceId()
  type BatchDeleteType = { visible: boolean; batchIds?: string[] }
  const deleteBatch = trpc.batch.delete.useMutation()
  const deleteMultipleBatch = trpc.batch.deleteMultiple.useMutation()
  const user = trpc.user.get.useQuery()

  const deleteBatchConfirmRef = useStateContainer<BatchDeleteType>()

  const devices = trpc.device.list.useQuery()
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>(deviceId ?? "")
  const [selectedBatches, setSelectedBatches] = useState<string[]>([])
  const [page, setPage] = useState(1)
  const [searchByName, setSearchByName] = useState("")

  const disabled = selectedDeviceId === undefined
  const batches: any = trpc.batch.listAll.useQuery({ page, search: searchByName }, { enabled: !disabled })
  const isLoading = batches.isLoading
  const [allBatches, setAllBatches] = useState(batches?.data?.batches)

  const batchData = batches?.data?.batches?.filter((batch: any) => batch?.status === "STOPPED")

  const handleCheckboxClick = async (batch: any) => {
    const itemIndex = selectedBatches.findIndex((selectedBatch: any) => selectedBatch?.id === batch.id)
    const isSTOPPED = batch?.status === "STOPPED"

    if (itemIndex !== -1) {
      const updatedSelectedBatches = [...selectedBatches]
      updatedSelectedBatches.splice(itemIndex, 1)
      setSelectedBatches(updatedSelectedBatches)
    } else if (isSTOPPED) setSelectedBatches([...selectedBatches, batch])
      else alert("The selected batch is currently running and not stopped. Please ensure that you only select stopped batches for this operation.")
  }

  const handleChooseAllCheckboxClick = (batches: any) => {
    const stoppedBatches: [] = batches?.data?.batches?.filter((batch: any) => batch?.status === "STOPPED")

    if (stoppedBatches.length === 0) {
      toast.info("All batches are running. You can't select running batches.")
    } else if (selectedBatches.length === stoppedBatches.length) {
        setSelectedBatches([])
      } else {
        setSelectedBatches([...stoppedBatches])
      }
  }

  const searchBatch = (e: any) => {
    const searchInput = e.target.value
    setSearchByName(searchInput)

    const filtered = batches?.data?.batches.filter((batch: any) => batch?.name?.toLowerCase().startsWith(searchInput.toLowerCase()))
    setAllBatches(filtered)
  }

  return (
    <Page title="Batches">
   
      <div className="main my-5 px-6">
        <div className="flex flex-col md:flex-row justify-between w-full mb-4 gap-4">
          {/* <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-4 xl:gap-6"> */}
          {/* <select
              className="bg-transparent border-gray-300 text-gray-800  text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block  line-clamp-1"
              value={selectedDeviceId}
              onChange={(e) => setSelectedDeviceId(e.target.value)}
            >
              <option disabled value="">
                Select Device
              </option>
              {devices.data?.devices.map((device: any) => (
                <option value={device.particleId} key={device.particleId}>
                  {device.name} / {device.particleId}
                </option>
              ))}
            </select> */}
          <div className="max-w-md w-full border border-gray-300 rounded-lg">
            <div className="relative flex items-center w-full h-12 rounded-lg overflow-hidden">
              <button className="grid place-items-center h-full w-12 text-gray-300 ">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </button>

              <input
                className=" h-full w-full border-none text-sm text-gray-700 pr-2 outline-none bg-transparent focus:ring-0 focus:ring-offset-0"
                type="text"
                placeholder="Search By Batch Name"
                value={searchByName}
                onChange={searchBatch}
              />
            </div>
          </div>

          {/* <div className="inline-flex flex-wrap md:flex-nowrap justify-between rounded-md border border-gray-300 p-1 w-full lg:w-fit font-[Mulish] md:col-span-full lg:col-auto">
              <button aria-current="page" className=" w-1/4 px-3 lg:px-5 py-1 text-sm font-medium text-white bg-darkOrangeDark rounded-md ">
                3d
              </button>
              <button className=" w-1/4 px-3 lg:px-5 py-1 text-sm font-medium text-textSecondary  hover:bg-darkOrangeDark hover:text-white rounded-md">
                1w
              </button>
              <button className=" w-1/4 px-3 lg:px-5 py-1 text-sm font-medium text-textSecondary  hover:bg-darkOrangeDark hover:text-white rounded-md">
                1m
              </button>
              <button className=" w-1/4 px-3 lg:px-5 py-1 text-sm font-medium text-textSecondary  hover:bg-darkOrangeDark hover:text-white rounded-md">
                24h
              </button>
            </div> */}
          {/* </div> */}

          <div className="flex justify-start md:justify-end w-full lg:w-96 font-[Mulish]">
            <p className="text-textSecondary text-left mt-2 font-[Mulish] hidden md:block lg:hidden mr-auto">
              {/* {batches?.data?.batches?.length() && batches?.data?.batches?.length()} */}
            </p>
            {!["MANAGER","PRODUCTION", "COLLABORATOR"].includes(user.data!.role)&&(

            <div className="flex gap-3 justify-between md:justify-start">
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox"
                  className="rounded-[4px] w-5 h-5 border-[#B6B4AF] text-darkOrangeDark"
                  checked={selectedBatches?.length > 0 && selectedBatches?.length === batchData?.length}
                  // onChange={() => handleChooseAllCheckboxClick(batches.data?.batches?.map((item: any) => item?.status !== "RUNNING" && item?.id))}
                  onChange={() => handleChooseAllCheckboxClick(batches)}
                />
                <label htmlFor="checkbox" className="whitespace-nowrap">
                  Choose all
                </label>
              </div>
            
              <button
                className={`flex justify-center items-center gap-3 ${selectedBatches.length > 0 ? "text-red-500" : "text-[#B6B4AF]"}`}
                disabled={selectedBatches.length == 0}
                onClick={() => deleteBatchConfirmRef.current?.setState({ visible: true, batchIds: selectedBatches })}
              >
                <AiOutlineDelete className="h-6 w-6" />
                <span className="whitespace-nowrap">{`Delete Selected (${selectedBatches?.length})`}</span> 
              </button>

            </div>
  )}
          </div>
          
        </div>
        <p className="text-textSecondary text-left mt-2 font-[Mulish] md:hidden lg:block">
          {/* {batches?.data?.batches?.length() && batches?.data?.batches?.length() + "Batches"} */}
        </p>

        <div className="w-full gap-3 mt-6">
          <BatchListing
            page={page}
            setPage={setPage}
            // batches={allBatches}
            batches={batches}
            isLoading={isLoading}
            selectedDeviceId={selectedDeviceId}
            disabled={disabled}
            handleCheckboxClick={handleCheckboxClick}
            selectedBatches={selectedBatches}
            setSelectedBatches={setSelectedBatches}
          />
        </div>
      </div>
      <StateContainer initialState={{ visible: false }} ref={deleteBatchConfirmRef}>
        {(state: any, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure ?"
            description="This action cannot be undone."
            onConfirm={async () => {
              let batchIdsToDelete: string[] = state?.batchIds?.map((batch: any) => batch?.id)

              await deleteMultipleBatch.mutateAsync({ batchIds: batchIdsToDelete })
              await batches.refetch()
              setSelectedBatches([])
              setState({ visible: false })
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            loading={deleteMultipleBatch.isLoading || batches.isFetching}
          />
        )}
      </StateContainer>
    </Page>
  )
}
