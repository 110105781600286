// src/index.ts
import { z } from "zod";
var roles = ["ADMIN", "OWNER", "MANAGER", "PRODUCTION", "COLLABORATOR"];
var noteTypes = ["Reminder", "Batch"];
var units = ["Plato", "SG"];
var loginCreds = z.object({
  email: z.string().email(),
  password: z.string().min(8)
});
var editAdmin = z.object({
  password: z.string().min(8),
  email: z.string().email()
});
var createCustomer = z.object({
  email: z.string().email(),
  firstName: z.string().min(3),
  password: z.string().min(8),
  lastName: z.string().optional(),
  phoneNumber: z.string().optional(),
  emailVerified: z.boolean().optional(),
  active: z.boolean()
});
var subAccount = z.object({
  firstName: z.string().min(3, "First Name is required"),
  lastName: z.string().optional(),
  password: z.string().min(8, "Password must be at least 8 characters").regex(/[A-Z]/, "Password must include at least 1 uppercase letter").regex(/[0-9]/, "Password must include at least 1 number").min(3, "Password is required"),
  email: z.string().email("Invalid email format"),
  phoneNumber: z.string().min(11, "Phone number is required"),
  role: z.enum(roles),
  batches: z.array(z.string()).optional()
});
var updateUser = z.object({
  firstName: z.string().min(3),
  lastName: z.string().optional(),
  phoneNumber: z.string().optional()
});
var request = z.object({
  name: z.string().min(2),
  phone: z.string().min(3),
  message: z.string().min(3)
});
var customer = z.object({
  email: z.string().email(),
  firstName: z.string().min(3),
  password: z.string().min(8),
  lastName: z.string().optional(),
  phoneNumber: z.string().optional(),
  emailVerified: z.boolean().optional(),
  active: z.boolean()
});
var editCustomer = customer.omit({ password: true }).and(
  z.object({
    password: z.string().refine((pass) => {
      if (!pass)
        return true;
      return pass.length >= 8;
    })
  })
);
var startRun = z.object({
  name: z.string(),
  expectedAlcohol: z.coerce.number(),
  tankSize: z.coerce.number(),
  originalGravity: z.coerce.number(),
  finalGravity: z.coerce.number(),
  originalGravityUnit: z.enum(units),
  finalGravityUnit: z.enum(units),
  expectedFermentationTime: z.coerce.number().int().positive()
});
var note = z.object({
  id: z.string(),
  description: z.string().min(3),
  type: z.enum(noteTypes),
  reminderDate: z.date().optional(),
  batchId: z.string().min(3)
});
var ROLES_PERMISSIONS = {
  MANAGER: ["PRODUCTION", "COLLABORATOR"],
  OWNER: ["MANAGER", "PRODUCTION", "COLLABORATOR"],
  PRODUCTION: ["COLLABORATOR"],
  ADMIN: ["ADMIN", "OWNER", "MANAGER", "PRODUCTION", "COLLABORATOR"],
  COLLABORATOR: []
};
var batchThresholdInput = z.union([
  z.object({ sgMin: z.number(), type: z.literal("sg"), batchId: z.string() }),
  z.object({ tmpMin: z.number(), tmpMax: z.number(), type: z.literal("temp"), batchId: z.string() })
]);
var sgThresholdSchema = z.object({
  sgMin: z.coerce.number().min(1e-3)
});
var tempThresholdsSchema = z.object({ tmpMin: z.coerce.number(), tmpMax: z.coerce.number() }).refine((data) => {
  if (!data.tmpMin && !data.tmpMax)
    return false;
  return true;
});
export {
  ROLES_PERMISSIONS,
  batchThresholdInput,
  createCustomer,
  customer,
  editAdmin,
  editCustomer,
  loginCreds,
  note,
  request,
  roles,
  sgThresholdSchema,
  startRun,
  subAccount,
  tempThresholdsSchema,
  updateUser
};
