import * as React from "react"
import { Button, AuthLayout, AuthCard } from "../../../components"
import { images, svg } from "../../../assets"

type Props = { children?: React.ReactNode; title?: string; className?: string; subscriptionType?: string; price?: number; featureList?: any }
export default function PricingCard({ children, title, className, subscriptionType, price, featureList }: Props) {
  return (
    <>
      <div className=" bg-white border border-gray-200 rounded-lg shadow p-8 ">
        <h5 className="mb-4 text-2xl  font-extrabold">{title}</h5>
        <div className="flex items-baseline text-gray-900 ">
          <span className="text-3xl font-extrabold ">${price}</span>
          <sub className="font-normal text-[16px]">/{subscriptionType}</sub>
        </div>
        {/* {subscriptionType && (
          <div className="relative inline-flex items-center mt-2">
            <span className="mr-2 text-sm font-medium text-gray-900 dark:text-black-300 relative inline-flex items-center">Monthly</span>

            <label className="relative inline-flex items-center mr-5 cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer"></input>
              <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:#F88D2A peer-focus:ring-4 peer-focus:#F88D2A  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:#F88D2A peer-checked:bg-orange-600"></div>
              <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Yearly</span>
            </label>
          </div>
        )} */}
        {subscriptionType == "Month" && <span className="font-normal mt-2 text-[16px] text-[#F88D2A]">Each Additional Sensor is $9.95/Month</span>}
        <hr className="h-px my-4 bg-gray-200 border-0 text-gray-900"></hr>
        <ul role="list" key="list" className="space-y-2 my-4">
          {featureList.map((item: any) => {
            return item.enable ? (
              <li key={item.id} className="flex space-x-3 items-center py-0">
                <svg.EnableCheck />
                <span className="text-base font-normal leading-tight text-gray-500 ">{item.title}</span>
              </li>
            ) : (
              <li key={item.id} className="flex space-x-3 line-through decoration-gray-500">
                <svg.DisableCheck />
                <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">{item.title}</span>
              </li>
            )
          })}
        </ul>
        {children}
      </div>
    </>
  )
}
