import { useEffect, useRef } from "react"
import { MdSend } from "react-icons/md"
import { StateContainer, trpc, useStateContainer } from "@accubrew/state"

export function MessagesBoard({ batchId }: { batchId: string }) {
  const messageContainer = useStateContainer<string>()
  const user = trpc.user.get.useQuery()
  const chatEndRef = useRef<HTMLDivElement>(null)

  const sendMessage = trpc.messageBoard.add.useMutation()
  const messages = trpc.messageBoard.list.useQuery({ batchId })
  // console.log("messages",messages);
  useEffect(() => {
    const msgs = messages.data?.messages
    if (Array.isArray(msgs) && msgs.length > 0 && chatEndRef.current) {
      chatEndRef.current.scrollTop = chatEndRef.current.scrollHeight
    }
  }, [messages.data])

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    const message = messageContainer.current!.getState()
    e.preventDefault()
    sendMessage.mutate({ batchId, content: message })
    messageContainer.current!.setState("")
  }

  return (
    <div className="bg-white rounded-md pt-7 pb-3 px-5 shadow max-h-[450px] h-full flex flex-col">
      <h4 className="font-[800] text-[24px]">Message Board</h4>
      <div className="flex-1 overflow-y-auto pt-2" ref={chatEndRef}>
        {messages.isLoading && <div className="font-semibold text-center mt-5 text-border">Loading...</div>}
        {messages.data?.messages.length === 0 && <div className="font-semibold text-center mt-5 text-border">No Messages ..</div>}

        {Array.from(messages.data?.messages ?? [])
          .reverse()
          .map((message: any) => {
            const isMe = message.userEmail === user.data?.email
            return (
              <div className={`flex items-center max-w-[80%] mb-5 px-5 [overflow-anchor:none] ${isMe ? "" : "flex-row-reverse ml-auto"}`} key={message.id}>
                <div className="flex-shrink-0 w-9 h-9 border flex justify-center items-center rounded-full text-xs bg-gray-500/70 text-white font-bold self-start">
                  {getInitials(message.user)}
                </div>
                <p className={`p-2 text-sm bg-blue/10 rounded-xl ${isMe ? "ml-2" : "mr-2"}`}>{message.content}</p>
              </div>
            )
          })}
      </div>
      <form className="flex" onSubmit={handleSubmit}>
        <StateContainer initialState="" ref={messageContainer}>
          {(message, setMessage) => {
            const inValid = message.trim().length === 0
            return (
              <>
                <input className="border px-3 py-2 flex-1 rounded-md" value={message} onChange={(e) => setMessage(e.target.value)} />
                <button className={`px-3 ${inValid || sendMessage.isLoading ? "opacity-50" : ""}`} disabled={inValid || sendMessage.isLoading}>
                  <MdSend className={`text-orangeDark ${inValid ? "opacity-40 cursor-not-allowed" : ""}`} />
                </button>
              </>
            )
          }}
        </StateContainer>
      </form>
    </div>
  )
}

function getInitials(user: { firstName: string; lastName: string | null }) {
  return `${user.firstName.charAt(0)}${user.lastName?.charAt(0) ?? user.firstName.charAt(2)}`
}
