import { ReactLocation, Router, Outlet, useLocation, Navigate } from "@tanstack/react-location"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { ReactLocationDevtools } from "@tanstack/react-location-devtools"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { trpc, EventSubscription, useToken, Store, queryClient, setBatchId, setDeviceId } from "@accubrew/state"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import * as auth from "./pages/auth"
import * as dashboard from "./pages/dashboard"
import { BrewBook } from "./pages/brewbook"
// import { Batches } from "./pages/batches"
import * as Batches from "./pages/batches"

import { BatchHistory } from "./pages/batches"
import { Calendar } from "./pages/calendar"
import { MessageBoard } from "./pages/message-board"
import * as settings from "./pages/settings"
import * as about from "./pages/about"
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from "@react-oauth/google"

import { DashboardLayout } from "./components"

import "./index.css"
import { svg } from "./assets"

const reactLocation = new ReactLocation()
const temp = localStorage.getItem("___temp___") as "f" | "c" | undefined

Store.init({
  token: localStorage.getItem("___token___") ?? undefined,
  temp,
})

Store.onChange((state, pState) => {
  if (state.token !== pState.token) {
    if (!state.token) {
      localStorage.removeItem("___token___")
      setTimeout(() => queryClient.clear(), 150)
    } else {
      localStorage.setItem("___token___", state.token)
    }
  }

  if (state.temp !== pState.temp) {
    if (!state.temp) {
      localStorage.removeItem("___temp___")
    } else {
      localStorage.setItem("___temp___", state.temp)
    }
  }
})

type Device = {
  particleId: string;
  running: boolean;
  signaling: boolean;
  charging: boolean;
  busy: boolean;
  power: number | null;
  wifi: number | null;
  createdAt: Date;
  updatedAt: Date;
  name: string | null;
  userEmail: string | null;
}

export function App() {
  const context = trpc.useContext()

  const stripePromise = loadStripe(`${import.meta.env.VITE_STRIPE_KEY}`) //test
  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <Router
        routes={[
          { path: "/", element: <Navigate to="/dashboard/home" /> },
          { path: "/auth/login", element: <auth.Login /> },
          { path: "/auth/signup", element: <auth.Register /> },
          { path: "/auth/forget-password", element: <auth.ForgotPassword /> },
          { path: "/auth/reset-password", element: <auth.ResetPassword /> },

          // require auth.
          { path: "/dashboard/complete-profile", element: <auth.CompleteProfile /> },
          {
            path: "/dashboard",
            element: <DashboardLayout />,
            children: [
              { path: "/", element: <Navigate to="/dashboard/home" replace /> },
              { path: "/brewbook", element: <BrewBook /> },
              {
                path: "/batches",
                // element: <Batches.Batches />,
                children: [
                  { path: "/", element: <Batches.Batches /> },
                  { path: "/batch-history", element: <Batches.BatchHistory /> },
                ],
              },

              { path: "/calendar", element: <Calendar /> },
              { path: "/message-board", element: <MessageBoard /> },

              {
                path: "/accubrew",
                element: <about.Layout />,
                children: [
                  { path: "/", element: <Navigate to="/dashboard/accubrew/about" replace /> },
                  { path: "/about", element: <about.About /> },
                  {
                    path: "/pricing",
                    element: (
                      <Elements stripe={stripePromise}>
                        <about.Pricing />
                      </Elements>
                    ),
                  },
                  { path: "/probe-troubleshooting", element: <about.Troubleshoot /> },
                ],
              },

              {
                path: "/home",
                element: <dashboard.Home />,
                children: [
                  {
                    path: "probe-info/:id",
                    element: <></>,
                    onMatch(match) {
                      const { id } = match.params
                      const devices = context.device.list.getData()
                      if (devices) {
                        const device = devices?.devices.find((device: Device) => device.particleId === id)
                        if (device) setDeviceId(id)
                      } else {
                        context.device.list.prefetch().then(() => {
                          const devices = context.device.list.getData()
                          const device = devices?.devices.find((device: Device) => device.particleId === id)
                          if (device) setBatchId(id)
                        })
                      }
                    },
                  },
                ],
              },
              { path: "/settings/profile",  element: (
                <Elements stripe={stripePromise}>
                  <settings.Profile />
                </Elements>
              ), },
              { path: "/settings/accounts", element: <settings.AccountManagment /> },
              { path: "/settings/devices", element: <settings.Devices /> },
              // { path: "/auth/forget-password", element: <auth.ForgotPassword /> },
              // { path: "/auth/reset-password", element: <auth.ResetPassword /> },
              //     {
              //       path: "/settings",
              //       element: <></>,
              //       children: [
              //         { path: "/", element: <Navigate to="/dashboard/settings/profile" replace /> },
              //         { path: "/profile", element: <settings.Profile /> },
              //         { path: "/accounts", element: <settings.AccountManagment /> },
              //         { path: "/devices", element: <settings.Devices /> },
              //       ],
              //     },
            ],
          },
        ]}
        location={reactLocation}
      >
        <MainRouter />
        {import.meta.env.DEV && (
          <>
            <ReactLocationDevtools position="bottom-right" />
            <ReactQueryDevtools position="bottom-left" />
          </>
        )}
      </Router>
      <ToastContainer />
    </GoogleOAuthProvider>
  )
}

function MainRouter() {
  const location = useLocation()
  const token = useToken()
  const enabled = !!token
  const user = trpc.user.get.useQuery(undefined, { enabled, refetchOnWindowFocus: false, retry: 0 })

  const authenticated = enabled && user.data?.email

  if (enabled && user.isLoading)
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <svg.Loading />
      </div>
    )

  if (!authenticated && location.current.pathname.includes("dashboard")) return <Navigate to="/auth/login" />
  else if (token && user.data?.email && !user.data.firstName && location.current.pathname !== "/dashboard/complete-profile") {
    return <Navigate to="/dashboard/complete-profile" />
  } else if (authenticated && location.current.pathname.includes("auth")) return <Navigate to="/dashboard/home" />

  return (
    <>
      <Outlet />
      {token && user.data?.email ? <EventSubscription /> : null}
    </>
  )
}
