import { Link } from "@tanstack/react-location"
import { FAQ } from "./components/constant"
import news from "./images/news.svg"
import pricing from "./images/pricing.svg"
import probe from "./images/probe.svg"
import Accordion from "./components/accordian"
import { trpc } from "@accubrew/state"
import { useEffect } from "react"

function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}
export function About() {
  const user = trpc.user.get.useQuery()

  const allowedRoles = ["OWNER", "MANAGER", "PRODUCTION"]
  const isAllowed = allowedRoles.includes(user.data!.role)
  return (
    <Page title="About">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-3 mx-4">
        {/* <div className="bg-cover relative">
          <Link to="/dashboard/accubrew/about" className=" cursor-pointer">
            <img src={news} />
            <div className="absolute bottom-0 px-4 py-3">
              <h1 className="text-white font-semibold text-2xl pb-4">News</h1>
              <p className="text-gray-200 pb-2">Get the latest info on AccuBrew</p>
            </div>
          </Link>
        </div> */}
        {isAllowed && (
          <div className="bg-cover relative">
            <Link to="/dashboard/accubrew/pricing" className=" cursor-pointer">
              <img src={pricing} />
              <div className="absolute bottom-0 px-4 py-3">
                <h1 className="text-white font-semibold text-2xl pb-4"> Pricing</h1>
                <p className="text-gray-200 pb-2">Click for current device and data plan prices</p>
              </div>
            </Link>
          </div>
        )}

        <div className="bg-cover relative">
          <Link to="/dashboard/accubrew/probe-troubleshooting" className=" cursor-pointer">
            <img src={probe} />
            <div className="absolute bottom-0 px-4 py-3">
              <h3 className="text-white font-semibold text-2xl pb-4">Probe Troubleshooting</h3>
              <p className="text-gray-200 pb-2">Learn what the lights mean on your Probe</p>
            </div>
          </Link>
        </div>
      </div>
      {/* <h1 className="text-1xl font-bold my-5 ml-3">FAQ</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5 mt-3 mx-4">
        <div className=" flex justify-center p-8 md:p-0 lg:p-0">
          <div className="w-96">
            <h1 className="text-1xl font-bold mb-3">General</h1>
            <Accordion items={FAQ} />
          </div>
        </div>
        <div className=" flex justify-center p-8 md:p-0 lg:p-0">
          <div className="w-96">
            <h1 className="text-1xl font-bold mb-3">Software</h1>
            <Accordion items={FAQ} />
          </div>
        </div>
        <div className=" flex justify-center p-8 md:p-0 lg:p-0">
          <div className="w-96">
            <h1 className="text-1xl font-bold mb-3">Probe</h1>
            <Accordion items={FAQ} />
          </div>
        </div>
      </div>{" "} */}
    </Page>
  )
}
