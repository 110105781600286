import { RouterOutput, StateContainer, trpc, useStateContainer } from "@accubrew/state"
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai"
import { IoCreateOutline } from "react-icons/io5"
import { svg } from "../../assets"
import { Button, ConfirmModal, Modal } from "../../components"
import { SubAccountForm, SubAccountFormState } from "./components"
import { Layout } from "./Layout"
import { useEffect, useState } from "react"
import { FiEdit, FiPlus } from "react-icons/fi"
import Pagination from "../../components/Pagination"

function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}

type SubAccount = RouterOutput["subUser"]["list"]["accounts"][number]
type SubAccountDeleteState = { visible: boolean; email?: string; name?: string }

export function AccountManagment() {
  const [page, setPage] = useState(1)
  const [searchByName, setSearchByName] = useState("")
  const subAccounts: any = trpc.subUser.list.useQuery({ page, search: searchByName })
  const deleteSubAccount = trpc.subUser.delete.useMutation()
  const subAccForm = useStateContainer<SubAccountFormState>()
  const deleteSubAccountConfirmRef = useStateContainer<SubAccountDeleteState>()

  function hideSubAccountForm() {
    subAccForm.current?.setState((x) => ({ ...x, visible: false }))
  }

  function showSubAccountForm() {
    subAccForm.current?.setState({ visible: true, account: undefined })
  }

  const showEditSubAccount = (account: SubAccount) => () => {
    subAccForm.current?.setState({ visible: true, account })
  }

  const searchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchInput = e.target.value
    setSearchByName(searchInput)
  }
  return (
    <Page title="Account Management">
      <Layout>
        <div className="max-w-7xl mx-auto">
          <div className="mx-4 flex flex-col lg:flex-row justify-between items-center">
            <div className="flex justify-between w-full">
              <h2 className="font-bold text-lg lg:hidden">Account Managment</h2>
              <div className="flex gap-5">
                <button
                  className="hidden lg:flex justify-center items-center px-4 py-2 bg-orangeDark text-white rounded-md gap-1 font-semibold"
                  onClick={showSubAccountForm}
                >
                  <FiPlus size={20} />
                  <span>Create account</span>
                </button>
                <button className="hidden lg:flex justify-center items-center px-4 py-2 bg-orangeLight text-orangeDark rounded-md gap-1 font-semibold">
                  <FiPlus size={20} />
                  <span>To Invite</span>
                </button>
              </div>
              <div>
                <button
                  className="p-2 w-[35px] h-[35px] bg-[#F88D2A] text-white flex lg:hidden  justify-center items-center rounded"
                  onClick={showSubAccountForm}
                >
                  <AiOutlinePlus size={20} />
                </button>
              </div>
            </div>
            <div className="max-w-md w-full border border-gray-300 rounded-lg mt-3 lg:mt-0">
              <div className="relative flex items-center w-full h-12 rounded-lg overflow-hidden">
                <button className="grid place-items-center h-full w-12 text-gray-300 ">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </button>

                <input
                  className=" h-full w-full border-none text-sm text-gray-700 pr-2 outline-none bg-transparent focus:ring-0 focus:ring-offset-0"
                  type="text"
                  placeholder="Search By Name"
                  value={searchByName}
                  onChange={searchUser}
                />
              </div>
            </div>
          </div>
          <div className="min-h-[400px] m-4 rounded">
            <div className="grid md:grid-cols-2 lg:block mb-3">
              <div className="hidden lg:flex p-2">
                <div className="flex-[2] shrink-0 grow-1 overflow-hidden text-textSecondary font-bold">Name</div>
                <div className="flex-[2] shrink-0 grow-1 overflow-hidden  text-textSecondary font-bold">Email</div>
                <div className="flex-[2] shrink-0 grow-1 overflow-hidden  text-textSecondary font-bold">Phone</div>
                <div className="flex-1 shrink-0 grow-1 overflow-hidden  text-textSecondary font-bold">Role</div>
                <div className="flex-1 shrink-0 grow-1 overflow-hidden  text-textSecondary font-bold">Actions</div>
              </div>

              {subAccounts.isLoading && <svg.Loading className="mx-auto mt-8" />}
              {subAccounts.data?.accounts?.length === 0 && <p className="text-lg text-textSecondary font-semibold text-center">No subUser found.</p>}
              {subAccounts.data?.accounts.map((acc: any) => {
                return (
                  <div className="bg-white  mb-2 ml-2 p-2 shadow rounded lg:flex my-2 lg:mb-0 lg:ml-0 lg:items-center" key={acc.email}>
                    <div className="flex-[2] shrink-0 grow-1 overflow-hidden font-[700] mb-1">
                      {acc.firstName} {acc.lastName}
                    </div>
                    <div className="flex-[2] shrink-0 grow-1 overflow-hidden mb-1">{acc.email}</div>
                    <div className="flex-[2] shrink-0 grow-1 overflow-hidden mb-1">{acc.phoneNumber ?? ""}</div>
                    <div className="flex-1 shrink-0 grow-1 overflow-hidden capitalize border lg:border-none px-2 py-1 inline-block rounded mb-1">
                      {acc.role.toLowerCase()}
                    </div>
                    <div className="flex-1 shrink-0 grow-1 overflow-hidden">
                      <div className="flex">
                        <button className="px-2 py-1 mr-1 hover:text-blue-600 text-[#427CBF]" onClick={showEditSubAccount(acc)}>
                          <FiEdit size={19} />
                        </button>
                        <button
                          className="px-2 py-1 hover:text-red-600 text-slate-500"
                          onClick={() => {
                            deleteSubAccountConfirmRef.current?.setState({ visible: true, email: acc.email, name: acc.firstName + acc.lastName })
                          }}
                        >
                          <AiOutlineDelete size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={subAccounts?.data?.totalPages * subAccounts?.data?.totalPages}
              pageSize={subAccounts?.data?.totalPages}
              onPageChange={(page) => setPage(page)}
            />
          </div>

          <StateContainer<SubAccountFormState> initialState={{ visible: false }} ref={subAccForm}>
            {(state) => (
              <Modal visible={state.visible} onClose={hideSubAccountForm} portal>
                <SubAccountForm account={state.account} hide={hideSubAccountForm} />
              </Modal>
            )}
          </StateContainer>

          <StateContainer<SubAccountDeleteState> initialState={{ visible: false }} ref={deleteSubAccountConfirmRef}>
            {(state, setState) => (
              <Modal visible={state.visible} onClose={() => setState({ visible: false })}>
                <h2 className="px-4 pt-4 pb-2 text-lg font-bold">
                  Are you sure you want to delete <span className="text-[#F88D2A]">{state.name} ?</span>
                </h2>
                <p className="px-4 mb-4 font-medium">By confirming the deletion you will not be able to recover the lost data.</p>

                <div className="flex justify-center gap-x-3 mb-4">
                  <Button onClick={() => setState({ visible: false })} variant="plain" className="px-4 py-2">
                    Cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      await deleteSubAccount.mutateAsync({ email: state.email! })
                      await subAccounts.refetch()
                      setState({ visible: false })
                    }}
                    variant="secondary"
                    className="relative px-4 py-2 bg-red-500 text-white"
                    disabled={deleteSubAccount.isLoading}
                    isLoading={deleteSubAccount.isLoading}
                  >
                    Confirm
                  </Button>
                </div>
              </Modal>
            )}
          </StateContainer>
        </div>
      </Layout>
    </Page>
  )
}

// function ViewPort({ children }: { children: [{ minWidth: number; maxWidth: number; element: React.ReactNode }] }) {
//   const [width, setWidth] = useState(window.innerWidth)
//   useEffect(() => {
//     function handler(event: UIEvent) {
//       setWidth(window.innerWidth)
//     }

//     addEventListener("resize", handler)
//     return () => {
//       removeEventListener("resize", handler)
//     }
//   }, [])
//   return (
//     <div className="bg-slate-300 h-40">
//       {children.map((child) => {
//         if (width >= child.minWidth && width <= child.maxWidth) return child.element
//       })}
//     </div>
//   )
// }
