const textPrimary = "#342F20"
const textDisabled = "#D6D6D6"
const orange = "#F3BD48"
const errorDefault = "#FF3030"
const successful = "#43D82A"

export function Power({ power, online, charging }: React.ComponentProps<"svg"> & { power: number; online: boolean; charging?: boolean }) {
  return (
    <svg width={28} height={14}>
      <path
        opacity={0.35}
        d="M2.667.75h19.987c1.058 0 1.917.858 1.917 1.917v8.666a1.917 1.917 0 0 1-1.917 1.917H2.667A1.917 1.917 0 0 1 .75 11.333V2.667C.75 1.608 1.608.75 2.667.75Z"
        stroke={textPrimary}
        strokeWidth={1.5}
      />
      <path opacity={0.4} d="M26.471 4.53v4.94C27.398 9.052 28 8.078 28 7c0-1.079-.602-2.052-1.529-2.47" fill={textPrimary} />
      <rect x={2.5} y={2.5} width={(power * 20) / 100} height={9} strokeWidth={0} fill={power > 50 ? successful : power > 20 ? orange : errorDefault} />
      {online && charging ? (
        <path
          d="m9.833 1.667-8.333 10H9l-.833 6.666 8.333-10H9l.833-6.667Z"
          fill={errorDefault}
          strokeOpacity={1}
          strokeWidth={1}
          strokeLinecap="round"
          strokeLinejoin="round"
          scale={0.5}
          x={8}
          y={2}
          className="animate-pulse scale-50 relative top-75 translate-x-2 translate-y-[2px]"
        />
      ) : null}
    </svg>
  )
}
