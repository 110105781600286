import React, { useState } from "react"
import { format } from "date-fns"
import { trpc, useDeviceId } from "@accubrew/state"
import { Button } from "./Button"
import { toast } from "react-toastify"

type CancelSubProps = {
  setDeleteSubModal?: any
  onChangeDevice?: any
  selectedDeviceID?: any

  subscriptionId?: any
}
const CancelSubModal: React.FC<CancelSubProps> = ({ setDeleteSubModal, selectedDeviceID, onChangeDevice, subscriptionId }) => {
  const [selectedOption, setSelectedOption] = useState("Pausing, I will be back soon!")
  const probeId = useDeviceId()

  const cancelSubscritpion = trpc.payment.cancelSubscritpion.useMutation({
    onSuccess(data) {},
  })
  const isLoading = cancelSubscritpion.isLoading
  const deviceInfo = trpc.device.info.useQuery(probeId!, { enabled: !!probeId })

  const handleCancelSubscriptionPress = () => {
    cancelSubscritpion.mutate(
      {
        id: subscriptionId ?? "",
        cancelReason: selectedOption,
      },
      {
        onSuccess(data) {
          setDeleteSubModal(false)
          // console.log("onSuccess handleCancelSubscriptionPress({})", JSON.stringify(data, null, 4))
          onChangeDevice(selectedDeviceID)
        },
        onError(error) {
          toast.error(error.message)
        },
      },
    )
  }
  return (
    <div className="w-full min-h-[300px] p-4">
      <h2 className="px-4 pt-4 pb-2 text-lg font-bold">Cancel Subscription?</h2>
      <p className="px-4 mb-4 font-medium">
        Device ID <span className="text-[#F88D2A]">{selectedDeviceID}</span>
      </p>
      <p className="px-4 mb-4 font-large text-textSecondary">Select a reason for canceling your subscription:</p>
      <div className="flex items-center ml-4 mt-2">
        <input
          id="red-radio"
          type="radio"
          value="Pausing, I will be back soon!"
          name="colored-radio"
          className="w-4 h-4 text-errorDefault"
          checked={selectedOption === "Pausing, I will be back soon!"}
          onChange={(e) => setSelectedOption(e.target.value)}
        />
        <label className="ml-2  font-large ">Pausing, I will be back soon!</label>
      </div>
      <div className="flex items-center ml-4 mt-2">
        <input
          id="red-radio"
          type="radio"
          value="No longer wish to continue the service"
          name="colored-radio"
          className="w-4 h-4 text-errorDefault"
          checked={selectedOption === "No longer wish to continue the service"}
          onChange={(e) => setSelectedOption(e.target.value)}
        />
        <label className="ml-2  font-large ">No longer wish to continue the service</label>
      </div>

      <div className="flex justify-center gap-x-3 mb-4 mt-8">
        <Button onClick={() => setDeleteSubModal(false)} variant="plain" className="px-4 py-2">
          Cancel
        </Button>
        <Button
          onClick={handleCancelSubscriptionPress}
          variant="primary"
          className="relative px-4 py-2 bg-errorDefault"
          disabled={isLoading}
          isLoading={isLoading}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}
export default CancelSubModal
