import { RouterOutput } from "@accubrew/state"
import { twMerge } from "tailwind-merge"

type Props = {
  loading?: boolean
  disabled?: boolean
  batches: RouterOutput["batch"]["list"]["batches"]
  selectedBatchId?: string | null | undefined
  onBatchIdChange: (batchId: string) => void
  className?: string
  hideWhenDisabled?: boolean
  highlightRunningBatch: boolean
}

function getMessage({ loading, length, disabled }: { loading?: boolean; length?: number; disabled?: boolean }) {
  if (disabled) return "Select Device"
  if (loading) return "Loading..."
  if (length === 0) return "No Batches Found"

  return "Select Batch"
}

export function BatchDropdown({ disabled, loading, onBatchIdChange, selectedBatchId, className, batches, hideWhenDisabled, highlightRunningBatch }: Props) {
  if (hideWhenDisabled && disabled) return null
  interface Batch  {
    createdAt: Date
    createdBy?: string | null
    expectedAlcohol: number
    expectedFermentationTime: number
    finalGravity: number
    finalGravityUnit: "SG" | "Plato"
    id: string
    name: string
    originalGravity: number
    originalGravityUnit: "SG" | "Plato"
    probeParticleId: string
    status: "RUNNING" | "STOPPED" | "DELETED"
    stoppedBy?: string | null
    tankSize: number
    thresholdSgMin: number | null
    thresholdTempMax?: number | null
    thresholdTempMin?:number | null
    updatedAt: Date
  }
  console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
  console.log(batches)
 

  return (
    <div className={twMerge("relative border border-gray-300 text-gray-800 bg-white shadow-lg rounded-md", className)}>
      {batches && (
        <select
          disabled={disabled || (batches ?? []).length === 0}
          className="appearance-none w-full py-1 px-2 bg-white border-none pr-9 line-clamp-1 rounded-md"
          value={selectedBatchId ?? "none"}
          id="device-dropdown"
          onChange={(e) => onBatchIdChange(e.target.value)}
        >
          <option disabled value="none">
            {getMessage({ loading, length: batches.length, disabled })}
          </option>
          {batches.map((batch: Batch) => (
            <option
              value={batch.id}
              key={batch.id}
              className={`${batch.status === "RUNNING" && highlightRunningBatch ? "bg-success text-white" : "bg-inherit"}`}
            >
              {batch.name}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}
