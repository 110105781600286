import { format } from "date-fns"
import { trpc, useDeviceId } from "@accubrew/state"
import { AiOutlineClose } from "react-icons/ai"
import { Link } from "@tanstack/react-location"

import { useSelectedDevcieState } from "../../hooks"
import { svg } from "../../assets"

export function DeviceInfoModal() {
  const probeId = useDeviceId()
  const device = useSelectedDevcieState()
  const deviceInfo = trpc.device.info.useQuery(probeId!, { enabled: !!probeId })
  const { online, busy, charging, wifi, power } = device.data ?? {}

  if (!probeId || device.isError || deviceInfo.isError) {
    return (
      <div className="w-full min-h-[300px] p-4">
        <h2 className="text-2xl font-[800] mb-5">Device Info</h2>
        <p className="text-md text-center font-[800]">Something Went Wrong, try again later.</p>
      </div>
    )
  }

  if (device.isLoading || deviceInfo.isLoading) {
    return (
      <div className="w-full min-h-[300px] p-4">
        <h2 className="text-2xl font-[800] mb-5">Device Info</h2>
        <svg.Loading className="mx-auto w-8 h-8 block self-center" />
      </div>
    )
  }

  return (
    <div className="w-full min-h-[300px] p-4">
      <div className="flex justify-between">
        <h2 className="text-2xl font-[800] mb-5">Device Info</h2>
        <Link to="/dashboard/home">
          {" "}
          <AiOutlineClose />
        </Link>
      </div>
      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Device Name:</span>
        <span>{deviceInfo.data?.name}</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Device ID:</span>
        <span>{deviceInfo.data?.id}</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Battery:</span>
        <span>{online ? Math.min(power ?? 0, 100) : 0}%</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Is charging:</span>
        <span>{online ? (device.data?.charging ? "Yes" : "No") : "No"}</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Is running:</span>
        <span>{online ? (device.data?.running ? "Yes" : "No") : "No"}</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Is signaling:</span>
        <span>{online ? (device.data?.signaling ? "Yes" : "No") : "No"}</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Wifi:</span>
        <span>{device.data?.wifi ?? 0}%</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Readings for current run:</span>
        <span>N/A</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Connected:</span>
        <span>{deviceInfo.data?.connected ? "Yes" : "No"}</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Status:</span>
        <span>{deviceInfo.data?.status}</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Last Heard:</span>
        <span>{deviceInfo.data?.last_heard ? format(Date.parse(deviceInfo.data.last_heard), "MM/dd/yyyy hh:mm a") : "N/A"}</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Last ip Address:</span>
        <span>{deviceInfo.data?.last_ip_address}</span>
      </div>
      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">Serial number:</span>
        <span>N/A</span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-md font-[500] text-[#858279]">System Firmware:</span>
        <span>{deviceInfo.data?.system_firmware_version ?? "N/A"}</span>
      </div>
    </div>
  )
}
