import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { trpc, DEFAULT_DEVICE_STATE, useDeviceId } from "@accubrew/state"
import { Button } from "../../../components"
import { svg } from "../../../assets"
import { noop } from "../../../utils"
import { toast } from "react-toastify"

const units = ["Plato", "SG"] as const

export const startRunInput = z.object({
  name: z.string(),
  expectedAlcohol: z.coerce.number(),
  tankSize: z.coerce.number().positive(),
  originalGravity: z.coerce.number(),
  finalGravity: z.coerce.number(),
  originalGravityUnit: z.enum(units),
  finalGravityUnit: z.enum(units),
  expectedFermentationTime: z.coerce.number().int().positive(),
})

export function StartRunForm({ hide }: { hide: () => void }) {
  const context = trpc.useContext()
  const startRun = trpc.device.startRun.useMutation()
  const deviceId = useDeviceId()!
  const { handleSubmit, formState, register } = useForm<z.infer<typeof startRunInput>>({
    resolver: zodResolver(startRunInput),
    mode: "onChange",
    defaultValues: { originalGravityUnit: "SG", finalGravityUnit: "SG" },
  })

  const { isValid } = formState

  const handleConfirm = handleSubmit((data) => {
    startRun.mutate(
      { ...data, probeId: deviceId },
      {
        onSuccess: () => {
          context.device.get.setData({ particleId: deviceId }, (pData = DEFAULT_DEVICE_STATE) => ({
            ...pData,
            running: true,
          }))
          context.batch.list.invalidate({ probeId: deviceId })
          hide()
        },
        onError(error) {
          toast.error(error.message)
        },
      },
    )
  })

  // function handleCancel() {}

  return (
    <div className="bg-white mx-5 rounded-md">
      <h2 className="font-extrabold text-[24px] my-4">Survey</h2>

      <label htmlFor="name" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
        Batch Name
      </label>
      <input type="text" id="name" className="w-full border-[#B6B4AF] border mb-3" {...register("name")} />

      <label htmlFor="expectedAlcohol" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
        Expected alcohol%
      </label>
      <input type="text" id="expectedAlcohol" className="w-full border-[#B6B4AF] border mb-3" {...register("expectedAlcohol")} />

      <label htmlFor="tankSize" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
        Tank size (Barrels)
      </label>
      <input type="text" id="tankSize" className="w-full border-[#B6B4AF] border mb-3" {...register("tankSize")} />

      <div className="flex">
        <div className="flex-1 mr-4">
          <label htmlFor="originalGravity" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
            Original gravity
          </label>
          <input type="text" id="originalGravity" className="w-full border-[#B6B4AF] border mb-3" {...register("originalGravity")} />
        </div>

        <div>
          <label htmlFor="originalGravityUnit" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
            Unit
          </label>
          <select  id="originalGravityUnit" className="w-full border-[#B6B4AF] border mb-3" {...register("originalGravityUnit")}>
          <option value="">Select Unit</option>
            {units.map((unit) => (
              <option key={unit} value={unit}>
                {unit}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex">
        <div className="flex-1 mr-4">
          <label htmlFor="originalGravity" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
            Final gravity
          </label>
          <input type="text" id="finalGravity" className="w-full border-[#B6B4AF] border mb-3" {...register("finalGravity")} />
        </div>
        <div>
          <label htmlFor="finalGravityUnit" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
            Unit
          </label>
          <select  id="finalGravityUnit" className="w-full border-[#B6B4AF] border mb-3" {...register("finalGravityUnit")}>
          <option value="">Select Unit</option>
            {units.map((unit) => (
              <option key={unit} value={unit}>
                {unit}
              </option>
            ))}
          </select>
        </div>
      </div>

      <label htmlFor="expectedFermentationTime" className="font-extrabold text-[14px] text-[#B6B4AF] block mb-2">
        Expected fermentation time (Days)
      </label>
      <input type="text" id="expectedFermentationTime" className="w-full border-[#B6B4AF] border mb-3" {...register("expectedFermentationTime")} />

      <div className="flex justify-center gap-x-3 mb-4">
        <Button onClick={startRun.isLoading ? noop : hide} variant="plain" className="px-5 py-3">
          Cancel
        </Button>
        <Button onClick={startRun.isLoading ? noop : handleConfirm} variant="primary" className="relative px-5 py-3" disabled={!isValid}>
          <span className={startRun.isLoading ? "opacity-0" : ""}>Confirm</span>
          {startRun.isLoading && (
            <div className="absolute inset-0 flex justify-center items-center">
              <svg.Loading className="mx-auto w-6 h-6" />
            </div>
          )}
        </Button>
      </div>
    </div>
  )
}
