import { useEffect } from "react"
import { Link } from "@tanstack/react-location"
import { Store, setBatchId, trpc, useBatchId, useBatches, useDeviceId } from "@accubrew/state"

import { svg } from "../../../assets"
import { BatchDropdown } from "../../../components"
import { useSelectedDevcieState } from "../../../hooks"

import { DeviceDropdown } from "./DeviceDropdown"
import SignalingButton from "./SignalingButton"

export function DeviceInfo() {
  const user = trpc.user.get.useQuery()
  const selectedDeviceId = useDeviceId()!
  const selectedBatchId = useBatchId()
  const batches = useBatches({ deviceId: selectedDeviceId })
  const role = user.data?.role

  useEffect(() => {
    if (user.data?.role !== "COLLABORATOR") {
      if (batches.data?.batches?.[0]?.id !== Store.useStore.getState().batchId) {
        setBatchId(batches.data?.batches?.[0]?.id)
      }
    }
  }, [user.data?.role, batches.data?.batches])

  return (
    <div className="bg-white rounded-md py-4 px-5 shadow sm:space-x-4 sm:flex sm:items-center sm:justify-between">
      {role === "COLLABORATOR" ? null : (
        <div className="flex-1 flex justify-between items-center mb-4 sm:mb-0">
          <SignalingButton screen="dashboard" />
          <DeviceState />
        </div>
      )}

      <div className="sm:flex-1 flex">
        <div className="flex-1">
          {role === "COLLABORATOR" ? (
            <BatchDropdown
              loading={batches.isLoading}
              batches={batches.data?.batches ?? []}
              selectedBatchId={selectedBatchId}
              onBatchIdChange={setBatchId}
              highlightRunningBatch={false}
            />
          ) : (
            <DeviceDropdown />
          )}
        </div>
        {role === "COLLABORATOR" ? null : <SettingsButton />}
      </div>
    </div>
  )
}

function SettingsButton() {
  const deviceId = useDeviceId()
  const disabled = deviceId === undefined
  return (
    <Link className={`px-4 py-2 ${disabled ? "opacity-50" : ""}`} disabled={disabled} to="/dashboard/settings/devices">
      <svg.Settings />
    </Link>
  )
}

function DeviceState() {
  const deviceState = useSelectedDevcieState()
  const { isFetching: loading } = deviceState
  const { online, busy, charging, wifi, power } = deviceState.data ?? {}

  return (
    <div className="flex items-center gap-4 py-2">
      <div title={`${online ? wifi ?? 0 : 0}%`}>
        <svg.Wifi value={online ? wifi ?? 0 : 0} />
      </div>

      <div title={`${online && charging ? "Charging" : "Not Charging"} / ${online ? power ?? 0 : 0}%`}>
        {/* <svg.Power online={online ?? false} charging={online && charging} power={online ? power ?? 0 : 0} /> */}
        <p className=" inline-flex  gap-1" style={{ alignItems: "center", fontSize: "14px" }}>
          {online ? Math.min(power ?? 0, 100) : 0}% <svg.Power online={online ?? false} charging={online && charging} power={online ? power ?? 0 : 0} />
        </p>
      </div>
      <Link disabled={!deviceState.data?.particleId} to={`/dashboard/home/probe-info/${deviceState.data?.particleId}`} title="Check Device Details">
        <svg.Info className={deviceState.data?.particleId ? "" : "opacity-50"} />
      </Link>
      <div
        className={`w-2 rounded-full h-2 animate-pulse ${getIndicatorBgClass({ online, busy, loading })}`}
        title={`${online ? "Online" : "Offline"}${online && busy ? " / Busy" : ""} `}
      />
      <svg.Loading className={`block w-4 h-4  ${loading ? "opacity-1" : "opacity-0"}`} />
    </div>
  )
}

function getIndicatorBgClass({ online, busy, loading }: { loading: boolean; online?: boolean; busy?: boolean }) {
  if (loading) return "bg-transparent"
  if (online === false) return "bg-black"
  if (busy) return "bg-red-400"
  if (busy === false) return "bg-green-600"
  else return "bg-transparent"
}
