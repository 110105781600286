import { twMerge } from "tailwind-merge"

import logo from "./logo.png"
import FullLogo from "./FullLogo.png"
import Avatar from "./Profile_background.png"
import { ReactComponent as Book } from "./book.svg"
import { ReactComponent as LoadingSVG } from "./loading.svg"
import { ReactComponent as Bookmark } from "./bookmark.svg"
import { ReactComponent as Calendar } from "./calendar.svg"
import { ReactComponent as Dashboard } from "./dashboard.svg"
import { ReactComponent as Layout } from "./layout.svg"
import { ReactComponent as MessageBox } from "./message-box.svg"
import { ReactComponent as Info } from "./info.svg"
import { ReactComponent as ChevronDown } from "./chevron-down.svg"
import { ReactComponent as Settings } from "./settings.svg"
import { ReactComponent as Clock } from "./clock.svg"
import { ReactComponent as Notification } from "./notification.svg"
import { ReactComponent as RotationRight } from "./rotation-right.svg"
import { ReactComponent as Play } from "./play.svg"
import { ReactComponent as Pause } from "./pause.svg"
import { ReactComponent as Plus } from "./plus.svg"
import { ReactComponent as Up } from "./up.svg"
import { ReactComponent as Maximize2 } from "./maximize2.svg"
import { ReactComponent as UploadCloud } from "./upload-cloud.svg"
import { ReactComponent as LoginBg } from "./marco.svg"
import { ReactComponent as Google } from "./google.svg"
import { ReactComponent as Apple } from "./apple.svg"
import { ReactComponent as EnableCheck } from "./enableCheck.svg"
import { ReactComponent as DisableCheck } from "./disableCheck.svg"
import { ReactComponent as LeftArrow } from "./left.svg"
import { ReactComponent as Download } from "./download.svg"
import { ReactComponent as Edit } from "./editBlue.svg"
import { ReactComponent as OrangePlus } from "./orangePlus.svg"
import { ReactComponent as BlueDownload } from "./probDownload.svg"
import { ReactComponent as Reset } from "./reset.svg"
import { ReactComponent as LargeLogo } from "./FullLogo.svg"
import { ReactComponent as Instagram } from "./instagram.svg"
import { ReactComponent as LinkedIn } from "./linkedin.svg"
import { ReactComponent as Twitter } from "./twitter.svg"
import { ReactComponent as Youtube } from "./youtube.svg"
import { ReactComponent as CommingSoon } from "./coming-soon.svg"
import { ReactComponent as BrewNotification } from "./notificationIcon.svg"
import { ReactComponent as Sort } from "./sort.svg"
import { Wifi } from "./Wifi"
import { Power } from "./Power"
import { Scale } from "./Scale"

export const images = {
  logo,
  FullLogo,
  Avatar,
} as const

function Loading({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return <LoadingSVG className={twMerge("w-8 h-8 mr-2 animate-spin text-[#F88D2A] fill-[#FEEEDF]", className)} {...props} />
}

export const svg = {
  Book,
  Bookmark,
  Calendar,
  Dashboard,
  Layout,
  MessageBox,
  Info,
  ChevronDown,
  Wifi,
  Power,
  Clock,
  Loading,
  Notification,
  Settings,
  RotationRight,
  Play,
  Pause,
  Plus,
  Up,
  Scale,
  Maximize2,
  UploadCloud,
  LoginBg,
  Google,
  Apple,
  EnableCheck,
  DisableCheck,
  LeftArrow,
  Download,
  Edit,
  OrangePlus,
  BlueDownload,
  Reset,
  LargeLogo,
  Instagram,
  LinkedIn,
  Twitter,
  Youtube,
  CommingSoon,
  BrewNotification,
  Sort,
} as const
