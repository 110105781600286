import { useEffect, useState } from "react"

import { setDeviceId, trpc, useDeviceId, setTemp, useTemp } from "@accubrew/state"
import { z } from "zod"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { AiOutlineDelete } from "react-icons/ai"
import { Confirm, useConfirmRef } from "../../components"
import { useSelectedDevcieState } from "../../hooks"

import accubrewDeviceImage from "./accubrew-probe.png"
import { svg } from "../../assets"
import TransactionRecord from "./components/TransactionHistory"
import SubscriptionRecord from "./components/SubscriptionDetails"
import { Layout } from "./Layout"
import SignalingButton from "../dashboard/components/SignalingButton"
import { Modal, Button } from "../../components"

import { toast } from "react-toastify"
function formatToKB(value: number) {
  return Math.round(value / 1024)
}
function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}
export function Devices() {
  const temp:any = useTemp()
  const deviceId = useDeviceId()
  const context = trpc.useContext()
  const devices: any = trpc.device.list.useQuery()
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>(deviceId ?? "")
  const [selectedDeviceName, setSelectedDeviceName] = useState<string>("")
  const [renameDeviceModal, setRenameDeviceModal] = useState<boolean>(false)

  const [subscriptionDetails, setSubscriptioDetails] = useState<any>()
  const [TransactionHistory, setTransactionHistory] = useState([])
  const [transactionHistoryUrl, setTransactionHistoryUrl] = useState<string>("")
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>("")
  const enabled = selectedDeviceId !== ""
  const user = trpc.user.get.useQuery()
  const updateUser: any = trpc.user.update.useMutation()

  const deviceDiagnostics = trpc.device.diagnostics.useQuery(selectedDeviceId, { enabled: selectedDeviceId !== "" })
  const temperatureUnit = trpc.user.temperatureUnit.useQuery({ email: user.data?.email!, unit: temp ?? "f" })

  const device = trpc.device.get.useQuery(
    { particleId: selectedDeviceId }, //
    { staleTime: 5 * 60 * 1000, enabled: selectedDeviceId !== "" },
  )

  const online = device.data?.online ?? false
  const { used, total } = deviceDiagnostics.data?.payload.device.system.memory ?? { total: 0, used: 0 }

  const isLoading = (deviceDiagnostics.isLoading && enabled) || (device.isLoading && enabled)

  const subscriptionDetail = trpc.payment.subscriptionDetail.useMutation({
    onSuccess(data) {
      // console.log("subscription", data)
    },
  })
  useEffect(() => {
    selectedDeviceId != "" && onChangeDevice(selectedDeviceId)
  }, [])
  const onChangeDevice = (deviceId: string) => {
    setSelectedDeviceId(deviceId)
    setDeviceId(deviceId)

    subscriptionDetail.mutate(
      {
        particleId: deviceId ?? "",
        email: user?.data?.email ?? "",
      },
      {
        onSuccess(data: any) {
          if (data != null) {
            const endDate = new Date(Number(data?.subscriptionDetail?.subscriptionEndDate) * 1000) // Multiply by 1000 to convert seconds to milliseconds
            const endDateFormatted = endDate.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }) // MM/dd/YYYY format
            setSubscriptionEndDate(endDateFormatted)
            setTransactionHistoryUrl(data?.url)
            setSubscriptioDetails(data.subscriptionDetail)
            setTransactionHistory(data.transactionHistory)
          }
        },
        onError(error) {
          console.log("ERROR", JSON.stringify(error, null, 4))
        },
      },
    )
  }
  const handleTempSwitchPress = async (temp: "c" | "f") => {
    
    setTemp(temp)
    updateUser.mutate(
      {
        firstName: user?.data?.firstName ?? "",
      },
      {
        async onSuccess(data: any) {
          await context.user.get.refetch()
        },
        onError(error: any) {
          console.log("ERROR user.update", error)
        },
      },
    )
  }
  return (
    <Page title="Device Management">
      <Layout>
        <div className="p-4">
          <div className="grid grid-cols-1 xl:grid-cols-2 mt-2">
            <select
              className="bg-white border border-gray-300 text-gray-800 shadow-lg text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4 pr-9 line-clamp-1"
              value={selectedDeviceId}
              onChange={(e) => onChangeDevice(e.target.value)}
            >
              <option disabled value="">
                Select Device
              </option>
              {devices.data?.devices.map((device: any) => (
                <option value={device.particleId} key={device.particleId}>
                  {device.name} / {device.particleId}
                </option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-3 ">
            <div className="px-4 bg-white rounded-md shadow-md xl:col-span-2  relative p-[35px] overflow-hidden w-full">
              {isLoading && (
                <div className="absolute inset-0 flex justify-center items-center">
                  <svg.Loading />
                </div>
              )}
              <div className=" md:hidden lg:hidden flex justify-between items-center mb-4">
                <h2 className="font-bold text-lg">Probe Management</h2>
                {/* <button className="p-1 text-white font-[700] text-[20px]  bg-[#F88D2A] flex justify-center items-center rounded disabled:opacity-50">
                  <svg.Plus />
                </button> */}
              </div>
              {selectedDeviceId === "" && (
                <div className=" inset-0 flex justify-center items-center">
                  <p className="font-bold">No Devices Selected</p>
                </div>
              )}

              {selectedDeviceId !== "" && (
                <>
                  <div className="flex justify-between items-center mb-4 md:mb-2 border border-gray rounded-md p-3 md:p-0 md:border-none ">
                    <div className="flex items-center">
                      <p className="font-[800]">{selectedDeviceId}</p>
                      <svg.Edit
                        className="ml-2 cursor-pointer hidden md:block p-0.5"
                        onClick={() => {
                          setRenameDeviceModal(true)
                          setSelectedDeviceName(device?.data?.name ?? "")
                        }}
                      />
                    </div>
                    <span className="flex items-center">
                      <svg.BlueDownload className="mr-2" />
                      <UnclaimButton deviceId={selectedDeviceId} />
                    </span>
                  </div>
                  <div className="md:hidden">
                    <button
                      className="flex-1 py-2 rounded-md bg-gold bg-[#F88D2A] text-white font-[800] mb-4"
                      style={{ width: "100%" }}
                      onClick={() => {
                        setRenameDeviceModal(true)
                        setSelectedDeviceName(device?.data?.name ?? "")
                      }}
                    >
                      Rename device
                    </button>
                  </div>
                  <div className=" items-center my-3 md:my-1 grid grid-cols-1 md:grid-cols-3 ">
                    <div className="col-span-2 border-gray border border-gray-300 rounded-md p-4">
                      <p className="font-[800]">Probe Status</p>
                      <span className="flex items-center">
                        <p className=" text-[#858279]">
                          Connected:
                          <span
                            className={`${online ? "bg-darkGreen3 text-darkSuccessful" : "bg-errorDefault/25 text-darkTextError "} rounded-md px-2 py-1 ml-1`}
                          >
                            {" "}
                            {online ? "true" : "false"}
                          </span>
                        </p>
                        <DeviceState />
                      </span>
                    </div>
                    <div className="flex md:block align-center py-2 md:py-4">
                      {" "}
                      <div className="flex md:justify-end md:mb-3">
                        <label className=" border inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800 mt-4">
                          <input id="Toggle3" type="checkbox" className="hidden peer" />
                          <span className={`px-3 py-1 rounded-md  ${temp === "f" ? "bg-darkOrangeDark text-white" : "bg-white"}`} onClick={() =>  handleTempSwitchPress("f")}>
                            °F
                          </span>
                          <span className={`px-3 py-1 rounded-md ${temp === "c" ? "bg-darkOrangeDark text-white" : "bg-white"}`} onClick={() => handleTempSwitchPress("c")}>
                            °C
                          </span>
                        </label>
                      </div>
                      <div className="flex justify-end ml-auto mt-3">
                        <SignalingButton />
                      </div>
                    </div>
                  </div>
                  <div className="flex  mt-4">
                    <ResetButton deviceId={selectedDeviceId} />
                    <button className="flex-1 py-2 rounded-md bg-gold bg-[#F88D2A] text-white font-[800]">View Charts</button>
                  </div>
                </>
              )}
            </div>
            {/* <div className="hidden md:col-span-1 md:block lg:col-span-1 lg:block">
              <div className="px-4 border-dashed border-2 border-orangeDark bg-[#FEF1E4] min-h-[350px] rounded-md shadow-md max-w-2xl md:mx-auto relative overflow-hidden w-full flex items-center justify-center">
                <div className="flex items-center space-x-3 py-0">
                  <div className="font-bold text-[#F88D2A] flex items-center">
                    <svg.OrangePlus className="mr-1" />
                    <span>Connect Probe</span>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div>
            <SubscriptionRecord
              subscriptionDetails={subscriptionDetails}
              selectedDeviceId={selectedDeviceId}
              subscriptionEndDate={subscriptionEndDate}
              devices={devices}
              onChangeDevice={onChangeDevice}
            />
          </div>
          <div>
            <TransactionRecord transactions={TransactionHistory} selectedId={selectedDeviceId} transactionsHistoryUrl={transactionHistoryUrl} />
          </div>
          <Modal visible={renameDeviceModal} onClose={() => setRenameDeviceModal(false)}>
            <RenameDeviceForm hide={() => setRenameDeviceModal(false)} selectedDeviceId={selectedDeviceId} name={selectedDeviceName} />
          </Modal>
        </div>
      </Layout>
      </Page>
   
  )
}

function ResetButton({ deviceId }: { deviceId: string }) {
  const resetDialog = useConfirmRef()
  const rebootDevice = trpc.device.reset.useMutation()
  const user = trpc.user.get.useQuery()
  const device = useSelectedDevcieState()

  const DISABLED = !deviceId || device.isFetching || device.status !== "success" || !device.data?.online || device.data.busy

  function handlePress() {
    resetDialog.current?.show()
  }

  function handleConfirm() {
    rebootDevice.mutate(
      { deviceId },
      {
        onSuccess() {
          resetDialog.current?.hide()
        },
        onError() {
          resetDialog.current?.hide()
          toast.error("Something went wrong")
        },
      },
    )
  }

  const disabled = !device.data?.online || DISABLED || (user.data?.role !== "OWNER" && user.data?.role !== "MANAGER" && user.data?.role === "PRODUCTION")

  return (
    <>
      <button
        disabled={DISABLED}
        onClick={handlePress}
        className={`hidden md:block lg:block flex-1 py-4 rounded-md mr-3 bg-[#FEEEDF] text-[#F88D2A] font-[800] ${disabled ? "opacity-30" : "opacity-100"}`}
      >
        Reset Probe
      </button>
      <button
        disabled={DISABLED}
        onClick={handlePress}
        className="block md:hidden lg:hidden p-4 text-white font-[700] text-[20px]  mr-3 bg-[#FEEEDF] justify-center items-center rounded disabled:opacity-50"
      >
        <svg.Reset />
      </button>
      <Confirm
        ref={resetDialog}
        title="Reset probe?"
        description="Existing charts will be lost without recovery"
        onConfirm={handleConfirm}
        onCancel={() => resetDialog.current?.hide()}
        loading={rebootDevice.isLoading}
      />
    </>
  )
}

function UnclaimButton({ deviceId }: { deviceId: string }) {
  const unclaimDialog = useConfirmRef()
  const devices = trpc.device.list.useQuery()
  const unClaimProbe = trpc.device.unclaim.useMutation()

  const isLoading = unClaimProbe.isLoading || devices.isFetching

  function unclaimSelectedProbe() {
    unclaimDialog.current?.show()
  }

  const handleConfirmUnclaiming = async () => {
    unClaimProbe.mutate(deviceId, {
      async onSuccess() {
        await devices.refetch()
        setDeviceId(undefined)
        requestAnimationFrame(() => {
          unclaimDialog.current?.hide()
        })
      },
    })
  }

  return (
    <>
      <button className="w-8 h-8 bg-errorDefault rounded-md flex justify-center items-center text-bgLight" onClick={unclaimSelectedProbe}>
        <AiOutlineDelete stroke="white" />
      </button>
      <Confirm
        ref={unclaimDialog}
        title="Remove probe"
        subTitle={deviceId}
        description="Deletion will result in data loss beyond recovery"
        onConfirm={handleConfirmUnclaiming}
        onCancel={() => unclaimDialog.current?.hide()}
        loading={isLoading}
      />
    </>
  )
}
function DeviceState() {
  const deviceState = useSelectedDevcieState()
  const { isFetching: loading } = deviceState
  const { online, busy, charging, wifi, power } = deviceState.data ?? {}

  return (
    <div className="flex items-center gap-2 py-2 ml-auto">
      <div title={`${online ? wifi ?? 0 : 0}%`}>
        <svg.Wifi value={online ? wifi ?? 0 : 0} />
      </div>
      <div title={`${online && charging ? "Charging" : "Not Charging"} / ${online ? power ?? 0 : 0}%`}>
        <p className=" inline-flex  gap-1" style={{ alignItems: "center", fontSize: "14px" }}>
          {Math.min(deviceState.data?.power ?? 0, 100)}% <svg.Power online={online ?? false} charging={online && charging} power={online ? power ?? 0 : 0} />
        </p>
      </div>
    </div>
  )
}
function RenameDeviceForm({ hide, selectedDeviceId, name }: { hide: () => void; selectedDeviceId: string; name: string }) {
  // const deviceId = useDeviceId()!
  const schema = z.object({ name: z.string().min(3) })
  const resolver = zodResolver(schema)
  const devices = trpc.device.list.useQuery()
  const renameDevice = trpc.device.rename.useMutation()
  const device = trpc.device.get.useQuery(
    { particleId: selectedDeviceId }, //
    { staleTime: 5 * 60 * 1000, enabled: selectedDeviceId !== "" },
  )
  const { control, formState, handleSubmit, register } = useForm<z.infer<typeof schema>>({
    resolver,
    defaultValues: {
      name: name ?? "",
    },
  })

  const { isValid } = formState
  const isLoading = renameDevice.isLoading
  const DISABLED = isLoading || !isValid || !selectedDeviceId

  function handleCancel() {
    hide()
  }
  const onSubmit = handleSubmit((data) => {
    renameDevice.mutate(
      { deviceId: selectedDeviceId, name: data.name },
      {
        async onSuccess() {
          hide()
          await devices.refetch()
          await device.refetch()
        },
      },
    )
  })

  return (
    <div>
      <form className="" onSubmit={onSubmit}>
        <h2 className="px-4 pt-4 pb-2 text-lg font-bold">Rename Device</h2>
        <div className="flex-1 px-4">
          <label htmlFor="name" className="block mb-1 font-[800] text-[14px] text-[#B6B4AF]">
            New Name
          </label>
          <div className="border mb-5 rounded-[6px]">
            <input type="text" id="name" className="w-full border-none bg-transparent" {...register("name")} />
          </div>
        </div>

        <div className="flex justify-center gap-x-3 mb-4">
          <Button onClick={handleCancel} variant="plain" className="px-4 py-2">
            Cancel
          </Button>
          <Button variant="primary" className="relative px-4 py-2 bg-errorDefault" disabled={isLoading} isLoading={isLoading}>
            Confirm
          </Button>
        </div>
      </form>
    </div>
  )
}
