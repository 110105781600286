import { Controller, useForm } from "react-hook-form"
import z from "zod"
import { isPast, format } from "date-fns"
import { AiOutlineClose } from "react-icons/ai"
import { zodResolver } from "@hookform/resolvers/zod"
import { note as noteSchema } from "@accubrew/validation"
import { RouterOutput, trpc } from "@accubrew/state"

import { svg } from "../../../assets"
import { useState } from "react"

const formSchema = noteSchema.omit({ id: true, batchId: true })

type Note = RouterOutput["note"]["list"][number]

type Props = {
  batchId: string
  close: () => void
  note?: Note
  type?: Note["type"]
}

export function NoteForm({ close, note, batchId, type }: Props) {
  const user = trpc.user.get.useQuery()

  const [noteType, setNoteType] = useState(note?.type ? note?.type : user.data?.role !== "COLLABORATOR" ? "Reminder" : "Batch")
  console.log({ noteType })
  const context = trpc.useContext()
  const addNote = trpc.note.create.useMutation()
  const editNote = trpc.note.edit.useMutation()
  const { control, handleSubmit, formState, setValue, getValues, register } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: note?.type ? note?.type : user.data?.role !== "COLLABORATOR" ? "Reminder" : "Batch",
      description: note?.description ?? "",
      reminderDate: note?.reminderDate ?? new Date(),
    },
    reValidateMode: "onChange",
    mode: "onChange",
  })

  const isPastDate = note?.reminderDate ? isPast(note?.reminderDate) : undefined

  const { isValid } = formState
  const allowedRoles = ["OWNER", "MANAGER", "PRODUCTION"]
  const isAllowed = noteType === "Reminder" ? allowedRoles.includes(user.data!.role) : true
  const submit = handleSubmit((data) => {
    if (note) {
      editNote.mutate(
        {
          batchId,
          id: note.id,
          description: data.description,
          type: data.type,
          reminderDate: noteType == "Reminder" ? data.reminderDate : undefined,
        },
        {
          onSuccess() {
            context.note.list.invalidate({ batchId })
            close()
          },
        },
      )
    } else {
      addNote.mutate(
        { batchId, description: data.description, type: data.type, reminderDate: noteType == "Reminder" ? data.reminderDate : undefined },
        {
          async onSuccess() {
            context.note.list.invalidate({ batchId })
            close()
          },
        },
      )
    }
  })

  return (
    <div className="py-4 px-5 shadow">
      <div className="flex justify-between items-center font-bold mb-5">
        <h2 className="text-lg">{note ? "Edit Note" : "Add Note"}</h2>
        <button onClick={close}>
          <AiOutlineClose />
        </button>
      </div>

      <div className="flex gap-x-2 border max-w-md mx-auto rounded-md p-1 mb-2">
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <>
              {user.data?.role !== "COLLABORATOR" && (
                <button
                  className={`flex-1 py-0.5 rounded-md font-semibold ${field.value === "Reminder" ? "text-white bg-[#427CBF]" : "text-slate-500"}`}
                  onClick={() => {
                    setValue("type", "Reminder")
                    setNoteType("Reminder")
                  }}
                >
                  Reminder
                </button>
              )}

              <button
                className={`flex-1 py-0.5 rounded-md font-semibold ${field.value === "Batch" ? "text-white bg-[#F88D2A]" : "text-slate-500"}`}
                onClick={() => {
                  setValue("type", "Batch")
                  setNoteType("Batch")
                }}
              >
                Batch Note
              </button>
            </>
          )}
        />
      </div>

      <div className="mx-3">
        <textarea className="border border-inherit w-full rounded-md h-12 min-h-[120px] mb-1" {...register("description")} />
        {noteType == "Reminder" && (
          <Controller
            control={control}
            name="reminderDate"
            render={({ field }) => (
              <input
                type="datetime-local"
                className="w-full border border-inherit rounded-md mb-3"
                value={field.value ? format(new Date(field.value), "yyyy-MM-dd'T'HH:mm") : ""}
                onChange={(e) => {
                  const date = new Date(e.target.value)
                  setValue("reminderDate", new Date(date.toLocaleString("en-US", { timeZone: "CET", timeZoneName: "short" })))
                }}
              />
            )}
          />
        )}

        <div className="flex">
          <button className="flex-1 bg-slate-300 py-2 mr-3 rounded-md font-semibold" onClick={close}>
            Cancel
          </button>
          <button
            className={`flex-1 py-2 bg-[#F88D2A] rounded-md font-semibold text-white relative ${isValid ? "" : "opacity-50"}`}
            disabled={!isValid || !isAllowed}
            onClick={submit}
          >
            {addNote.isLoading || editNote.isLoading ? (
              <div className="absolute inset-0 flex justify-center items-center">
                <svg.Loading color="white" className="w-5 h-5" />
              </div>
            ) : null}
            <span className={addNote.isLoading || editNote.isLoading ? "opacity-0" : ""}>{note ? "Edit Note" : "Add Note"}</span>
          </button>
        </div>
      </div>
    </div>
  )
}
