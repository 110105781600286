import { twMerge } from "tailwind-merge"
import { ROUND, round, calculateROC, lastElement, getROCStatus, sgToPlato } from "@accubrew/utils"
import { StateContainer, trpc, useStateContainer, useTemp, useUnit } from "@accubrew/state"

import { svg } from "../../../assets"
import { Modal } from "../../../components"
import { ThresholdsForm } from "./ThresholdsForm"

type GaugeChartsProps = { batchId: string }

export function GaugeCharts({ batchId }: GaugeChartsProps) {
  const modalStateRef = useStateContainer<{ visible: boolean; type: "temp" | "sg" } | undefined>()
  const stats = trpc.batch.stats.useQuery({ batchId }, { enabled: !!batchId })
  const loading = stats.isLoading
  const preferredTemp = useTemp() ?? "f"
  const preferredUnit = useUnit() ?? "SG"
  const SGData = stats.data?.datasets?.specificGravity.data ?? []
  function convertDataToPlato(data: any) {

  const platoArray = Object?.values(data)?.map((sg):any => sgToPlato(sg as number));
  return platoArray;
    
  }
  // const specificGravity = stats.data?.datasets?.[preferredUnit === "SG" ? "specificGravity" : "plato"].data ?? []
  const gravity = lastElement(SGData) ?? 0

  const gravityValue =
    preferredUnit === "Plato"
      ? Number.isInteger(sgToPlato(gravity ?? 0))
        ? sgToPlato(gravity ?? 0).toString()
        : sgToPlato(gravity ?? 0)
            .toFixed(3)
            .toString()
      : gravity.toFixed(3).toString()
  const clarity = stats.data?.datasets?.clarity.data ?? []
  const fermentation = stats.data?.datasets?.fermentation.data ?? []
  const temp = stats.data?.datasets?.[preferredTemp === "f" ? "temperatureF" : "temperatureC"].data ?? []
  const specificGravity = preferredUnit === "Plato" ? convertDataToPlato(SGData) : SGData
  console.log("temp",temp ,"sg",specificGravity);
  const SGROC = round(calculateROC(specificGravity), ROUND.default)
  const FROC = round(calculateROC(fermentation), ROUND.default)
  const CROC = round(calculateROC(clarity), ROUND.default)
  const TROC = round(calculateROC(temp), ROUND.default)

  const SGROCStatus = getROCStatus(SGROC)
  const FROCStatus = getROCStatus(FROC)
  const CROCStatus = getROCStatus(CROC)
  const TROCStatus = getROCStatus(TROC)

  return (
    <>
      <div className="grid gap-4 sm:grid-cols-2">
        <GaugeCard
          title={`Specific Gravity (${preferredUnit}):`}
          thresholdsTitle="Set Threshold"
          onThresholdsClick={() => modalStateRef.current?.setState({ visible: true, type: "sg" })}
          loading={loading}
          value={gravityValue}
          roc={`${SGROC === 0 ? "0" : SGROC}${SGROCStatus === "none" ? "" : "%"}`}
          status={getROCStatus(SGROC)}
          className="bg-[#E9FFE6] bg-opacity-40 border border-[#3EAE2B] border-opacity-40"
        />
        <GaugeCard
          title="Fermentation:"
          loading={loading}
          value={(lastElement(fermentation) ?? 0).toFixed(3).toString()}
          roc={`${FROC === 0 ? "0" : FROC}${FROCStatus === "none" ? "" : "%"}`}
          status={getROCStatus(FROC)}
          className="bg-[#427CBF] bg-opacity-[0.09] border border-[#427CBF] border-opacity-[0.61]"
        />
        <GaugeCard
          title="Clarity:"
          loading={loading}
          value={(lastElement(clarity) ?? 0).toString()}
          roc={`${CROC === 0 ? "0" : CROC}${CROCStatus === "none" ? "" : "%"}`}
          status={getROCStatus(CROC)}
          className="bg-[#F8EEFC] bg-opacity-40 border border-[#EAB5FF] border-opacity-40"
        />
        <GaugeCard
          title={`Temperature °${preferredTemp=="f"?"F":"C"}:`}
          thresholdsTitle="Set Thresholds"
          onThresholdsClick={() => modalStateRef.current?.setState({ visible: true, type: "temp" })}
          loading={loading}
          value={`${(lastElement(temp) ?? 0).toString()}°`}
          roc={`${TROC === 0 ? "0" : TROC}${TROCStatus === "none" ? "" : "°"}`}
          status={getROCStatus(TROC)}
          className="bg-[#FFFBE4] bg-opacity-40 border border-[#FFE08F] border-opacity-40"
        />
      </div>
      <StateContainer<{ visible: boolean; type: "temp" | "sg" } | undefined> ref={modalStateRef} initialState={undefined}>
        {(state, setState) => (
          <Modal visible={state?.visible ?? false} onClose={() => setState((state) => ({ type: state?.type!, visible: false }))} portal>
            <ThresholdsForm type={state?.type!} hide={() => setState((state) => ({ type: state?.type!, visible: false }))} />
          </Modal>
        )}
      </StateContainer>
    </>
  )
}

type Props = {
  title: string
  value: string
  roc: string
  loading?: boolean
  className?: string
  status?: "up" | "down" | "none"
  thresholdsTitle?: string
  onThresholdsClick?: () => void
}

function GaugeCard({ title, value, roc, loading, className, status, thresholdsTitle, onThresholdsClick }: Props) {
  return (
    <div className="p-4 shadow-lg bg-white rounded-md flex">
      <div className="flex-1 mr-2">
        <div className="flex justify-between items-center">
          <h3 className="text-base font-[400] mb-2 text-[18px]">{title}</h3>
          {thresholdsTitle && (
            <button className="bg-orangeDark text-white text-xs px-2 py-1 mb-1 rounded" onClick={onThresholdsClick}>
              {thresholdsTitle}
            </button>
          )}
        </div>
        <div className={twMerge("rounded p-4 flex flex-1 justify-between relative flex-row items-center", className ?? "")}>
          <div>
            <p className={`font-[800] text-[22px] ${loading ? "opacity-0" : "opacity-1"}`}>{value}</p>
          </div>
          <div className={`flex items-center ${loading ? "opacity-0" : "opacity-1"}`}>
            <p className={`font-[700] text-[20px] ${status === "up" ? "text-[#3EAE2B]" : ""} ${status === "down" ? "text-[#D32F2F]" : ""}`}>{roc}</p>
            {status === "up" && <svg.Up className="ml-2 fill-[#3EAE2B]" />}
            {status === "down" && <svg.Up className="ml-2 rotate-[180deg] fill-[#D32F2F]" />}
          </div>
          {loading && (
            <div className="absolute inset-0 flex justify-center items-center">
              <svg.Loading className="w-6 h-6" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
