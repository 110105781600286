import { useEffect } from "react"
import { Link } from "@tanstack/react-location"
import { images, svg } from "../assets"
import LoginBg from "../assets/loginBg.svg"
import ReactFlagsSelect from "react-flags-select"
type Props = { children: React.ReactNode; title?: string; className?: string; buttonHref?: string; buttonText?: string; backgroundImg?: string }

function AuthHeader({ buttonText, buttonHref }: { buttonText?: string; buttonHref?: string }) {
  return (
    <div className="flex justify-between items-center  md:px-5 pt-5 mb-16 w-full max-w-5xl mx-auto">
      <div className="py-1/2">
        <img src={images.logo} alt="accupair" className=" lg:hidden md:hidden w-[34px] h-[49px]  md:ml-2" />

        <span className="md:ml-2 hidden lg:block md:block">
          <svg.LargeLogo />
        </span>
      </div>
      <div className="py-1/2  ml-auto mr-1 border-none py-4 block md:block lg:hidden ">
        <ReactFlagsSelect
          className=" bg-white ml-auto rounded-lg border-none !pb-0"
          countries={["US"]}
          customLabels={{ US: "EN" }}
          placeholder="lng"
          selected="US"
          showSelectedLabel={false}
          onSelect={function (countryCode: string): void {
            throw new Error("Function not implemented.")
          }}
        />
      </div>
      {buttonText && (
        <Link to={buttonHref} className="bg-[#FEEEDF] text-[#F88D2A] py-2.5 px-3  lg:py-4 lg:px-9 font-[800] text-[14px] md:mr-2 rounded-md">
          {buttonText}
        </Link>
      )}
    </div>
  )
}

export function AuthLayout({ children, title, className, buttonHref, buttonText, backgroundImg }: Props) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return (
    <>
      <div className="grid grid-cols-1  lg:grid-cols-2">
        <div className={`min-h-screen w-full flex flex-col bg-[#F8F8F8] px-6 md:px-10 ${className}`}>
          <AuthHeader buttonText={buttonText} buttonHref={buttonHref} />
          <div className="w-full max-w-5xl mx-auto">{children}</div>
          <div className="px-5 lg:px-12 mb-8  text-[#858279] mt-auto">
            <div className="grid md:grid-cols-3 grid-cols-2 lg:gap-2">
              <a href="https://accubrew.io/" className="mb-5 sm:mb-0  md:col-span-1 lg:clo-span-1 col-span-2">
                {new Date().getFullYear()} accubrew.io
              </a>
              <a href="https://accubrew.io/privacy-policy" className="">
                Privacy policy
              </a>
              <a href="https://accubrew.io/terms-of-service" className="">
                Terms of use
              </a>
            </div>
            <div className="flex items-center gap-6 text-[#858279] mt-2 block  lg:hidden">
              <a href="https://www.instagram.com/accubrew/" className="footerIcon">
                <svg.Instagram />
              </a>
              {/* <a href="https://accubrew.io/" className="footerIcon">
                <svg.LinkedIn />
              </a> */}
              <a href="https://twitter.com/GPAccuBrew" className="footerIcon">
                <svg.Twitter />
              </a>
              <a href="https://www.youtube.com/@accubrew650" className="footerIcon">
                <svg.Youtube />
              </a>
            </div>
          </div>
        </div>
        <div className="relative hidden md:col-span-1 lg:col-span-1 lg:block">
          <div
            className="min-h-screen bg-no-repeat bg-cover bg-center px-10 col-80 object-fit absolute inset-0"
            style={{ backgroundImage: `url(${backgroundImg || LoginBg})` }}
          >
            <div className="py-1/2 pt-5 ml-auto">
              <ReactFlagsSelect
                className="w-[100px] bg-white ml-auto rounded-lg border-none !pb-0"
                countries={["US"]}
                customLabels={{ US: "EN" }}
                placeholder="lng"
                selected="US"
                onSelect={function (countryCode: string): void {
                  throw new Error("Function not implemented.")
                }}
              />
            </div>
          </div>
          <footer className="mb-8 text-[#858279] mt-auto absolute bottom-0 right-9">
            <div className="flex items-center gap-6">
              <a href="https://www.instagram.com/accubrew/">
                <svg.Instagram />
              </a>
              {/* <a href="https://accubrew.io/">
                <svg.LinkedIn />
              </a> */}
              <a href="https://twitter.com/GPAccuBrew">
                <svg.Twitter />
              </a>
              <a href="https://www.youtube.com/@accubrew650">
                <svg.Youtube />
              </a>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

export function AuthCard({ children, title, className }: { children: React.ReactNode; title?: string; className?: string }) {
  return (
    <div className={`bg-white px-5 mb-9 rounded-md pt-10 md:mx-12 ${className}`}>
      <div className="font-[800] text-[24px] mb-4 text-[#342F20]">{title}</div>
      {children}
    </div>
  )
}

export function Page() {
  return null
}
