import React, { useEffect, useState } from "react"
import { AiOutlineDelete } from "react-icons/ai"
import { FiArrowLeft } from "react-icons/fi"
import { FiDownload } from "react-icons/fi"
import Notes from "./components/Notes"
import { useBatchId } from "@accubrew/state"
import Charts from "./components/Charts"
import { Link, useNavigate } from "@tanstack/react-location"
import { StateContainer, trpc, useStateContainer, useBatches, useDeviceId } from "@accubrew/state"
import { ConfirmModal } from "../../components"
import { toast } from "react-toastify"
import { svg } from "../../assets"
function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}
export function BatchHistory() {
  const navigate = useNavigate()
  const user = trpc.user.get.useQuery()

  const batchId: any = useBatchId()
  const deviceId = useDeviceId()!
  const [selectedBatchId, setSelectedBatchId] = useState<string | null | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const allbatches: any = trpc.batch.listAll.useQuery({ page: 0 })
  const deletebatch = allbatches?.data?.batches?.filter((batch: any) => batch.id === batchId)!
  const batchname = deletebatch || []
  const overlayBatchName = allbatches?.data?.batches?.filter((batch: any) => batch.id === selectedBatchId)!
  type BatchDeleteType = { visible: boolean; batchId?: any }
  const deleteBatch = trpc.batch.delete.useMutation()
  const deleteBatchConfirmRef = useStateContainer<BatchDeleteType>()
  const batches = useBatches({ deviceId })
  const notes = trpc.note.list.useQuery({ batchId })
  const overlayNotes = trpc.note.list.useQuery({ batchId: selectedBatchId! }, { enabled: !!selectedBatchId })

  const { data, refetch, isSuccess, error, isLoading } = trpc.batch.exportReport.useQuery({ batchId: batchId ?? "" }, { enabled: false })

  useEffect(() => {
    if (isSuccess) {
      const link: any = document.createElement("a")
      link.download = data?.pdfUrl
      link.href = data?.pdfUrl
      link.click()
    }
  }, [isSuccess])

  const handleDownloadReport = async () => {
    setLoading(true) // Set loading state to true before refetch

    try {
      await refetch()
      setLoading(false)
    } catch (error) {
      console.error("Error while fetching data:", error)
      setLoading(false)
    }
    if (error) {
      toast.error(error.message)
    }
  }

  return (
    <Page title="Batch history">
      <div className="mx-5">
        <div className="rounded-2xl flex justify-between items-center gap-3 w-full">
          <div className="rounded-md flex space-x-5 w-fit" role="group">
            <Link to="/dashboard/batches" className="inline-flex text-sm font-medium">
              <FiArrowLeft className="w-5 h-5" />
              <span className="mt-0 font-[Mulish]">Back</span>
            </Link>
          </div>

          <div className="flex justify-center items-center gap-5">
            {!["PRODUCTION", "COLLABORATOR"].includes(user.data!.role) && (
              <button className="flex justify-center items-center gap-2 font-semibold text-successful" onClick={handleDownloadReport}>
                {loading ? (
                  <svg.Loading className="mx-auto w-6 h-6" />
                ) : (
                  <>
                    <span>Export</span>
                    <FiDownload className="h-5 w-5" />
                  </>
                )}
              </button>
            )}
            {!["MANAGER", "PRODUCTION", "COLLABORATOR"].includes(user.data!.role) && (
              <button
                onClick={() => {
                  deletebatch.map((item: any) =>
                    item.status === "STOPPED"
                      ? deleteBatchConfirmRef.current?.setState({ visible: true, batchId })
                      : toast.info("You can't delete a running batch."),
                  )
                }}
              >
                <AiOutlineDelete className="h-6 w-6 text-red-600" />
              </button>
            )}
          </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row gap-3 my-5">
          {batchId && (
            <>
              <Charts batchId={batchId} batchname={batchname} selectedBatchId={selectedBatchId} setSelectedBatchId={setSelectedBatchId} />
              <Notes batchId={batchId} notes={notes} overlayNotes={overlayNotes} batchname={batchname} overlayBatchName={overlayBatchName} />
            </>
          )}
        </div>
      </div>
      <StateContainer initialState={{ visible: false }} ref={deleteBatchConfirmRef}>
        {(state: any, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure ?"
            description="This action cannot be undone."
            onConfirm={async () => {
              await deleteBatch.mutateAsync({ batchId: state.batchId! })
              navigate({ to: "/dashboard/batches" })
              setState({ visible: false })
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            loading={deleteBatch.isLoading}
          />
        )}
      </StateContainer>
    </Page>
  )
}
