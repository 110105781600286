import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { trpc } from "@accubrew/state"

import { Button, AuthLayout, AuthCard } from "../../components"
import { Link } from "@tanstack/react-location"
import ResetBg from "../../assets/forgetpasswordBg.svg"
import { toast } from "react-toastify"
const schema = z.object({
  email: z.string().email(),
})

const resolver = zodResolver(schema)

const __DEV__ = import.meta.env.DEV
const isProduction:string = import.meta.env.VITE_IS_PRODUCTION
export function ForgotPassword() {
  const forgotPassword = trpc.user.forgotPassword.useMutation()

  const { handleSubmit, formState, register } = useForm<z.infer<typeof schema>>({
    resolver,
    defaultValues: __DEV__ ? { email: "customer@argonteq.com" } : { email: "" },
    mode: "onChange",
  })

  const { isValid } = formState

  const onSubmit = handleSubmit(({ email }) =>
    forgotPassword.mutate(
      { email, isProduction },
      {
        onError(error:any) {
          if (error.data?.code === "TOO_MANY_REQUESTS") {
            toast.error("Too many requests. Please try again later.")
          } else {
            toast.error("Something went wrong. Please try again later.")
          }
        },
      },
    ),
  )

  return (
    <AuthLayout title={forgotPassword?.isSuccess ? "Check your mail" : "Password recovery"} backgroundImg={ResetBg}>
      <AuthCard title={forgotPassword?.isSuccess ? "Check your mail" : "Password recovery"}>
        <div className="mb-8 text-[#342F20] text-[18px]">
          {forgotPassword?.isSuccess ? (
            <>
              We have sent a password recovery email to your email <span className="text-[#F88D2A]">{forgotPassword?.variables?.email}</span>
            </>
          ) : (
            "Please enter your email address and if you have an account we will send you a reset link"
          )}
        </div>
        {forgotPassword?.isSuccess ? (
          <Button link to="/auth/login" title="Back to login" variant="primary" className="py-3 mb-15 block w-full text-[18px] font-[800]" />
        ) : (
          <form className="mb-3 pb-14" onSubmit={onSubmit}>
            <label htmlFor="email" className="block mb-1 font-[800] text-[14px] text-[#B6B4AF]">
              Email
            </label>
            <div className="border  rounded-[6px]">
              <input type="email" id="email" placeholder="example@email.com" className="w-full border-none bg-transparent" {...register("email")} />
            </div>
            <div className="flex justify-end">
              <Link to="/auth/login" className="py-4 pl-3 text-[#F88D2A] text-[14px] font-[800]">
                I know my password, take me back to login.
              </Link>
            </div>
            <Button
              title="Send"
              variant="primary"
              className="py-3 mb-15 block w-full text-[18px] font-[800]"
              disabled={!isValid}
              isLoading={forgotPassword?.isLoading}
            />
          </form>
        )}
      </AuthCard>
    </AuthLayout>
  )
}
