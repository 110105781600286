import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { setToken, trpc } from "@accubrew/state"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { Button, AuthLayout, AuthCard } from "../../components"
import { useState } from "react"
import { images, svg } from "../../assets"
import { useGoogleLogin } from "@react-oauth/google"
import AppleLogin from "react-apple-login"
import { toast } from "react-toastify"
import jwt_decode from "jwt-decode"
import axios from "axios"
import SignupBg from "../../assets/marco.svg"
const schema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters")
    .regex(/[A-Z]/, "Password must include at least 1 uppercase letter")
    .regex(/[0-9]/, "Password must include at least 1 number")
    .min(1, "Password is required"),
})

const resolver = zodResolver(schema)

const __DEV__ = import.meta.env.DEV

export function Register() {
  const context = trpc.useContext()
  const signup = trpc.user.register.useMutation()
  const googleLogin = trpc.user.googleSignIn.useMutation()
  const appleLogin = trpc.user.appleSignIn.useMutation()
  const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, formState, register } = useForm<z.infer<typeof schema>>({
    resolver,
    defaultValues: __DEV__ ? { email: "", password: "" } : {},
  })

  const { isValid } = formState

  const onSubmit = handleSubmit(({ email, password }) => {
    signup.mutate(
      { email, password },
      {
        onSuccess(data) {
          context.user.get.setData(undefined, data.user)
          setToken(data.token)
        },
        onError(error) {
          toast.error(error.message)
        },
      },
    )
  })
  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState)
  }
  const handleGoogleLogin = (response: any) => {
    // Handle successful login

    // Exchange the authorization code for an access token
    const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID
    const clientSecret = import.meta.env.VITE_GOOGLE_CLIENT_SECRET
    const code = response.code
    const redirectUri = import.meta.env.VITE_REDIRECT_URI // Replace with the correct redirect URI

    axios
      .post("https://oauth2.googleapis.com/token", {
        code,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: "authorization_code",
      })
      .then((tokenResponse) => {
        // Access the access token from the response
        const accessToken = tokenResponse.data.access_token

        // Fetch user information using the access token
        axios
          .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((userInfoResponse) => {
            // Access user information from the response
            const { email, sub } = userInfoResponse.data

            googleLogin.mutate(
              { email, googleId: sub },
              {
                onSuccess(data) {
                  context.user.get.setData(undefined, data.user)
                  setToken(data.token)
                  localStorage.setItem("email", "")
                  localStorage.setItem("password", "")
                },
                onError(error) {
                  toast.error("Invalid credentials")
                },
              },
            )
          })
          .catch((error) => {
            console.error("Error fetching user info", error)
          })
      })
      .catch((error) => {
        console.error("Error exchanging code for access token", error)
      })
  }

  const handleAppleLogin = (appleResponse: any) => {
    let decoded: any = jwt_decode(appleResponse?.authorization?.id_token)

    appleLogin.mutate(
      { email: decoded?.email, appleId: decoded?.sub },
      {
        onSuccess(data:any) {
          context.user.get.setData(undefined, data.user)
          setToken(data.token)
          localStorage.setItem("email", "")
          localStorage.setItem("password", "")
        },
        onError(error:any) {
          toast.error("Invalid credentials")
        },
      },
    )
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      handleGoogleLogin(credentialResponse)
    },
    flow: "auth-code",
  })
  return (
    <AuthLayout title="Sign Up" buttonText="Sign In" buttonHref="/auth/login" backgroundImg={SignupBg}>
      <AuthCard title="Sign up to AccuBrew">
        <div className="mb-8 grid grid-cols-2">
          <div className="pr-3">
            <Button
              title="Google"
              icon={<svg.Google stroke="#F88D2A" />}
              variant="secondary"
              className="py-4 px-10  font-[800] mx-auto inline-block text-[18px] w-full"
              onClick={() => loginWithGoogle()}
            />
          </div>

          <div className="pl-3">
            <AppleLogin
              clientId={import.meta.env.VITE_APPLE_CLIENT_ID}
              redirectURI={import.meta.env.VITE_REDIRECT_URI}
              usePopup
              responseType="code"
              responseMode="query"
              callback={(appleResponse) => handleAppleLogin(appleResponse)} // Catch the response
              render={(renderProps) => (
                <Button
                  title="Apple"
                  icon={<svg.Apple stroke="#F88D2A" />}
                  variant="secondary"
                  onClick={renderProps.onClick}
                  className="py-4 px-10  font-[800]  inline-block text-[18px] w-full"
                />
              )}
              scope="email name"
              // responseType={"id_token"}
              // responseMode={"query"}

              // usePopup={false}
            />
          </div>
        </div>
        <div className="h-5 mb-5 relative flex items-center">
          <div className="h-[1px] bg-[#ededed] flex-1"></div>
          <p className="absolute top-0 left-[50%] bg-white text-center w-9 text-[#B6B4AF] -translate-x-[50%] text-[14px] leading-[20px]">or</p>
        </div>
        <div className="mb-8"></div>
        <form className="mb-3 pb-14" onSubmit={onSubmit}>
          <label htmlFor="email" className="block mb-1 font-[800] text-[14px] text-[#B6B4AF]">
            Email
          </label>
          <div className={`border rounded-[6px] ${formState?.errors?.email && "border-red-500"} ${formState?.errors?.email ? "mb-2" : "mb-5"}`}>
            <input type="text" id="email" className="w-full border-none bg-transparent" {...register("email")} />
          </div>
          {formState?.errors?.email?.message && <p className="text-red-500 text-sm mb-5">{formState?.errors?.email?.message}</p>}
          <label htmlFor="password" className="block mb-1 font-[800] text-[14px] text-[#B6B4AF] ">
            Password
          </label>
          <div className="relative">
            <div className={`border rounded-[6px] ${formState?.errors?.password && "border-red-500"} ${formState?.errors?.password ? "mb-2" : "mb-5"}`}>
              <input type={showPassword ? "text" : "password"} id="password" className="w-full border-none bg-transparent" {...register("password")} />
            </div>
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <button type="button" onClick={handleTogglePassword} className="focus:outline-none">
                {showPassword ? <HiEyeOff className="text-gray-400" /> : <HiEye className="text-gray-400" />}
              </button>
            </div>
          </div>
          {formState?.errors?.password?.message && <p className="text-red-500 text-sm mb-5">{formState?.errors?.password?.message}</p>}
          <ul className="list-disc p-4 text-[#B6B4AF] font-[16px]">
            <li>Password should be at least 8 characters</li>
            <li>Password should include at least 1 uppercase letter, 1 number</li>
          </ul>
          <Button
            title="Create my free account"
            variant="primary"
            className="py-3 mb-15 block w-full text-[18px] font-[800]"
            // disabled={!isValid}
            isLoading={signup.isLoading}
          />
          <div className="text-[#858279] font-[400] text-[14px] mt-4">
            <p className="mb-6">
              This site is protected by reCAPTCHA and the Google{" "}
              <a href=" " className=" font-[800]">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href=" " className=" font-[800]">
                Terms of Service
              </a>{" "}
              apply.
            </p>
            <p>
              {" "}
              By clicking “Create my free account”, I agree to AccuBrew`s{" "}
              <a href=" " className=" font-[800]">
                Terms of Service & Privacy Policy.
              </a>{" "}
            </p>
          </div>
        </form>
      </AuthCard>
    </AuthLayout>
  )
}
