import React, { useState } from "react"
import { PhoneInput } from "react-international-phone"
import "react-international-phone/style.css"
import Map from "./Map"
import { trpc } from "@accubrew/state"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

export function ModalContactUs({ isContact, setIsContact }: any) {
  const [phone, setPhone] = useState("")
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm()

  const handlePhoneChange = (phoneNumber: string) => {
    setPhone(phoneNumber)
    setValue("phone", phoneNumber)
  }

  const { mutate: createRequest } = trpc.request.create.useMutation()

  const onSubmit = async (data: any) => {
    try {
      const response = await createRequest(data)
      setIsContact(false)
      setPhone("")
      toast.success(`Thank you!, Someone from our team will contact you soon.`)
      reset()
    } catch (error) {
      console.error("Error creating request:", error)
    }
  }

  return (
    <>
      {isContact && (
        <>
          <div className="flex justify-center items-center h-full fixed inset-0 z-50" id="wrapper">
            <div className="w-full md:w-[550px] font-[Mulish] h-fit max-h-[calc(100vh-20px)] rounded-2xl mx-3 shadow-lg flex flex-col overflow-y-scroll no-scrollbar bg-white px-4 lg:px-5 py-3 lg:py-5">
              <div className="flex w-full justify-between items-center">
                <p className="font-[Mulish] font-bold text-lg">Contact Us</p>
                <button onClick={() => setIsContact(false)} className="text-[#B6B4AF] font-semibold">
                  X
                </button>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-5 mt-3">
                <div className="w-full md:w-2/4">
                  <Map />
                </div>
                <div className="w-full md:w-2/4 font-[Mulish] flex flex-col items-start text-sm">
                  <p className="font-semibold text-[#B6B4AF]">Address</p>
                  <p className="text-left font-normal">640 Brooker Creek Boulevard, Suite 460, Oldsmar, Floride 34667 United States </p>
                  <p className="font-semibold text-[#B6B4AF] mt-4">Phone</p>
                  <p className="font-normal">+1 813-448-5520</p>
                  <p className="font-semibold text-[#B6B4AF] mt-4">Email</p>
                  <p className="font-normal">contact@accubrew.io</p>
                  <p className="font-semibold text-[#B6B4AF] mt-4">Social Media</p>
                  <div className="font-bold text-[#F88D2A] space-x-5">
                    <a href="https://www.facebook.com/accubrew/" target="_blank" rel="noreferrer">
                      Facebook
                    </a>
                    <a href="https://www.instagram.com/accubrew/" target="_blank" rel="noreferrer">
                      Instagram
                    </a>
                    <a href="https://twitter.com/GPAccuBrew" target="_blank" rel="noreferrer">
                      Twitter
                    </a>
                  </div>
                </div>
              </div>
              <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full flex flex-col md:flex-row mt-5 gap-6 text-[#B6B4AF] text-sm">
                  <div className="w-full md:w-2/4">
                    <p className="text-left font-semibold">Name</p>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none text-black"
                      {...register("name", { required: true })}
                    />
                    {errors.name && <span className="text-red-500">Name is required</span>}
                  </div>
                  <div className="w-full md:w-2/4">
                    <p className="text-left font-semibold">Phone</p>
                    <PhoneInput
                      defaultCountry="us"
                      inputClassName="w-full border rounded-lg p-2 focus:outline-none"
                      value={phone}
                      onChange={handlePhoneChange}
                    />
                    {errors.phone && <span className="text-red-500">Phone is required</span>}
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row mt-5 gap-6 text-[#B6B4AF] text-bold text-sm">
                  <div className="w-full md:w-2/4">
                    <p className="text-left font-semibold">Email</p>
                    <input
                      type="email"
                      className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none text-black"
                      placeholder="youremail@gmail.com"
                      {...register("email", { required: true })}
                    />
                    {errors.email && <span className="text-red-500">Email is required</span>}
                  </div>
                  <div className="w-full md:w-2/4">
                    <p className="text-left font-semibold">Message</p>
                    <input
                      type="text"
                      placeholder="Type Your Message"
                      className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none text-black"
                      {...register("message", { required: true })}
                    />
                    {errors.message && <span className="text-red-500">Message is required</span>}
                  </div>
                </div>
                <button type="submit" className="w-full bg-[#F88D2A] text-white rounded-lg py-3 mt-5 font-semibold">
                  Send
                </button>
              </form>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black h-full"></div>
        </>
      )}
    </>
  )
}

// export default ModalContactUs;
