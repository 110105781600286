import { Transition, Dialog } from "@headlessui/react"
import { createPortal } from "react-dom"
import { Fragment, type ReactNode, forwardRef, useImperativeHandle, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

import { Button } from "./Button"
import { svg } from "../assets"
import { noop } from "../utils"

type Props = {
  children: ReactNode
  visible: boolean
  portal?: boolean
  className?: string
  onClose?: () => void
}

const transitionChild1 = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
}

const transitionChild2 = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
  enterTo: "opacity-100 translate-y-0 sm:scale-100",
  leave: "ease-in duration-200",
  leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
  leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
}

function ReactPortal({ children }: { children: ReactNode }) {
  return createPortal(children, document.getElementById("root")!)
}
export function Modal({ children, visible, onClose, portal = false, className }: Props) {
  const Wrapper = portal ? ReactPortal : Fragment

  return (
    <Wrapper>
      <Transition.Root show={visible} as={Fragment} unmount>
        <Dialog as="div" className="relative z-20" onClose={() => {}}>
          <Transition.Child as={Fragment} {...transitionChild1}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child as={Fragment} {...transitionChild2}>
                <Dialog.Panel
                  className={twMerge(
                    "relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl w-full mx-3",
                    className ?? "",
                  )}
                >
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Wrapper>
  )
}

type Ref = {
  show: () => void
  hide: () => void
  getIsVisible: () => boolean
}

type ConfirmProps = {
  title: string
  subTitle?: string
  description: string
  onConfirm: () => void
  onCancel: () => void
  loading?: boolean
}

export function useConfirmRef() {
  return useRef<Ref>(null)
}

export const Confirm = forwardRef<Ref, ConfirmProps>((props, ref) => {
  const [visible, setVisible] = useState(false)

  function hide() {
    setVisible(false)
  }

  useImperativeHandle(
    ref,
    () => ({
      show: () => setVisible(true),
      hide: () => setVisible(false),
      getIsVisible: () => visible,
    }),
    [visible],
  )

  return (
    <Modal visible={visible} onClose={props.loading ? undefined : hide} portal>
      <h2 className="px-4 pt-4 pb-2 text-lg font-bold">
        {props.title} {props.subTitle ? <span className="text-orangeDark">{props.subTitle}?</span> : null}
      </h2>
      <p className="px-4 mb-4 font-medium">{props.description}</p>
      <div className="flex justify-center gap-x-3 mb-4">
        <Button onClick={props.loading ? noop : props.onCancel} variant="plain" className="px-4 py-2">
          Cancel
        </Button>
        <Button onClick={props.loading ? noop : props.onConfirm} variant="primary" className="relative px-4 py-2">
          <span className={props.loading ? "opacity-0" : ""}>Confirm</span>
          {props.loading && (
            <div className="absolute inset-0 flex justify-center items-center">
              <svg.Loading className="mx-auto w-6 h-6" />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  )
})

type ConfirmModalProps = {
  visible: boolean
  hide: () => void
  title: string
  subTitle?: string
  description: string
  onConfirm: () => void
  onCancel: () => void
  loading?: boolean
}

export function ConfirmModal(props: ConfirmModalProps) {
  return (
    <Modal visible={props.visible} onClose={props.loading ? undefined : props.hide} portal>
      <h2 className="px-4 pt-4 pb-2 text-lg font-bold">{props.title}</h2>
      <p className="px-4 mb-4 font-medium">{props.description}</p>
      <div className="flex justify-center gap-x-3 mb-4">
        <Button onClick={props.loading ? noop : props.onCancel} variant="plain" className="px-5 py-3">
          Cancel
        </Button>
        <Button onClick={props.loading ? noop : props.onConfirm} variant="primary" className="relative px-5 py-3">
          <span className={props.loading ? "opacity-0" : ""}>Confirm</span>
          {props.loading && (
            <div className="absolute inset-0 flex justify-center items-center">
              <svg.Loading className="mx-auto w-6 h-6" />
            </div>
          )}
        </Button>
      </div>
    </Modal>
  )
}
