import React, { useEffect, useState } from "react"
import { CiSearch } from "react-icons/ci"
import { AiOutlineDelete, AiOutlineEdit, AiOutlineInfoCircle, AiOutlinePlus } from "react-icons/ai"
import { RouterOutput, StateContainer, setBatchId, useStateContainer } from "@accubrew/state"
import { ConfirmModal, Modal } from "../../../components"
import { InfoTable, NoteForm } from "../../brewbook/components"
import { svg } from "../../../assets"
import { trpc, useBatches, useDeviceId } from "@accubrew/state"
import { format } from "date-fns"
type NotesModalType = { visible: boolean; note?: RouterOutput["note"]["list"][number] }
type NotesDeleteType = { visible: boolean; noteId?: RouterOutput["note"]["list"][number]["id"] }
type NotesProps = {
  batchId: any
  notes: any
  batchname: any
  overlayBatchName: any
  overlayNotes: any
}

const Notes: React.FC<NotesProps> = ({ batchId, notes, batchname, overlayBatchName, overlayNotes }) => {
  const [selectedTab, setSelectedTab] = useState(batchname?.[0]?.name)
  const [displayNotes, setDisplayNotes] = useState(notes ?? notes)
  const [modalId, setModalId] = useState(batchId)
  const [shouldUpdateNotes, setShouldUpdateNotes] = useState(true)
  const user = trpc.user.get.useQuery()
  const notesModalRef = useStateContainer<NotesModalType>()
  const infoModalRef = useStateContainer<NotesModalType>()
  const deleteNote = trpc.note.delete.useMutation()
  const deleteNoteConfirmRef = useStateContainer<NotesDeleteType>()
  useEffect(() => {
    {
      shouldUpdateNotes && setDisplayNotes(notes)
    }
  }, [notes, shouldUpdateNotes])

  const handleAddClick = () => {
    notesModalRef.current?.setState({ visible: true })
    setShouldUpdateNotes(false)
  }
  useEffect(() => {
    if (selectedTab === batchname?.[0]?.name) {
      handleNotes()
    } else {
      handleOverlayNotes()
    }
  }, [])
  // }, [notes, overlayNotes])
  const handleNotes = () => {
    setSelectedTab(batchname?.[0]?.name)
    setDisplayNotes(notes)
    setModalId(batchId)
  }
  const handleOverlayNotes = () => {
    setSelectedTab(overlayBatchName?.[0]?.name)
    setDisplayNotes(overlayNotes)
    setModalId(overlayBatchName?.[0]?.id)
    setShouldUpdateNotes(false)
  }

  return (
    <div className="flex flex-col gap-y-5 w-full lg:w-[35%]">
      <div className="px-3 pt-3 rounded-2xl bg-white h-[750px] lg:h-screen shadow-lg overflow-y-scroll no-scrollbar relative space-y-3">
        <div className="flex justify-between items-center">
          <p className="font-bold text-[18px]">BrewBook</p>
          <button className="bg-orangeDark text-white p-2 rounded" onClick={handleAddClick}>
            <AiOutlinePlus />
          </button>
        </div>

        {/* <div className="inline-flex flex-wrap md:flex-nowrap justify-between rounded-md shadow-sm border border-gray-200 p-1 w-full">
          <button aria-current="page" className="py-1 text-sm font-medium text-white bg-darkOrangeDark rounded-md w-1/4">
            3d
          </button>
          <button className="py-1 text-sm font-medium text-border bg-white  hover:bg-darkOrangeDark hover:text-white rounded-md w-1/4">1w</button>
          <button className="py-1 text-sm font-medium text-border bg-white hover:bg-darkOrangeDark hover:text-white rounded-md w-1/4">1m</button>
          <button className="py-1 text-sm font-medium text-border bg-white hover:bg-darkOrangeDark hover:text-white rounded-md w-1/4">24h</button>
        </div>

        <div className="w-full flex gap-1 md:gap-5">
          <select className="block w-2/4 md:w-2/5 px-2 py-3 border border-gray-200 rounded-lg bg-white appearance-none text-sm md:text-base">
            <option value="">Tags</option>
            <option value="">Option 2</option>
            <option value="">Option 3</option>
            <option value="">Option 4</option>
          </select>
          <div className="relative w-2/4 md:w-3/5 flex items-center h-12 rounded-lg bg-white overflow-hidden border border-gray-200">
            <button className="grid place-items-center h-full w-12">
              <CiSearch className="h-6 w-6 text-border" />
            </button>

            <input className="h-full w-full border-none text-border text-sm md:text-base pr-2" type="text" id="search" placeholder="Search" />
          </div>
        </div> */}

        <div className={`border rounded-xl grid ${batchname?.[0]?.name && overlayBatchName?.[0]?.name ? "grid-cols-2" : "grid-cols-1"} overflow-hidden`}>
          <button
            className={`p-3 ${selectedTab === undefined || selectedTab === batchname?.[0]?.name ? "bg-orangeDark text-white" : "bg-white text-black"}`}
            onClick={handleNotes}
          >
            {batchname[0]?.name}
          </button>
          {overlayBatchName?.[0]?.name && (
            <button
              className={`p-3 ${selectedTab === overlayBatchName?.[0]?.name ? "bg-orangeDark text-white" : "bg-white text-black"}`}
              onClick={handleOverlayNotes}
            >
              {overlayBatchName?.[0]?.name}
            </button>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-3">
          <>
            {displayNotes && displayNotes?.data?.length === 0 && <p className="font-bold w-full h-20 text-center pt-5">No Notes Found</p>}
            {displayNotes?.data?.map((note: any) => (
              <div
                className={`bg-white shadow-md p-2 rounded-lg border-l-4 ${
                  note.type === "Reminder" ? "border-l-blue" : "border-l-orangeDark"
                } w-full font-[Mulish]`}
              >
                <div className="flex justify-between gap-y-3">
                  <h2 className="font-bold line-clamp-2">{note.type}</h2>
                  <div className="flex gap-1">
                    <button className="p-1" onClick={() => notesModalRef.current?.setState({ visible: true, note })}>
                      <AiOutlineEdit size={20} />
                    </button>
                    <button className="p-1" onClick={() => deleteNoteConfirmRef.current?.setState({ visible: true, noteId: note?.id })}>
                      <AiOutlineDelete size={20} />
                    </button>
                    <button className="p-1" onClick={() => infoModalRef.current?.setState({ visible: true, note })}>
                      <AiOutlineInfoCircle size={20} />
                    </button>
                  </div>
                </div>
                <h5 className={`text-${note.createdBy?.email === user?.data?.email ? "orangeDark" : "textPrimary"} font-bold`}>
                  {note.createdBy?.firstName ?? ""} {note.createdBy?.lastName ?? ""}
                </h5>
                <p className="text-slate-400 text-left my-3">{note.description}</p>
                <div className="flex justify-between items-center">
                  <p className="my-1 text-slate-600 text-left text-xs md:text-sm">{format(note.createdAt, "MMM d, yyyy ")}</p>
                  <div className="flex items-center gap-3">
                    {note.reminderDate && <p className="text-slate-400 text-xs md:text-sm">{format(note.reminderDate, "MMM d, yyyy ")}</p>}
                    <svg.BrewNotification />
                  </div>
                </div>
              </div>
            ))}
          </>
          {displayNotes?.data && displayNotes?.data?.length > 3 && (
            <button className="text-darkOrangeDark text-center font-bold font-[Mulish] sticky -bottom-1 left-0 bg-white w-full h-16">View all</button>
          )}
        </div>
      </div>

      {displayNotes.isLoading && (
        <div className="absolute inset-0 flex justify-center items-center">
          <svg.Loading />
        </div>
      )}

      {modalId && (
        <StateContainer<NotesModalType> initialState={{ visible: false }} ref={notesModalRef}>
          {(state, setState) => (
            <Modal visible={state.visible} onClose={() => setState((s) => ({ ...s, visible: false }))} className="max-w-lg sm:max-w-lg">
              <NoteForm close={() => setState((s) => ({ ...s, visible: false }))} note={state.note} batchId={modalId} />
            </Modal>
          )}
        </StateContainer>
      )}
      {modalId && (
        <StateContainer<NotesModalType> initialState={{ visible: false }} ref={infoModalRef}>
          {(state, setState) => (
            <Modal visible={state.visible} onClose={() => setState((s) => ({ ...s, visible: false }))} className="max-w-lg sm:max-w-lg">
              <InfoTable close={() => setState((s) => ({ ...s, visible: false }))} batchId={modalId} note={state.note} />
            </Modal>
          )}
        </StateContainer>
      )}
      <StateContainer initialState={{ visible: false }} ref={deleteNoteConfirmRef}>
        {(state, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure ?"
            description="This action cannot be undone."
            onConfirm={async () => {
              await deleteNote.mutateAsync({ id: state.noteId! })
              await displayNotes.refetch()
              setState({ visible: false })
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            loading={deleteNote.isLoading || notes.isFetching}
          />
        )}
      </StateContainer>
    </div>
  )
}

export default Notes
