import { useEffect, useState } from "react"
import { format } from "date-fns"
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus, AiOutlineInfoCircle } from "react-icons/ai"
import { RouterOutput, StateContainer, trpc, useStateContainer, useBatches, useDeviceId } from "@accubrew/state"

import { svg } from "../../assets"
import { DeviceDropdown } from "../dashboard/components"
import { BatchDropdown, ConfirmModal, Modal } from "../../components"

import { NoteForm, InfoTable } from "./components"

type NotesModalType = { visible: boolean; note?: RouterOutput["note"]["list"][number] }
type NotesDeleteType = { visible: boolean; noteId?: RouterOutput["note"]["list"][number]["id"] }

function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}
export function BrewBook() {
  const deviceId = useDeviceId()!
  const user = trpc.user.get.useQuery()

  const batches = useBatches({ deviceId })
  const [selectedBatchId, setSelectedBatchId] = useState<string | null | undefined>(undefined)
  const deleteNote = trpc.note.delete.useMutation()
  const ENABLED_NOTES_FETCHING = selectedBatchId !== undefined && selectedBatchId !== "none"
  const notes = trpc.note.list.useQuery({ batchId: selectedBatchId! }, { enabled: ENABLED_NOTES_FETCHING })
  const notesModalRef = useStateContainer<NotesModalType>()
  const infoModalRef = useStateContainer<NotesModalType>()
  const deleteNoteConfirmRef = useStateContainer<NotesDeleteType>()

  const isOperator = user.data?.role === "OWNER" || user.data?.role === "PRODUCTION" || user.data?.role === "MANAGER"

  function handleAddClick() {
    notesModalRef.current?.setState({ visible: true })
  }

  return (
    <Page title="BrewBook">
      <div className="px-4">
        <div className="mb-4 flex justify-between items-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 max-w-md lg:max-w-3xl w-full">
            <DeviceDropdown className="lg:col-span-1 col-span-1 bg-red-100" setSelectedBatchId={setSelectedBatchId} />
            <BatchDropdown
              hideWhenDisabled
              loading={batches.isLoading}
              batches={batches.data?.batches ?? []}
              disabled={!deviceId}
              selectedBatchId={selectedBatchId}
              onBatchIdChange={setSelectedBatchId}
              highlightRunningBatch={false}
            />
          </div>

          {/* {ENABLED_NOTES_FETCHING && ( */}
          <button className="hidden md:block bg-[#F88D2A] text-white p-2 rounded" onClick={handleAddClick}>
            <AiOutlinePlus />
          </button>
          {/* )} */}
        </div>
        {!deviceId && <span className="font-bold">Select Device.</span>}
        {deviceId && (selectedBatchId === undefined || selectedBatchId === "none") && <span className="font-bold">Select Batch.</span>}

        <button
          className="max-w-md  md:hidden bg-[#FEEEDF] text-darkOrangeDark p-2 rounded w-full flex  items-center justify-center font-bold mb-2 shadow"
          onClick={handleAddClick}
        >
          <AiOutlinePlus />
          <span className="ml-2"> Add Note / Reminder</span>
        </button>

        {notes.data && notes.data.length === 0 && <span className="font-bold">No Notes Found</span>}
        {ENABLED_NOTES_FETCHING && notes.isLoading && <svg.Loading className="mx-auto mt-16" />}

        <div className="grid gap-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {notes.data?.map((note: any) => (
            <div
              key={note.id}
              className={`bg-white p-2 rounded-md overflow-y-auto border-l-[4px] ${note.type === "Batch" ? "border-[#F88D2A]" : "border-[#427CBF]"}`}
            >
              <div className="mb-2 flex justify-between items-center">
                <h2 className="font-bold line-clamp-2">{note.type === "Batch" ? "Batch Note" : "Reminder"}</h2>
                <div className="flex items-center">
                  {isOperator && (
                    <>
                      <button className="p-1" onClick={() => notesModalRef.current?.setState({ visible: true, note })}>
                        <AiOutlineEdit size={20} />
                      </button>
                      <button className="p-1" onClick={() => deleteNoteConfirmRef.current?.setState({ visible: true, noteId: note.id })}>
                        <AiOutlineDelete size={20} />
                      </button>
                    </>
                  )}
                  <button className="p-1" onClick={() => infoModalRef.current?.setState({ visible: true, note })}>
                    <AiOutlineInfoCircle size={20} />
                  </button>
                </div>
              </div>
              <h5 className={`text-${note.createdBy?.email === user.data?.email ? "orangeDark" : "textPrimary"} font-bold`}>
                {note.createdBy?.firstName ?? ""} {note.createdBy?.lastName ?? ""}
              </h5>
              <p className="text-slate-400 mb-2">{note.description}</p>
              <div className="mb-2 flex justify-between  text-xs md:text-sm lg:text-sm ">
                {note.createdAt && <p className="text-slate-600 mb-2 flex">{format(note.createdAt, "MMM d, yyyy ")}</p>}

                <div className="flex  ml-auto">
                  {note.reminderDate && <p className="text-slate-400  mb-2">{format(note.reminderDate, "MMM d, yyyy h:mm a")}</p>}

                  <svg.BrewNotification className="ml-2" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedBatchId && (
        <StateContainer<NotesModalType> initialState={{ visible: false }} ref={notesModalRef}>
          {(state, setState) => (
            <Modal visible={state.visible} onClose={() => setState((s) => ({ ...s, visible: false }))} className="max-w-lg sm:max-w-lg">
              <NoteForm close={() => setState((s) => ({ ...s, visible: false }))} batchId={selectedBatchId} note={state.note} />
            </Modal>
          )}
        </StateContainer>
      )}
      {selectedBatchId && (
        <StateContainer<NotesModalType> initialState={{ visible: false }} ref={infoModalRef}>
          {(state, setState) => (
            <Modal visible={state.visible} onClose={() => setState((s) => ({ ...s, visible: false }))} className="max-w-lg sm:max-w-lg">
              <InfoTable close={() => setState((s) => ({ ...s, visible: false }))} batchId={selectedBatchId} note={state.note} />
            </Modal>
          )}
        </StateContainer>
      )}
      <StateContainer initialState={{ visible: false }} ref={deleteNoteConfirmRef}>
        {(state, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure ?"
            description="This action cannot be undone."
            onConfirm={async () => {
              await deleteNote.mutateAsync({ id: state.noteId! })
              await notes.refetch()
              setState({ visible: false })
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            loading={deleteNote.isLoading || notes.isFetching}
          />
        )}
      </StateContainer>
    </Page>
  )
}
