import React from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

function Map() {
  const key = "AIzaSyCpiGa72fSboe4AxAFm4EvGm6yjhxLDFx4";
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: key,
  });
  console.log(isLoaded);
  const center = { lat: 28.0543308389698, lng: -82.65802290118665 };

  return (
    <div className="h-[10rem] md:h-full w-full">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
          }}
          center={center}
          zoom={10}
        >
          <Marker position={center} />
        </GoogleMap>
      )}
    </div>
  );
}

export default Map;
