import { StateContainer, Store, queryClient, trpc, useStateContainer } from "@accubrew/state"
import { Link, useLocation } from "@tanstack/react-location"
import React, { useEffect, useState } from "react"
import { FiPhoneCall } from "react-icons/fi"
import { RxCross1 } from "react-icons/rx"
import { ModalContactUs } from "../pages/contact_us"
import { ConfirmModal } from "./Modal"
type DeleteAccountState = { visible: boolean }

function Drawer({ isOpen, setIsOpen }: any) {
  const user = trpc.user.get.useQuery()
  const deactivateUser = trpc.user.deactivate.useMutation()
  const [isContact, setIsContact] = useState(false)
  const deleteAccountConfirmRef = useStateContainer<DeleteAccountState>()
  const handleTab = () => {
    setIsOpen(false)
  }

  function handleLogout() {
    Store.clear()
    requestAnimationFrame(() => queryClient.clear())
  }

  return (
    <>
      <div
        className="flex flex-col items-center justify-between lg:hidden gap-5 bg-white text-black h-screen rounded-l-2xl shadow-xl min-w-[300px] py-5 fixed top-0 right-0 font-[Mulish] font-bold"
      >
        <div className="flex flex-col w-full">
          <div className="flex justify-end items-center w-full px-3 gap-3 font-bold">
            <p>Hi, {user.data?.firstName}</p>
            <button className="rounded-full w-fit p-2 bg-[#EDEDED]" onClick={() => setIsOpen(!isOpen)}>
              <RxCross1 />
            </button>
          </div>
          <MatchPathName path="settings/profile">
            {(active) => (
              <Link
                to="/dashboard/settings/profile"
                className={`${
                  active ? "bg-orangeDark text-white" : "text-textTertiary"
                } hover:bg-orangeDark hover:text-white px-5 py-3 w-full text-left rounded-lg mt-5`}
                onClick={handleTab}
              >
                Personal info
              </Link>
            )}
          </MatchPathName>
          <MatchPathName path="settings/accounts">
            {(active) => (
              <Link
                to="/dashboard/settings/accounts"
                className={`${
                  active ? "bg-orangeDark text-white" : "text-textTertiary"
                } hover:bg-orangeDark hover:text-white px-5 py-3 w-full text-left rounded-lg`}
                onClick={handleTab}
              >
                Account Management
              </Link>
            )}
          </MatchPathName>
          <MatchPathName path="settings/devices">
            {(active) => (
              <Link
                to="/dashboard/settings/devices"
                className={`${
                  active ? "bg-orangeDark text-white" : "text-textTertiary"
                } hover:bg-orangeDark hover:text-white px-5 py-3 w-full text-left rounded-lg`}
                onClick={handleTab}
              >
                Probe Management
              </Link>
            )}
          </MatchPathName>
        </div>
        <div className="w-full px-5 space-y-8 mb-20 md:mb-0">
          <button className="flex justify-center items-center py-3 w-full rounded-lg gap-3 bg-[#FEEEDF] text-[#F88D2A]" onClick={() => setIsContact(true)}>
            <FiPhoneCall />
            <span>Contact us</span>
          </button>
          <button className="w-full text-left text-[#B6B4AF]" onClick={handleLogout}>
            Logout
          </button>
          <button
            className="w-full text-left text-red-500"
            onClick={() => {
              deleteAccountConfirmRef.current?.setState({ visible: true })
            }}
          >
            Delete account
          </button>
        </div>
      </div>
      <div className="block lg:hidden w-screen h-screen fixed inset-0 bg-black bg-opacity-40 -z-10"></div>
      <ModalContactUs isContact={isContact} setIsContact={setIsContact} />
      <StateContainer<DeleteAccountState> initialState={{ visible: false }} ref={deleteAccountConfirmRef}>
        {(state, setState) => (
          <ConfirmModal
            visible={state.visible}
            title="Are you sure, you want to delete your account?"
            description="All data and connected accounts will
            be permanently deleted."
            onConfirm={async () => {
              await deactivateUser.mutateAsync(undefined)
              setState({ visible: false })
              Store.clear()
              requestAnimationFrame(() => queryClient.clear())
            }}
            hide={() => setState({ visible: false })}
            onCancel={() => setState({ visible: false })}
            loading={deactivateUser.isLoading}
          />
        )}
      </StateContainer>
    </>
  )
}

function MatchPathName({ path, children }: { path: string; children: (active: boolean) => React.ReactNode }) {
  const location = useLocation()
  const active = location.current.pathname.includes(path)
  return children(active) as JSX.Element
}
export default Drawer
