import React, { useEffect } from "react"
import { svg } from "../../assets/index"
import { Link } from "@tanstack/react-location"

function Page({ children, title }: { children: React.ReactNode; title?: string }) {
  useEffect(() => {
    document.title = `AccuBrew${title ? ` | ${title}` : ""}`
  }, [title])

  return <>{children}</>
}

export function Troubleshoot() {
  return (
    <Page title="Probe Troubleshooting">
      <div className="flex items-center space-x-3 py-0">
        <Link to="/dashboard/accubrew/about" className="pl-3 text-[#000] text-16 font-semibold flex items-center">
          <svg.LeftArrow className="mr-1" />
          <span>Back</span>
        </Link>
      </div>
      <div className="flex justify-center h-screen" style={{ alignItems: "center" }}>
        <svg.CommingSoon />
      </div>
    </Page>
  )
}
