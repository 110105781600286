import { useState } from "react"
import { trpc, useDeviceId } from "@accubrew/state"
import { svg } from "../../../assets"
import { Confirm, Modal, useConfirmRef } from "../../../components"
import { useSelectedDevcieState } from "../../../hooks"
import { noop } from "../../../utils"
import { StartRunForm } from "./StartRunForm"
import { twMerge } from "tailwind-merge"
import { FaStop } from "react-icons/fa"

type RunButtonProps = { className?: string; loading: boolean; running?: boolean }
export function RunButton({ className, loading, running }: RunButtonProps) {
  const [visible, setVisible] = useState(false)
  const deviceId = useDeviceId()!
  const stopRunConfirmDialogRef = useConfirmRef()
  const startRunConfirmDialogRef = useConfirmRef()
  const context = trpc.useContext()
  const stopRun = trpc.device.stopRun.useMutation()
  const state = useSelectedDevcieState()
  const LOADING = state.isLoading
  const DISABLED = !deviceId || state.isFetching || state.status !== "success" || !state.data?.online || state.data.busy

  function handleClick() {
    state.data?.running ? stopRunConfirmDialogRef.current?.show() : startRunConfirmDialogRef.current?.show()
  }

  function hideStopRunDialog() {
    stopRunConfirmDialogRef.current?.hide()
  }

  function hideStartRunDialog() {
    startRunConfirmDialogRef.current?.hide()
  }

  function handleStartRunConfirm() {
    hideStartRunDialog()
    setVisible(true)
  }

  function handleStopRunConfirm() {
    stopRun.mutate(
      { deviceId },
      {
        onSuccess() {
          context.batch.list.invalidate({ probeId: deviceId })
          hideStopRunDialog()
        },
      },
    )
  }

  return (
    <>
      <RunButtonIndicator loading={loading} handleClick={handleClick} running={running} disabled={DISABLED} className={className} />
      {running && (
        <Confirm
          ref={stopRunConfirmDialogRef}
          title="Stop Accuprobe Run?"
          description="Accuprobe will stop collecting data"
          onConfirm={handleStopRunConfirm}
          onCancel={hideStopRunDialog}
          loading={stopRun.isLoading}
        />
      )}

      {state.data?.running === false && (
        <Confirm
          ref={startRunConfirmDialogRef}
          title="Confirm Probe Run"
          description="New chart will be created for this run."
          onConfirm={handleStartRunConfirm}
          onCancel={hideStartRunDialog}
        />
      )}

      <Modal portal visible={visible} onClose={() => setVisible(false)}>
        <StartRunForm hide={() => setVisible(false)} />
      </Modal>
    </>
  )
}

type Props = {
  running?: boolean
  loading?: boolean
  handleClick?: () => void
  disabled?: boolean
  className?: string
}

export function RunButtonIndicator({ loading, running, handleClick, disabled, className }: Props) {
  // prettier-ignore
  const icon = loading ? <svg.Loading className="block w-5 h-5 mx-auto" /> : running ? <FaStop size={20} /> : <svg.Play className="mx-auto" />

  return (
    <button
      className={twMerge(
        `h-11 text-white font-[700] text-base flex justify-center items-center rounded disabled:opacity-50 ${running ? "bg-[#D32F2F]" : "bg-[#3EAE2B]"}`,
        className,
      )}
      onClick={loading ? noop : handleClick}
      disabled={disabled}
    >
      <span>{running ? "Stop Run" : "Start Run"} </span>
      <span className="mx-2"> {icon}</span>
    </button>
  )
}
