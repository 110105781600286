// src/index.ts
function exclude(entity, keys) {
  for (let key of keys)
    delete entity[key];
  return entity;
}
function round(num, decimalPlaces = 0) {
  const factor = 10 ** decimalPlaces;
  if (decimalPlaces === 0) {
    return Math.round(num);
  } else {
    return Math.round((num + Number.EPSILON) * factor) / factor;
  }
}
function ObjectKeys(obj) {
  return Object.keys(obj);
}
var ROUND = {
  specificGravity: 3,
  fermentation: 3,
  clarity: void 0,
  tempC: 1,
  tempF: 1,
  default: 2
};
function lastElement(array) {
  return array[array.length - 1];
}
function last17thElement(array) {
  if (array.length >= 17) {
    return array[array.length - 17];
  } else {
    return array[0];
  }
}
function calculateROC(array) {
  const v1 = lastElement(array) ?? 0;
  const v2 = last17thElement(array) ?? 0;
  if (v1 === 0 && v2 === 0)
    return 0;
  return ((v1 - v2) / v2 + 1e-32) * 100;
}
function getROCStatus(value) {
  if (value > 0)
    return "up";
  if (value < 0)
    return "down";
  return "none";
}
function opacityToHex(opacity) {
  const opacityDecimal = opacity / 100;
  const opacityHex = Math.round(opacityDecimal * 255).toString(16).padStart(2, "0");
  return opacityHex;
}
function fToC(fahrenheit) {
  return (fahrenheit - 32) * 5 / 9;
}
function cToF(celsius) {
  return round(celsius * 9 / 5 + 32);
}
function sgToPlato(specificGravity) {
  return -616.868 + 1111.14 * specificGravity - 630.272 * Math.pow(specificGravity, 2) + 135.997 * Math.pow(specificGravity, 3);
}
function platoToSg(plato) {
  return (258.6 - plato / (258.2 + plato)) / 1e3 + 1;
}
export {
  ObjectKeys,
  ROUND,
  cToF,
  calculateROC,
  exclude,
  fToC,
  getROCStatus,
  last17thElement,
  lastElement,
  opacityToHex,
  platoToSg,
  round,
  sgToPlato
};
