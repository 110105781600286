const textPrimary = "#342F20"
const textDisabled = "#D6D6D6"

export function Wifi({ value, ...props }: React.ComponentProps<"svg"> & { value: number }) {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21">
      <path d="M5 17.1663V13.833" stroke={value / 100 > 0.3 ? textPrimary : textDisabled} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 17.1663V8.83301" stroke={value / 100 > 0.5 ? textPrimary : textDisabled} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 17.1663V3.83301" stroke={value / 100 > 0.7 ? textPrimary : textDisabled} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
